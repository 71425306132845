@charset "utf-8";

//@import '~bulma/bulma';
@import '~bulma/sass/utilities/_all.sass';
@import '~bulma-switch';
@import '~bulma-checkradio';
@import '~@vueform/slider/themes/default.css';
@import '~vue-toastification/dist/index.css';
@import '~simplebar/dist/simplebar.css';

//@import "fonts";

@import 'variables';
@import 'mixins';
@import 'layout';
@import 'shop';