@import 'shop-footer';
@import 'account';
@import 'modal';

.shop-subcategory {
    position: relative;
    z-index: 5;

    &-title {
        margin-top: 50px;
        font-family: $title-font;
        font-size: 40px;
        line-height: 1.1;
        font-weight: 500;

        h1 {
            display: inline;
            font-size: unset;
            font-weight: unset;
            margin-right: 0.5rem;
        }

        @include maxw1200 {
            margin-top: 36px;
            font-size: 36px;
        }

        @include touch {
            margin-top: 24px;
            font-size: 32px;
        }

        @include mobile {
            margin-top: 16px;
            font-size: 24px;
            line-height: 1.3;
            padding-right: 1rem;
        }

        span {
            color: $grey-text;
            display: inline-block;

            @include mobile {
                display: inline;
            }
        }
    }

    &-content {
        position: relative;

        @include mobile {
            margin-top: 16px !important;
        }
    }

    .shop-categories {
        padding-left: 16px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1px;
        margin-bottom: 30px;

        @include touch {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            padding: 0 10px;
            margin-bottom: 0;
            grid-auto-flow: column;
            margin-left: -10px;
            margin-right: -10px;
            width: calc(100% + 20px);
            overflow: auto;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                @include mobile {
                    display: none;
                }
            }
        }
    }

    .shop-category-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 24px;
        background-color: $grey-bg;
        min-height: 148px;

        @include mobile {
            padding: 10px;
            min-height: 165px;
            width: 128px;
        }

        &-image {
            height: 80px;

            picture {
                display: block;

                &::before {
                    background-color: transparent;
                }
            }

            img {
                height: 80px;
                //max-width: 108px;
                width: auto;
                object-fit: contain;
            }
        }

        &-title {
            margin-top: 16px;
            text-align: center;
            line-height: 1.57;

            span {
                color: $grey-text;
                margin-left: 3px;
            }
        }
    }

    .brand {
        display: flex;
        padding-left: 15px;
        margin-bottom: 12px;

        @include touch {
            flex-direction: column;
        }

        @include mobile {
            padding-left: 0;
            margin-bottom: 0;
        }

        &-column {
            &.left {
                width: 276px;
                flex-shrink: 0;
                margin-right: 32px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                @include touch {
                    width: 100%;
                    margin-right: 0;
                }
            }

            &.right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;

                @include touch {
                    margin-top: 30px;
                }
            }
        }

        .logo-item-label {
            position: absolute;
            top: 12px;
            left: 12px;
            color: $purple;
            font-size: 12px;
            line-height: 28px;
            padding: 0 8px;
            background-color: $purple-ultralight;
            z-index: 2;
        }

        &-logo {
            padding: 35px 20px 8px;
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 2px solid $grey-border;

            @include mobile {
                padding: 22px 16px 8px;
            }

            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 120px;

                @include mobile {
                    height: 70px;
                }

                img {
                    max-height: 120px;
                    max-width: 200px;
                    object-fit: contain;

                    @include mobile {
                        max-height: 70px;
                        max-width: 128px;
                    }
                }
            }

            &-title {
                margin-top: 28px;
                font-size: 18px;
                line-height: 1.5;
                color: #000;
                text-align: center;

                @include mobile {
                    margin-top: 14px;
                    font-size: 12px;
                }
            }
        }

        .world {
            width: 19px;
            height: 19px;
            margin-right: 6px;
        }

        &-site {
            margin-top: 8px;
            display: flex;
            align-items: center;
            color: $purple;
            font-size: 14px;
            line-height: 1.57;
        }

        &-tabs {
            overflow: initial;

            &:not(:last-child) {
                margin-bottom: initial;
            }

            ul {
                border: 0;
            }

            li {
                font-size: 18px;
                color: $grey-text;
                line-height: 1.78;
                position: relative;

                &:not(:last-child) {
                    margin-right: 38px;

                    &::after {
                        content: '/';
                        top: 0;
                        left: calc(100% + 15px);
                        pointer-events: none;
                        position: absolute;
                    }
                }

                &.is-active {
                    color: $black;

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        border-bottom: 2px solid currentColor;
                        top: calc(100% + 8px);
                    }

                    &::after {
                        color: $grey-text;
                    }

                    a {
                        color: inherit;
                        border-bottom: initial;
                    }
                }
            }

            a {
                padding: 0;
                border: 0;
                margin: initial;
                color: inherit;
            }
        }

        &-tabs-content {
            margin-top: 30px;
            width: 100%;
        }

        &-tab {
            display: none;

            &.open {
                display: block;
            }
        }

        &-descr {
            // margin-top: 30px;
            max-width: 706px;
            max-height: 174px;
            overflow: hidden;
            position: relative;

            @include mobile {
                max-width: 100%;
                max-height: 240px;
            }

            &::after {
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 48px;
                background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0.49) 100%);
                z-index: 2;
            }

            &.open {
                overflow: initial;
                max-height: initial;

                &::after {
                    background: initial;
                }
            }

            p {
                line-height: 1.67;
                font-weight: 400;
                color: #000;

                &:not(:last-child) {
                    margin-bottom: 25px;

                    @include mobile {
                        margin-bottom: 15px;
                    }
                }
            }

            &-toggler {
                margin-top: 20px;
                display: flex;
                align-items: center;
                color: #000;
                font-size: 16px;
                line-height: 28px;
                cursor: pointer;

                @include mobile {
                    margin-top: 14px;
                }

                svg {
                    width: 8px;
                    height: 5px;
                    margin-left: 4px;
                }
            }
        }

        &-catalogs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 5px;
            width: 100%;
            max-width: 600px;
            overflow: hidden;
            max-height: 184px;

            &.open {
                max-height: initial;
            }

            li {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
            }

            a {
                position: relative;
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 1.57;
                color: $purple;

                &::before {
                    display: block;
                    content: '';
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.25 9.625V12.25H1.75V9.625H0V12.25C0 13.2125 0.7875 14 1.75 14H12.25C13.2125 14 14 13.2125 14 12.25V9.625H12.25ZM11.375 6.125L10.1413 4.89125L7.875 7.14875V0H6.125V7.14875L3.85875 4.89125L2.625 6.125L7 10.5L11.375 6.125Z' fill='%239E61FF'/%3E%3C/svg%3E%0A");
                    flex-shrink: 0;
                }
            }

            &-toggler {
                margin-top: 20px;
                display: flex;
                align-items: center;
                color: #000;
                font-size: 16px;
                line-height: 28px;
                cursor: pointer;

                svg {
                    width: 8px;
                    height: 5px;
                    margin-left: 4px;
                }
            }
        }
    }

    .catalog-filter-open-mobile {
        margin-top: 24px;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        border: 0;
        color: $text;
        border-radius: 0;
        background-color: #f5f5f5;
        color: #222222;
        font-size: 0.9rem;

        @include mobile {
            display: flex;
        }

        .total-active-filters {
            position: absolute;
            top: -1px;
            right: -21px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #b98cff;
            font-size: 12px;
            line-height: 1.1;
            color: #fff;
        }

        .icon {
            margin-right: 10px;
            width: 15px;
            height: 18px;
        }
    }

    .view-sort-controls {
        @include mobile {
            margin: 0;
            margin-top: 16px;
            // padding: 0;
        }

        &:not(:last-child) {
            @include mobile {
                margin-bottom: 0;
            }
        }

        .columns {
            width: 100%;
            display: flex;
            justify-content: space-between;

            @include mobile {
                padding: 0;
            }
        }

        .column {
            @include mobile {
                flex-grow: 0;
            }
        }
    }

    .sort-selector-products {
        line-height: 17px;
        margin-right: 28px;

        @include mobile {
            line-height: 1.57;
            margin-right: 0;

            .dropdown-trigger {
                .button {
                    font-size: 14px;

                    span {
                        color: #000;
                    }
                }
            }
        }

        button {
            border: 0;
            padding: 0;
            display: flex;
            align-items: center;
            color: $grey-text;
            height: auto;

            &:focus {
                box-shadow: initial;
                outline: 0;
            }

            span {
                color: $purple;
                margin-left: 6px;
            }

            &::after {
                display: block;
                content: '';
                width: 13px;
                height: 15px;
                flex-shrink: 0;
                margin-left: 4px;
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='15' viewBox='0 0 13 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.66634 11.4662V5.91667H8.08301V11.4662H5.70801L8.87468 14.625L12.0413 11.4662H9.66634ZM4.12468 0.375L0.958008 3.53375H3.33301V9.08333H4.91634V3.53375H7.29134L4.12468 0.375ZM9.66634 11.4662V5.91667H8.08301V11.4662H5.70801L8.87468 14.625L12.0413 11.4662H9.66634ZM4.12468 0.375L0.958008 3.53375H3.33301V9.08333H4.91634V3.53375H7.29134L4.12468 0.375Z' fill='%239E61FF'/%3E%3C/svg%3E%0A");

                @include mobile {
                    margin-left: -7px;
                }
            }
        }

        .dropdown-menu {
            left: -20px;
            right: -20px;
            padding-top: 0;

            @include mobile {
                left: 0;
            }

            &::before {
                display: block;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 5px 6px 5px;
                border-color: transparent transparent #fff transparent;
                position: absolute;
                bottom: 100%;
                right: 20px;
            }
        }

        .dropdown-content {
            background-color: initial;
            border: 0;
            padding: 0;
            box-shadow: 0;
        }

        .dropdown-item {
            padding: 5px 0;
            background-color: #fff;
            box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);
            display: flex;
            flex-direction: column;
        }

        .drop-selector {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            font-size: 14px;
            line-height: 17px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                background-color: $purple-light;
                color: $purple;
                transition: color $duration, background-color $duration;
            }

            &.checked {
                color: $purple;
                pointer-events: none;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.45455 9.20149L1.11364 5.75373L0 6.90299L4.45455 11.5L14 1.64925L12.8864 0.5L4.45455 9.20149Z' fill='%239E61FF'/%3E%3C/svg%3E%0A");
                    width: 14px;
                    height: 12px;
                    margin-left: 10px;
                }
            }
        }
    }

    .products-view-switcher {
        display: flex;
        align-items: center;

        &.is-horizontal {
            .switcher-item {
                &.horizontal {
                    color: $purple;
                    pointer-events: none;
                }

                &.grid {
                    color: $grey-text;
                    pointer-events: auto;
                }
            }
        }

        .switcher-item {
            color: $grey-text;
            cursor: pointer;

            &.grid {
                color: $purple;
                pointer-events: none;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .icon {
            display: block;

            &.view-grid {
                width: 19px;
                height: 18px;
            }

            &.view-list {
                width: 18px;
                height: 18px;
            }
        }
    }

    .products-list {
        padding-left: 16px;
        // width: calc(100% + 4px);
        width: calc(100% + 7px);

        @include maxw1200 {
            width: calc(100% + 5px);
        }

        @include touch {
            width: calc(100% + 3px);
        }

        @include mobile {
            margin-top: 12px;
            display: flex;
            width: calc(100% + 1px);
            padding-left: 0;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        &.is-horizontal {
            width: 100%;

            >.column {
                margin-bottom: -1px;
                width: 100%;

                @include touch {
                    margin-bottom: 0;
                }

                &:last-child {
                    .card.product {
                        @include touch {
                            border-bottom: 2px solid $grey-border;
                        }
                    }
                }
            }

            .card.product {
                min-height: 300px;
                padding-right: 20px;
                border: 2px solid $grey-border;

                @include touch {
                    min-height: 170px;
                    padding-right: 8px;
                    border-bottom: 0;
                }

                &:hover {
                    .card-inner {
                        position: relative;
                        box-shadow: none;
                    }
                }

                .card-inner {
                    display: flex;
                    flex-direction: row;
                    border: 0;
                }

                .card-content {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 30px;
                    // padding: 10px 0;
                    padding: 30px 0;

                    @include touch {
                        grid-template-columns: initial;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        padding: 8px 0;
                    }
                }

                .labels {
                    @include touch {
                        margin-top: 8px;
                        position: relative;
                        top: initial;
                        left: initial;
                        font-size: 10px;
                        line-height: 1.2;
                        order: 2;
                    }
                }

                .card-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 250px;
                    flex-shrink: 0;
                    margin-right: 48px;
                    padding: 0 10px;

                    @include maxw1200 {
                        margin-right: 16px;
                        width: 150px;
                    }

                    @include touch {
                        width: 128px;
                        padding: 0;
                        padding-left: 8px;
                        margin-right: 12px;
                    }

                    .image.is-4by3 {
                        padding-top: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;

                        @include touch {
                            top: 32px;
                            left: 8px;
                        }

                        img {
                            @include touch {
                                padding: 0;
                            }
                        }
                    }
                }

                .product-toolbar {
                    // margin-top: 20px;
                    margin-top: -31px;
                    position: relative;
                    top: initial;
                    right: initial;
                    grid-row: 3;
                    // grid-row: 2;
                    grid-column: 2;
                    align-self: start;
                    flex-direction: row;

                    @include touch {
                        position: absolute;
                        left: 8px;
                        top: 8px;
                        margin: 0;
                        grid-column: initial;
                        grid-row: initial;
                    }

                    .button {
                        &:not(:last-child) {
                            margin-left: 0;
                            margin-right: 15px;
                        }
                    }
                }

                .card-title {
                    grid-column: 1;
                    align-self: start;
                    font-size: 20px;
                    line-height: 1.25;
                    height: initial;

                    @include touch {
                        font-size: 14px;
                        line-height: 1.4;
                        order: 1;
                    }
                }

                .card-number-availability {
                    grid-column: 1;
                    margin-bottom: 0;
                    align-self: start;

                    @include touch {
                        margin-top: 10px;
                        width: 100%;
                        margin-bottom: 32px;
                        font-size: 10px;
                        line-height: 1.2;
                        order: 3;
                    }
                }

                .card-offer {
                    margin-top: initial;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    grid-column: 2;
                    align-self: start;
                    min-height: initial;

                    @include touch {
                        margin-top: auto;
                        width: 100%;
                        order: 4;
                    }
                }

                .cart-change-quantity {
                    opacity: 1;
                    pointer-events: auto;

                    @include touch {
                        display: none;
                    }
                }

                .cart-add {
                    .button.is-link {
                        width: initial;
                        padding: 0 15px;

                        span {
                            display: block;
                            margin-left: 6px;
                        }
                    }
                }

                .current-price {
                    font-size: 24px;
                    line-height: 0.69;

                    &:not(:first-child) {
                        margin-top: 5px;
                    }

                    span[currency-symbol] {
                        font-size: 12px;
                        //margin-left: 3px;
                    }
                }

                .regular-price {
                    margin-top: 5px;
                }

                .card-params {
                    margin-top: 8px;
                    grid-row-start: 4;
                    display: block;
                    grid-column: 1;

                    @include touch {
                        display: none;
                    }
                }

                .params-item {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    span {
                        display: block;
                        width: 40%;
                        margin-left: 20px;
                    }
                }

                .card-delivery {
                    display: block;
                    grid-column: 2;
                    align-self: end;

                    @include touch {
                        display: none;
                    }
                }
            }
        }

        >.column {
            margin: -1px;
            width: 25%;

            @include maxw1200 {
                width: calc(100% / 3);
            }

            @include touch {
                width: 50%;
                flex: initial;
            }

            @include mobile {
                margin: -0.5px;
            }
        }
    }

    ~.block-info {
        .container {
            @include maxw1280 {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .columns {
            width: 100%;
        }

        .column {
            &.is-one-fifth {
                @include responsive {
                    width: 260px;
                    flex-shrink: 0;
                }

                @include mobile {
                    width: 100%;
                }
            }

            &.is-four-fifths {
                @include responsive {
                    width: calc(100% - 260px);
                }

                @include mobile {
                    padding-left: 0;
                    width: 100%;
                }
            }
        }

        .faq-group {
            padding-left: 15px;

            @include mobile {
                padding-left: 0;
            }
        }
    }
}

.shop-category-filter-active {
    .selected-filter-items {
        display: flex;
        align-items: center;
        line-height: 28px;

        @include mobile {
            flex-flow: row;
            flex-wrap: wrap;
        }
    }

    .selected-filter-item {
        padding: 0 6px;
        color: #fff;
        background-color: $purple;
        font-size: 12px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-right: 6px;
        }

        .icon {
            width: 12px;
            height: 12px;
            margin-left: 4px;
        }
    }

    .selected-filter-items-clear {
        color: $purple;
        margin-left: 6px;
    }
}

.block-text-accordeon {
    margin-top: 80px;

    @include mobile {
        margin-top: 60px;
    }

    ._seo_text-accordeon {
        background-color: #fff;
        border: 2px solid $grey-border;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        position: relative;

        @include mobile {
            padding: 16px;
        }

        &.is-opened {
            &::after {
                background: initial;
            }

            .text-accordeon-descr {
                max-height: initial;
            }
        }

        &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 72px;
            left: 0;
            right: 0;
            // height: 48px;
            height: 80px;
            // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.49) 100%);
            background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0.49) 100%);
            z-index: 2;

            @include mobile {
                height: 48px;
                bottom: 47px;
            }
        }
    }

    .text-accordeon {
        // background-color: #fff;
        // border: 2px solid $grey-border;
        // padding: 30px;
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        // width: 100%;
        // position: relative;

        // @include mobile {
        //     padding: 16px;
        // }

        // &::after {
        //     display: block;
        //     content: '';
        //     position: absolute;
        //     bottom: 50px;
        //     left: 0;
        //     right: 0;
        //     // height: 48px;
        //     height: 80px;
        //     // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.49) 100%);
        //     background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0.49) 100%);
        //     z-index: 2;

        //     @include mobile {
        //         height: 48px;
        //         bottom: 47px;
        //     }
        // }

        // &.is-opened {
        //     &::after {
        //         background: initial;
        //     }

        //     .text-accordeon-descr {
        //         max-height: initial;
        //     }
        // }

        &-title {
            font-size: 24px;
            line-height: 2;

            @include mobile {
                font-size: 20px;
                line-height: 1.3;
                font-weight: 500;
            }
        }

        &-descr {
            margin-top: 5px;
            max-height: 126px;
            overflow: hidden;
            max-width: 1210px;
            font-weight: 400;

            @include mobile {
                margin-top: 24px;
                max-height: 237px;
            }

            >div {
                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 21px;

                    @include mobile {
                        margin-bottom: 19px;
                    }
                }
            }

            p {
                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 21px;

                    @include mobile {
                        margin-bottom: 19px;
                    }
                }
            }

            ul,
            ol {
                list-style: none;
                font-size: 16px;

                &:not(:last-child) {
                    margin-bottom: 21px;

                    @include mobile {
                        margin-bottom: 19px;
                    }
                }

                li {
                    position: relative;

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }

            ul {
                li {
                    padding-left: 16px;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background-color: #000;
                        border-radius: 50%;
                        left: 0;
                        top: 9px;
                    }
                }
            }

            ol {
                counter-reset: step-counter;

                li {
                    padding-left: 24px;
                    position: relative;
                    counter-increment: step-counter;

                    &::before {
                        content: counter(step-counter) '.';
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }

            a {
                text-decoration: underline;
                text-decoration-color: #9e61ff;
            }

            h2 {
                font-size: 20px;
                line-height: 1.5;
                color: $purple;

                &:not(:last-child) {
                    margin-bottom: 16px;

                    @include mobile {
                        margin-bottom: 14px;
                    }
                }
            }

            h3 {
                font-size: 18px;
                line-height: 1.5;
                color: $purple;

                &:not(:last-child) {
                    margin-bottom: 16px;

                    @include mobile {
                        margin-bottom: 14px;
                    }
                }
            }

            h4 {
                font-size: 16px;
                line-height: 1.5;
                color: $purple;

                &:not(:last-child) {
                    margin-bottom: 16px;

                    @include mobile {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        &-expand {
            margin-top: 16px;
            font-size: 12px;
            line-height: 28px;
            text-transform: uppercase;
            position: relative;
            z-index: 3;
            color: $purple;
            cursor: pointer;

            @include mobile {
                margin-top: 5px;
            }
        }
    }
}

.catalog-filter {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 60px;
    align-self: flex-start;

    @include mobile {
        position: fixed;
        transform: translateX(-100%);
        top: 0;
        left: 0;
        bottom: 0;
        transition: transform $duration;
        pointer-events: none;
        background-color: rgba($black, 0.3);
        overflow: auto;
        z-index: 1000;
        height: 100%;
    }

    &.is-opened {
        transform: translateX(0%);
        pointer-events: auto;
    }

    &.is-one-fifth {
        @include responsive {
            width: 260px;
            flex-shrink: 0;
        }

        @include mobile {
            width: 100%;
        }
    }

    .filter-return-button-mobile {
        display: none;
        width: calc(100% - 40px);
        max-width: 768px;
        height: 56px;
        flex-shrink: 0;

        @include mobile {
            display: flex;
        }
    }

    &-inner {
        // flex-grow: 1;
        width: 100%;
        max-height: calc(100vh - 68px);
        overflow-y: auto;

        @include mobile {
            background-color: $grey-bg;
            width: calc(100% - 40px);
            max-width: 768px;
            max-height: 100svh;
            height: 100%;
        }
    }

    &-group {
        background-color: $grey-bg;
        padding-top: 4px;

        // @include mobile {
        //     width: calc(100% - 40px);
        //     max-width: 768px;
        // }

        &:empty {
            display: none;
        }

        &:not(:last-child) {
            margin-bottom: 2px;

            @include mobile {
                margin-bottom: 0;
                border-bottom: 2px solid #fff;
            }
        }

        &-head {
            margin: 5px 0px 7px 0px;
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            cursor: pointer;

            @include mobile {
                margin: 10px 0;
            }
        }

        &-title {
            position: relative;
            font-size: 16px;
            // line-height: 2;
            line-height: 24px;
            width: 100%;
            padding-right: 20px;

            @include mobile {
                line-height: 1.37;
            }

            &::after {
                display: block;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'%3E%3Cpath d='M1.17918 2.91733e-07L5 3.71005L8.82082 3.89993e-08L10 1.14496L5 6L-9.18752e-07 1.14496L1.17918 2.91733e-07Z' fill='%23222222'/%3E%3C/svg%3E");
                width: 10px;
                height: 6px;
                position: absolute;
                // top: 14px;
                top: 8px;
                right: 0;
                transform: rotate(0deg);
            }
        }

        &.is-closed {
            .catalog-filter-group-body {
                height: 0 !important;
                padding-bottom: 0 !important;
            }

            .catalog-filter-group-title {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &-body {
            overflow: hidden;
            transition: height 0.5s;
        }

        &-reset {
            font-size: 12px;
            line-height: 1.83;
            color: $purple;
            margin-top: -4px;
        }

        .letter {
            padding: 0 15px;
        }

        .shop-filters-search {
            padding: 0 15px;

            input {
                box-shadow: initial !important;
                border: 0 !important;
                padding: 0 10px !important;
                margin-bottom: 0 !important;
                height: 30px;
                line-height: 30px;
                font-weight: 400;
                color: $grey-text;
                display: block;
                font-size: 14px !important;
                border-radius: 0 !important;

                @include mobile {
                    height: 40px;
                }

                &::placeholder {
                    opacity: 1;
                    color: inherit !important;
                }
            }
        }

        .shop-filters-price {
            padding-bottom: 0px;

            >.mb-2 {
                margin-bottom: 0px !important;
                padding: 0 15px;
            }

            >div[shop-filter-content] {
                padding: 0 15px 18px 15px;
            }

            .columns {
                @include mobile {
                    display: flex;
                }
            }

            .column {
                &.is-narrow {
                    flex: none;
                }
            }

            .title {
                position: relative;
                font-size: 16px;
                line-height: 2;
                width: 100%;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6' fill='none'%3E%3Cpath d='M1.17918 2.91733e-07L5 3.71005L8.82082 3.89993e-08L10 1.14496L5 6L-9.18752e-07 1.14496L1.17918 2.91733e-07Z' fill='%23222222'/%3E%3C/svg%3E");
                    width: 10px;
                    height: 6px;
                    position: absolute;
                    top: 14px;
                    right: 0;
                }
            }

            a.is-size-7 {
                font-size: 12px;
                line-height: 1.83;
                color: $purple;
                margin-top: -4px;
            }

            .columns.is-gapless.is-vcentered {
                margin-top: 8px;

                // padding: 0 15px !important;
                .button.is-small {
                    width: 35px;
                    height: 28px;
                    border: 0;
                    border-radius: 0;
                    background-color: $purple;
                    color: #fff;
                    padding: 0;
                    font-size: 12px;

                    @include mobile {
                        width: 44px;
                        height: 40px;
                    }
                }
            }

            >.columns.is-gapless.is-align-items-flex-end.mb-0 {
                margin: 0 15px;
            }

            .slider-target {
                // margin: 0 15px;
                height: 3px !important;
            }

            .slider-connect {
                background-color: $purple;
            }

            .slider-handle {
                width: 11px;
                height: 11px;
                box-shadow: initial;
                top: -4px;
                right: -6px;
                background-color: $purple;
            }
        }

        &-items {
            margin-top: 8px;
            padding-bottom: 10px;
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;

            @include mobile {
                max-height: initial;
            }
        }

        &-item {
            display: flex;
            flex-wrap: wrap;
        }

        .checkbox-item {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 3px 15px;
            transition: color $duration, background-color $duration;
            line-height: 1.57;
            font-weight: 400;

            &:hover {
                background-color: $purple-ultralight;
                color: $purple;

                .checkbox-amount {
                    color: $purple;
                }
            }

            .checkbox-default:checked+.checkbox-custom::before {
                opacity: 1;
            }

            .checkbox-custom {
                position: relative;
                width: 16px;
                height: 16px;
                background-color: #fff;
                border: 1px solid $grey-border;
                flex-shrink: 0;
                margin-right: 7px;

                @include mobile {
                    width: 22px;
                    height: 22px;
                    margin-right: 8px;
                }

                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    right: -1px;
                    bottom: -1px;
                    background-color: $purple;
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.99896 7.79982L1.19896 4.99982L0.265625 5.93316L3.99896 9.66649L11.999 1.66649L11.0656 0.733154L3.99896 7.79982Z' fill='white'/%3E%3C/svg%3E%0A");
                    opacity: 0;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px 10px;
                }
            }

            .checkbox-title {
                margin-right: auto;
                transform: translateY(2px);
            }

            .checkbox-amount {
                color: $grey-text;
            }
        }

        .badge {
            font-size: 80%;
            padding-left: 38px;
            margin-left: 0;
            transform: translateY(-4px);
        }
    }
}

.catalog-products {
    &.is-four-fifths {
        @include responsive {
            width: calc(100% - 260px);
        }

        @include mobile {
            width: 100%;
        }
    }
}

.card.product {
    border-radius: 0;
    background-color: transparent;
    box-shadow: initial;
    position: relative;
    z-index: 1;
    border: 0;
    width: 100%;
    // min-height: 100%;
    // min-height: 355px;
    // min-height: 380px;
    min-height: 390px;
    height: 100%;
    display: flex;
    color: $black;

    @include not-touch {
        &:hover {
            z-index: 2;

            .card-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 2;
                box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
            }

            // .product-toolbar {
            //     opacity: 1;
            //     pointer-events: auto;
            // }
            .cart-change-quantity {
                opacity: 1;
                pointer-events: auto;
            }

            .cart-add {
                >div:not(.columns) {
                    opacity: 1;
                }
            }

            .card-params {
                display: block;
            }
        }
    }

    @include mobile {
        min-height: 340px;
    }

    picture {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .card-inner {
        position: relative;
        border: 2px solid $grey-border;
        background-color: #fff;
        min-height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        transition: box-shadow 0.5s;

        @include mobile {
            border-width: 1px;
        }
    }

    .product-toolbar {
        // opacity: 0;
        // pointer-events: none;
        top: 13px;
        right: 10px;
        display: flex;
        flex-direction: row-reverse;

        @include mobile {
            top: 8px;
            right: 8px;
            flex-direction: column;
        }

        .button {
            color: $purple;
            width: 18px;
            height: 18px;
            filter: initial !important;

            &:not(:last-child) {
                margin-left: 15px;

                @include mobile {
                    margin-left: 0;
                    margin-bottom: 8px;
                }
            }

            &.is-transparent {
                color: rgba($black, 0.3);

                &:hover {
                    color: $purple-medium;
                }
            }
        }

        .icon {
            color: inherit;
            margin: 0 !important;

            &.compare {
                width: 18px;
                height: 18px;
            }

            &.wishlist {
                width: 13px;
                height: 18px;
            }
        }
    }

    .card-image {
        // background-color: $grey-bg;
        background-color: transparent;
        position: relative;

        &.is-padding {
            img {
                padding: 2rem !important;
            }
        }

        .image {
            width: 100%;

            @include mobile {
                padding-top: 100%;
            }

            img {
                object-fit: contain;
                border-radius: 0;
                padding: 16px;

                @include mobile {
                    padding: 8px 8px 0;
                }
            }
        }
    }

    .card-content {
        padding: 16px 10px 6px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @include mobile {
            padding: 12px 8px 20px;
        }
    }

    .labels {
        position: absolute;
        top: 12px;
        left: 10px;
        font-size: 12px;
        line-height: 17px;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 8px;
        font-weight: 500;
        z-index: 3;

        @include mobile {
            top: 8px;
            left: 8px;
            font-size: 10px;
            line-height: 1;
            grid-column-gap: 6px;
        }

        .promo {
            color: $purple;
        }

        .sticker {
            color: $green;
        }
    }

    .card-title {
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        height: 42px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include mobile {
            font-size: 14px;
            line-height: 1.4;
            height: initial;
            -webkit-line-clamp: 3;
        }
    }

    .card-number-availability {
        display: flex;
        align-items: center;
        margin-top: 3px;
        font-size: 14px;
        line-height: 18px;
        // line-height: 28px;
        font-weight: 400;
        flex-wrap: wrap;
        row-gap: 4px;
        margin-bottom: auto;

        // margin-bottom: 20px;
        // margin-bottom: 32px;
        @include mobile {
            margin-top: 6px;
            font-size: 10px;
            line-height: 12px;
            // flex-direction: row-reverse;
            // justify-content: space-between;
            width: 100%;
            margin-bottom: auto;
        }
    }

    .card-number {
        color: rgba($black, 0.5);
        // margin-right: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        // width: 50%;
        overflow: hidden;
        margin-right: 8px;

        // font-weight: 400;
        // @include mobile {
        //     width: initial;
        //     margin: 0;
        // }
    }

    .card-availability {
        margin-right: 8px;

        &.available {
            color: $green;
        }

        &.not-available {
            color: #f14668;
        }

        &.order {
            color: #9e61ff;
        }

        &.wait {
            color: #ffa825;
        }
    }

    .card-storage-amount,
    .card-next-supply {
        color: $grey-text;
        width: 100%;
    }

    .out-of-production {
        margin-top: 3px;
        // color: rgba($black, .5);
        color: $grey-text;
        font-size: 12px;
        line-height: 20px;
        padding: 0 8px;
        background-color: $grey-bg;
        font-weight: 400;
        display: inline-block;
        align-self: flex-start;

        @include mobile {
            margin-top: 6px;
            font-size: 10px;
            line-height: 12px;
            padding: 0;
            display: block;
        }

        +.card-offer {
            margin-top: 11px;
        }
    }

    .card-offer {
        display: flex;
        align-items: center;
        // margin-top: auto;
        margin-top: 32px;
        min-height: 61px;

        @include mobile {
            margin-top: 24px;
            min-height: initial;
        }
    }

    .card-prices {
        flex-shrink: 0;
        flex-grow: 0;
    }

    .price-label {
        font-size: 13px;
        color: #909090;
        font-weight: 400;
        margin-bottom: -4px;
    }

    .old-price {
        font-size: 12px;
        line-height: 1.38;
        text-decoration-line: line-through;
        color: rgba($black, 0.5);

        @include mobile {
            line-height: 1;
        }
    }

    .current-price {
        font-size: 20px;
        line-height: 1;

        @include mobile {
            line-height: 1;
        }

        &.color-dealer {
            color: $orange;
        }

        &:not(:first-child) {
            margin-top: 6px;
            line-height: 0.83;

            @include mobile {
                margin-top: 4px;
            }
        }
    }

    .price-annotation {
        display: none;
    }

    .cart-add {
        display: flex;
        align-items: center;
        margin-left: auto;
        position: relative;

        &.is-already-in-cart {
            .button.is-link {
                background-color: $purple-ultralight;
                color: $purple;
            }
        }

        .columns {
            margin: 0 !important;
            flex: initial !important;

            @include mobile {
                display: flex;
            }
        }

        >div:not(.columns) {
            font-size: 12px;
            position: absolute;
            right: 0;
            bottom: -21px;
            pointer-events: none;
            opacity: 0;
            transition: opacity $duration;
            font-weight: 400;

            a {
                display: none;
            }
        }

        .column {
            padding: 0;
            flex-basis: initial;
            flex-grow: 0;
        }

        .button.is-link {
            width: 44px;
            height: 44px;
            background-color: $purple;
            border: 0;
            border-radius: 0 !important;
            padding: 0;

            &:focus {
                box-shadow: initial;
            }

            .icon {
                margin: 0 !important;
                width: 20px !important;
                height: 18px !important;
            }

            span {
                display: none;
            }
        }
    }

    .cart-change-quantity {
        margin-right: 12px;
        opacity: 0;
        transition: opacity $duration;
        pointer-events: none;

        @include mobile {
            display: none;
        }
    }

    .card-params {
        display: none;
        margin-top: 20px;
        font-size: 12px;
        line-height: 1.5;
        font-weight: 400;
        color: $grey-text;

        span {
            color: $black;
        }

        a {
            color: $purple;
        }
    }

    .card-delivery {
        display: none;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        color: $grey-text;

        p {
            margin-bottom: 0;
        }
    }
}

.pagination-wrapper {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;

    @include touch {
        flex-direction: column;
    }

    @include mobile {
        margin-top: 24px;
        padding: 0;
    }

    .shop-pagination {
        margin-bottom: 0 !important;

        @include touch {
            margin-bottom: 16px !important;
        }

        @include mobile {
            width: 100%;
        }
    }

    .shop-pagination-more {
        @include mobile {
            width: 100%;
        }

        .button.is-small {
            height: 44px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            border: 0;
            border-radius: 0;
            color: #fff;
            background-color: $purple;

            @include touch {
                width: 100%;
            }

            &:focus {
                box-shadow: initial;
            }

            .icon.is-small {
                margin-left: 0 !important;
                margin-right: 8px !important;
                width: 14px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='20' viewBox='0 0 14 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.9987 5.00065V7.50065L10.332 4.16732L6.9987 0.833984V3.33398C3.31536 3.33398 0.332031 6.31732 0.332031 10.0007C0.332031 11.309 0.715364 12.5257 1.36536 13.5506L2.58203 12.334C2.20703 11.6423 1.9987 10.8423 1.9987 10.0007C1.9987 7.24232 4.24036 5.00065 6.9987 5.00065ZM12.632 6.45065L11.4154 7.66732C11.782 8.36732 11.9987 9.15898 11.9987 10.0007C11.9987 12.759 9.75703 15.0006 6.9987 15.0006V12.5007L3.66536 15.834L6.9987 19.1673V16.6673C10.682 16.6673 13.6654 13.684 13.6654 10.0007C13.6654 8.69232 13.282 7.47565 12.632 6.45065Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .pagination {
        margin: 0;

        @include mobile {
            width: 100%;
            flex-wrap: nowrap;
        }

        &.is-centered {
            .pagination-previous {
                order: 1;
            }

            .pagination-list {
                order: 2;
            }

            .pagination-next {
                order: 3;
            }
        }

        &.is-small {
            @include mobile {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .pagination-previous,
    .pagination-next {
        width: 44px;
        height: 44px;
        border: 0;
        border-radius: 0;
        color: #fff;
        background-color: $purple;
        padding: 0;
        margin: 0;
        min-width: initial;

        @include mobile {
            flex-grow: 1;
        }

        @include maxw639 {
            max-width: 44px;
        }

        &[disabled] {
            display: none;
        }
    }

    .pagination-previous {
        .icon {
            transform: rotate(-180deg);
        }
    }

    .icon {
        &.paginator-arrow-right {
            width: 7px;
            height: 11px;
        }
    }

    .pagination-list {
        @include touch {
            flex-wrap: nowrap;
        }

        @include mobile {
            flex-grow: 12;
        }
    }

    .pagination-link {
        min-width: initial;
        width: 44px;
        height: 44px;
        // margin: 0 -0.5px;
        margin: 0;
        border: 1px solid $grey-border;
        border-right: 0;
        color: $grey-text;
        border-radius: 0;
        padding: 0;

        @include touch {
            width: 100%;
            max-width: 44px;
        }

        @include mobile {
            max-width: initial;
        }

        &.is-current {
            border: 0;
            background-color: $purple-ultralight;
            color: $purple;
        }
    }

    .pagination-ellipsis {
        border-left: 1px solid $grey-border;
        height: 44px;
        margin: 0;
        margin-left: -1px;
        padding: 0 8px;
        width: 100%;
        min-width: initial;

        @include maxw639 {
            padding: 0;
        }
    }
}

body:has(.shop-product) .scroll-top-btn {
    display: none;
}

.shop-product {
    z-index: 100;

    .price-label {
        font-size: 13px;
        color: #909090;
        font-weight: 400;
        margin-bottom: -4px;

        @include mobile {
            display: none;
        }
    }

    .labels {
        position: absolute;
        left: 145px;

        &.no-images {
            left: 16px;
        }

        top: 10px;
        z-index: 100;

        @include maxw1200 {
            left: 135px;
        }

        @include mobile {
            left: 10px;
        }

        .promo {
            color: $purple;
        }

        .sticker {
            color: $green;
        }

        span {
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }

    .icon {
        &.star {
            width: 18px;
            height: 17px;
        }

        &.wishlist {
            width: 12px;
            height: 16px;
        }

        &.compare {
            width: 16px;
            height: 16px;
        }

        &.check {
            width: 9px;
            height: 7px;
        }

        &.drop-arrow {
            width: 8px;
            height: 5px;
        }
    }

    .product-columns {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        align-items: start;

        @include mobile {
            grid-template-columns: minmax(0, 1fr);
            align-items: initial;
        }
    }

    .product-photos {
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        position: relative;
    }

    .gallery-wrapper,
    .product-gallery-fullscreen-content {
        display: flex;
        width: 100%;

        &.is-padding {
            img {
                padding: 2rem;
            }
        }
    }

    .thumbs-gallery-wrapper {
        width: 80px;
        flex-shrink: 0;
        margin-right: 55px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include maxw1200 {
            margin-right: 40px;
        }

        @include touch {
            margin-right: 32px;
        }

        @include mobile {
            display: none;
        }
    }

    .thumb-btn {
        width: 22px;
        height: 22px;
        border: 0;
        background-color: #f5f5f5;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.36998 6L4.1776 2.80762L0.985229 6L-8.61286e-08 5.0148L4.1776 0.837199L8.35521 5.0148L7.36998 6Z' fill='black'/%3E%3C/svg%3E%0A");
        background-size: 9px 6px;
        background-position: center;
        background-repeat: no-repeat;

        &.swiper-button {
            &-disabled {
                display: block !important;
                opacity: 0.1;
                pointer-events: none;
            }
        }

        &.thumb-prev {
            margin-bottom: 4px;
        }

        &.thumb-next {
            margin-top: 4px;
            transform: rotate(-180deg);
        }
    }

    .product-gallery-thumbs {
        width: 100%;
        height: 440px;

        // height: 485px;
        @include mobile {
            height: auto;
        }

        .swiper-slide {
            padding: 6px;
            border: 1px solid transparent;
            height: auto !important;
            cursor: pointer;

            &-thumb-active {
                border-color: $purple-medium;
                cursor: default;
            }
        }
    }

    .main-gallery-wrapper {
        position: relative;
        width: calc(100% - 80px - 55px);

        @include maxw1200 {
            width: calc(100% - 80px - 40px);
        }

        @include touch {
            width: calc(100% - 80px - 32px);
        }

        @include mobile {
            width: 100%;
        }
    }

    .product-gallery-main {
        width: 100%;
        border: 2px solid $grey-border;

        @include mobile {
            border: 0;
        }

        // .swiper-slide {
        // }
        img {
            // cursor: pointer;
            cursor: zoom-in;
        }
    }

    .swiper-slide {
        picture {
            display: block;
        }

        img {
            display: block;
        }
    }

    *:is(.swiper-button-prev, .swiper-button-next) {
        @include mobile {
            display: none;
        }
    }

    .swiper-pagination-progressbar {
        width: 100%;
        height: 2px;
        background-color: $grey-border;
        display: none;

        @include mobile {
            display: block;
        }

        &-fill {
            background-color: $black;
        }
    }

    .column-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
        position: relative;

        @include maxw1200 {
            padding-left: 70px;
            padding-right: 70px;
        }

        @include touch {
            padding-left: 32px;
            padding-right: 0;
        }

        @include mobile {
            padding-left: 0;
        }
    }

    .product-info {
        grid-column: 2;
        grid-row-start: 1;
        grid-row-end: span 2;
        width: 100%;

        @include mobile {
            margin-top: 24px !important;
            grid-column: 1;
            grid-row-start: initial;
            grid-row-end: initial;
            grid-row: 2;
        }

        &-head {
            display: flex;
            align-items: center;
            width: 100%;

            @include mobile {
                order: 4;
                margin-top: 8px;
                width: 50%;
                justify-content: flex-end;
            }

            .button {
                padding: 0;
                border: 0;
                height: auto;
                font-size: 14px;
                line-height: 1.17;
                margin-right: 20px;
                filter: initial !important;
                color: $purple;
                background-color: transparent;

                @include mobile {
                    position: absolute;
                    // position: fixed;
                    z-index: 11;
                    // bottom: 124px;
                    // top: 170px;
                    top: 165px;
                    // bottom: 20px;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.is-transparent {
                    color: $grey-text;
                }

                &.wishlist {
                    @include mobile {
                        margin-right: 0;
                        right: 0;
                        // right: 150px;
                    }

                    .icon {
                        width: 16px;
                        height: 24px;
                    }
                }

                &.compare {
                    @include mobile {
                        right: 40px;
                        // right: 190px;
                        margin-right: 0;
                    }

                    .icon {
                        @include mobile {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &:focus {
                    box-shadow: initial;
                }

                .icon {
                    margin: 0 6px 0 0 !important;

                    @include mobile {
                        margin: 0 !important;
                    }
                }

                span {
                    @include mobile {
                        display: none !important;
                    }
                }
            }
        }
    }

    .rating-stars {
        display: flex;
        align-items: center;
        color: $purple;
        font-size: 16px;

        // @include mobile {
        //     order: 4;
        //     margin-top: 8px;
        // }
        span {
            margin-left: 4px;
            padding-top: 3px;
        }
    }

    .title {
        margin-top: 10px;
        font-family: $title-font;
        font-size: 32px;
        font-weight: 500;
        line-height: 1.2;
        color: $black;
        width: 100%;
        margin-bottom: 16px;

        @include mobile {
            margin-top: 26px;
            font-size: 20px;
            line-height: 1.3;
            order: 5;
            width: 100%;
            margin-bottom: 0;
            min-height: 52px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .product-number {
        background-color: $sand;
        line-height: 17px;
        padding: 3px 8px;
        font-weight: 400;

        @include mobile {
            order: 1;
            max-width: 50%;
        }
    }

    .product-availability {
        display: flex;
        align-items: center;

        @include mobile {
            order: 2;
            width: 50%;
            justify-content: flex-end;
        }

        &.available {
            color: $green;
        }

        &.not-available {
            color: #f14668;
        }

        &.order {
            color: #9e61ff;
        }

        &.wait {
            color: #ffa825;
        }

        .icon-wrapper {
            width: 15px;
            height: 15px;
            background-color: currentColor;
            margin-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }

        .icon {
            color: #fff;
        }

        .line {
            width: 6px;
            height: 1px;
            display: block;
            background-color: #fff;
        }
    }

    .product-storage-amount,
    .product-next-supply {
        width: 100%;
        font-size: 13px;
        line-height: 1.27;
        // font-weight: 500;
        font-weight: 400;
        color: $grey-text;

        span {
            // font-weight: 400;
            color: $black;
        }

        +.product-brand {
            margin-top: 10px;
        }
    }

    .product-storage-amount {
        margin-top: 14px;

        @include mobile {
            order: 5;
        }
    }

    .product-next-supply {
        margin-top: 6px;
    }

    .product-brand {
        width: 100%;
        margin-top: 12px;
        font-size: 13px;
        line-height: 1.27;
        font-weight: 400;
        color: $grey-text;

        @include mobile {
            order: 3;
            margin-top: 8px;
            line-height: 28px;
            width: 50%;
        }

        span {
            color: $purple;
            margin-left: 4px;
        }
    }

    .out-of-production {
        margin-top: 12px;
        color: $grey-text;
        font-size: 12px;
        line-height: 20px;
        padding: 0 8px;
        background-color: $grey-bg;
        font-weight: 400;
        display: inline-block;
        align-self: flex-start;
    }

    .card-offer {
        margin-top: 22px;
        width: 100%;

        @include mobile {
            margin-top: 24px;
            order: 5;

            // position: fixed;
            // bottom: 0;
            // z-index: 10;
            // left: 0;
            // right: 0;
            // padding: 10px;
            // background: $grey-bg;
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
        }

        &.is-fixed {
            @include mobile {
                position: fixed;
                bottom: 0;
                z-index: 10;
                left: 0;
                right: 0;
                padding: 10px;
                background: $grey-bg;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .columns {
            margin: 0 !important;
            flex-direction: column;
        }

        .column {
            padding: 0;
        }
    }

    .card-offer-fixed-mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        opacity: 0;
        pointer-events: none;
        background-color: $grey-bg;
        transition: bottom $duration, opacity $duration;
        z-index: 11;

        @include mobile {
            display: flex;
        }

        &.is-visible {
            opacity: 1;
            pointer-events: auto;
        }

        .card-prices {
            min-width: 84px;
        }

        .cart-change-quantity {
            position: relative;
            bottom: 0;
            right: 0;
            width: 100px;
            height: 52px;
            border: 1px solid $grey-border;
        }

        .controls {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;

            .button {
                padding: 0;
                border: 0;
                height: auto;
                font-size: 14px;
                line-height: 1.17;
                filter: initial !important;
                color: $purple;
                background-color: transparent;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.is-transparent {
                    color: $grey-text;
                }

                &.wishlist {

                    .icon {
                        width: 16px;
                        height: 24px;
                    }
                }

                &.compare {
                    .icon {
                        width: 24px;
                        height: 24px;
                    }
                }

                &:focus {
                    box-shadow: initial;
                }
            }
        }

        .cart-add {
            margin-top: 0;

            .columns {
                display: flex;
                align-items: center;
                margin: 0;

                &:not(:last-child) {
                    margin: 0;
                }
            }

            .column {
                padding: 0;

                &:nth-child(2) {
                    margin-left: 16px;
                }
            }

            .button.is-link.is-small {
                border: 0;
                border-radius: 0;
                width: 52px;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background-color: $purple;
                font-size: 0;
                padding: 0;

                .icon {
                    width: 22px;
                    height: 28px;

                    &:first-child:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }

            >div:nth-child(2) {
                display: none;
            }
        }
    }

    .card-prices {
        @include mobile {
            min-height: 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .old-price {
            text-decoration: line-through;
            font-size: 12px;
            line-height: 1.38;
            color: $grey-text;
        }

        .current-price {
            font-size: 24px;
            line-height: 0.69;
            display: flex;
            align-items: baseline;

            &.color-dealer {
                color: $orange;
            }

            &:not(:first-child) {
                margin-top: 9px;

                @include mobile {
                    margin-top: 0;
                }
            }

            .price-annotation {
                color: $grey-text;
                font-size: 15px;
                font-weight: 400;
                line-height: 1.1;
                margin-right: 15px;
            }

            span[currency-symbol] {
                font-size: 14px;
                margin-left: 5px;
            }
        }

        .regular-price {
            margin-top: 12px;
            font-size: 15px;
            // font-weight: 400;
            line-height: 1.1;

            &:not(:first-child) {
                margin-top: 9px;
            }

            .price-annotation {
                color: $grey-text;
                font-size: 15px;
                font-weight: 400;
                line-height: 1.1;
                margin-right: 10px;
            }

            span[currency-symbol] {
                margin-left: 3px;
            }
        }
    }

    .cart-add {
        margin-top: 20px;
        position: relative;

        @include mobile {
            margin-top: 30px;
            // margin-top: 0;
            // width: 120px;
        }

        .button.is-link.is-small {
            width: 100%;
            height: 54px;
            border: 2px solid $purple;
            color: #fff;
            background-color: $purple;
            font-size: 16px;
            line-height: 1.87;

            @include mobile {
                // height: 44px;
                // font-size: 14px;
            }

            .icon:first-child:not(:last-child) {
                margin-left: 0;
                width: 20px;
                height: 17px;
                margin-right: 9px;

                @include mobile {
                    margin-right: 6px;
                }
            }
        }

        >div:nth-child(2) {
            display: flex;
            align-items: center;
            padding-top: 12px;

            @include mobile {
                // display: none;
            }

            a {
                color: $grey-text;
                margin-left: 10px;
            }
        }
    }

    .cart-change-quantity {
        position: absolute;
        bottom: calc(100% + 20px);
        right: 0;

        @include mobile {
            // display: none;
            // bottom: calc(100% + 10px);
            bottom: calc(100% + 32px);
            // right: initial;
            // right: 100px;
            right: calc(50% - 46px);
            // left: 50%;
            // transform: translateX(-50%);
        }
    }

    .shop-order-one-click {
        margin-top: 10px;
        width: 100%;

        @include mobile {
            margin-top: 12px;
            order: 6;
        }

        .button.is-link.is-small {
            width: 100%;
            height: 54px;
            border: 2px solid $purple;
            color: $purple;
            background-color: #fff;
            font-size: 16px;
            line-height: 1.87;

            @include mobile {
                height: 44px;
                font-size: 14px;
            }
        }
    }

    .product-bottom-info {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 490px;

        @include mobile {
            order: 7;
        }

        &.is-open {
            .bottom-info-description {
                -webkit-line-clamp: unset;
            }

            .icon {
                transform: rotate(180deg);
            }

            p {
                &:not(:first-child) {
                    display: block;
                }
            }
        }

        .bottom-info-description {
            font-weight: 400;
            color: $grey-text;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }

        p {
            &:not(:first-child) {
                display: none;
            }
        }

        ul {
            margin-top: 0;
        }
    }

    .bottom-info-title {
        color: #000;
        font-size: 16px;
        line-height: 1.75;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        cursor: pointer;

        span {
            margin-right: 4px;
        }
    }

    // #product-tabs {
    //     margin-top: 0 !important;

    //     .column {
    //         position: relative;
    //     }
    // }
    .product-tabs {
        margin-top: 0 !important;
        grid-column: 1;
        grid-row: 2;
        width: 100%;

        @include mobile {
            grid-row: 3;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        .column {
            position: relative;
        }
    }

    .tabs {
        overflow: initial;

        @include mobile {
            overflow-x: auto;
            // position: sticky;
            // top: 0;
        }

        &:not(:last-child) {
            margin-bottom: initial;
        }

        ul {
            border: 0;
        }

        li {
            font-size: 18px;
            color: $grey-text;
            line-height: 1.78;
            position: relative;

            @include mobile {
                line-height: 1.44;
                padding-bottom: 10px;
            }

            &:not(:last-child) {
                margin-right: 38px !important;

                @include mobile {
                    margin-right: 28px !important;
                }

                &::after {
                    content: '/';
                    top: 0;
                    left: calc(100% + 15px);
                    pointer-events: none;
                    position: absolute;

                    @include mobile {
                        left: calc(100% + 10px);
                    }
                }
            }

            &.is-active {
                color: $black;

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;

                    // border-bottom: 2px solid currentColor;
                    // top: calc(100% + 8px);
                    @include mobile {
                        top: calc(100% + 8px);
                    }
                }

                &::after {
                    color: $grey-text;
                }

                a {
                    color: inherit;
                    border-bottom: initial;

                    &::after {
                        bottom: 0;

                        @include mobile {
                            top: calc(100% + 8px);
                            bottom: initial;
                        }
                    }
                }
            }

            a {
                padding: 0;
                border: 0;
                margin: initial;
                color: inherit;
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    .tab-content {
        margin-top: 34px;

        @include mobile {
            margin-top: 18px;
        }
    }

    .tab {
        display: none;
        flex-direction: column;
        align-items: flex-start;

        @include mobile {
            padding: 0;
        }

        &.is-active {
            display: flex;
        }
    }

    .product-params {
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;

        .product-params-table {
            width: 100%;
            margin: 0;

            a {
                border-bottom: 1px solid #000;
            }

            @include mobile {
                display: flex;
                flex-wrap: wrap;
            }

            &:not(:first-child) {
                margin-top: 22px;
            }

            .column {
                font-size: 16px;
                padding-top: 6px;
                padding-bottom: 6px;

                @include mobile {
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 35px;
                }

                &:nth-child(2n + 1) {
                    color: $grey-text;

                    @include mobile {
                        padding-left: 10px;
                        padding-right: 0;
                    }
                }

                &:nth-child(4n + 1),
                &:nth-child(4n + 2) {
                    background-color: $grey-bg;
                }

                &.is-5 {
                    @include mobile {
                        width: 50%;
                        flex: initial;
                    }
                }

                &.is-7 {
                    @include mobile {
                        width: 50%;
                        flex: initial;
                    }
                }
            }

            .param-item-title {
                display: flex;
                align-items: center;
                line-height: 1.4;

                @include mobile {
                    margin: 7px 0;
                }

                &-annotation {
                    margin-left: 5px;
                    position: relative;
                    z-index: 1;

                    &:hover {
                        z-index: 2;

                        small {
                            display: block;
                        }
                    }

                    .annotation-hover {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        display: block;
                        background-color: $purple;
                        background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.26731 5.29423L3.34038 4.53269C3.48654 4.425 3.61731 4.32885 3.73269 4.24423C3.85577 4.15192 3.95962 4.05577 4.04423 3.95577C4.12885 3.85577 4.19038 3.74423 4.22885 3.62115C4.275 3.49038 4.29808 3.33654 4.29808 3.15962C4.29808 3.02115 4.27115 2.88654 4.21731 2.75577C4.17115 2.61731 4.09808 2.49423 3.99808 2.38654C3.89808 2.27885 3.76731 2.19423 3.60577 2.13269C3.45192 2.06346 3.26731 2.02885 3.05192 2.02885C2.59038 2.02885 2.22885 2.16731 1.96731 2.44423C1.71346 2.71346 1.58654 3.08654 1.58654 3.56346H0.375C0.375 3.125 0.444231 2.74038 0.582692 2.40962C0.721154 2.07115 0.909615 1.79038 1.14808 1.56731C1.38654 1.33654 1.66731 1.16346 1.99038 1.04808C2.32115 0.932692 2.675 0.875 3.05192 0.875C3.475 0.875 3.84423 0.940385 4.15962 1.07115C4.475 1.19423 4.73654 1.35962 4.94423 1.56731C5.15192 1.775 5.30577 2.01731 5.40577 2.29423C5.51346 2.56346 5.56731 2.84423 5.56731 3.13654C5.56731 3.45192 5.51346 3.72885 5.40577 3.96731C5.30577 4.20577 5.175 4.41731 5.01346 4.60192C4.85192 4.78654 4.675 4.94808 4.48269 5.08654C4.29038 5.21731 4.10962 5.34038 3.94038 5.45577L3.53654 5.73269V6.58654H2.26731V5.29423ZM2.23269 7.70577H3.57115V9.125H2.23269V7.70577Z' fill='white'/%3E%3C/svg%3E%0A");
                        background-size: 6px 10px;
                        background-repeat: no-repeat;
                        background-position: center;
                        cursor: pointer;
                    }

                    small {
                        display: none;
                        position: absolute;
                        filter: drop-shadow(0px 1px 5px rgba(34, 34, 34, 0.15));
                        box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);
                        top: calc(100% + 10px);
                        padding: 7px 10px;
                        background-color: #fff;
                        left: calc(50% - 100px);
                        width: 200px;
                        color: $black;
                        line-height: 1.4;
                        font-weight: 400;

                        &::before {
                            display: block;
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 6px 7px 6px;
                            border-color: transparent transparent #fff transparent;
                            position: absolute;
                            bottom: 100%;
                            left: calc(50% - 6px);
                        }
                    }
                }
            }

            &-toggler {
                margin-top: 18px;
                color: $purple;
                line-height: 28px;
                display: flex;
                align-items: center;

                span {
                    margin-right: 4px;
                }
            }
        }
    }

    .product-description {
        font-size: 15px;
        line-height: 1.4;
        font-weight: 400;
        color: $grey-text;

        span {
            font-family: $title-font;
            font-size: 18px;
            line-height: 1.3;
            color: #000;
            display: block;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        b {
            font-weight: 500;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        img {
            display: block;
            max-width: 100%;

            &:not(:first-child) {
                margin-top: 14px;
            }

            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }

        ul {
            list-style: initial;
            padding-left: 20px;
            margin-bottom: 10px;

            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    }

    .empty-feedback-text {
        h3 {
            font-size: 18px;
            line-height: 1.3;
            font-family: $title-font;
            font-weight: 500;
            color: #000;
        }

        p {
            font-size: 15px;
            line-height: 1.4;
            margin-top: 10px;
        }
    }

    .product-review-item {
        padding: 20px 20px 15px;
        border: 2px solid $grey-border;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &-head {
            display: flex;
            align-items: center;
            color: $grey-text;
            line-height: 1;
        }

        &-date {
            margin-right: 16px;
            position: relative;

            &::after {
                display: block;
                content: '';
                width: 3px;
                height: 3px;
                background-color: currentColor;
                position: absolute;
                top: 5px;
                right: -10px;
                pointer-events: none;
            }
        }

        &-author {
            margin-right: 12px;
        }

        &-rating {
            .icon.star {
                width: 14px;
                height: 13px;
            }

            span {
                font-size: 14px;
                line-height: 1;
                padding-top: 2px;
            }
        }

        &-message {
            margin-top: 13px;
            line-height: 17px;
            font-weight: 400;
        }
    }

    .shop-reviews {
        margin-top: 20px;
        width: 100%;
        max-width: 590px;

        &-form {
            .column.has-text-right {
                @include mobile {
                    // margin-top: 16px;
                    display: none;
                }
            }
        }

        .button {
            color: #fff;
            background-color: $purple;
            border-radius: 0;
            border-color: $purple;
            height: 44px;
        }

        .modal {
            // position: initial;
            // overflow: initial;
            // justify-content: initial;
            // margin-top: 20px;

            &.is-active {
                display: flex;
            }

            // &-background {
            //     display: none;
            // }

            &-close {
                // display: none;
                z-index: 2;
            }

            &-content {
                // margin: initial;
                // max-height: initial;
                max-width: 590px;
                // overflow: initial;
            }

            .box {
                padding-top: 30px;
            }

            .field:not(.schema-row) {
                .columns {
                    margin: 0;
                }

                .column {
                    padding: 0;
                }

                .button {
                    &[type='submit'] {
                        margin-top: 0;
                    }
                }
            }

            .textarea {
                resize: none;
                background-color: $grey-bg;
                height: 125px;
                border: 0;
                border-radius: 0;
                box-shadow: initial;
                padding: 15px;
            }
        }
    }

    .product-tab-info-group {
        padding: 15px 20px;
        border: 2px solid $grey-border;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &-title {
            color: $grey-text;
            line-height: 1;
        }

        &-content {
            margin-top: 10px;
            line-height: 1.4;
            font-weight: 400;

            p {
                line-height: 1.4;
                font-weight: 400;
            }

            strong {
                display: block;
                font-weight: 500;
            }
        }

        .subgroup {
            line-height: 1.5;
            font-weight: 400;

            &:not(:last-child) {
                margin-bottom: 0;
            }

            .column:first-child {
                color: $grey-text;
            }
        }
    }
}

.shop-cross-sale,
.viewed-container {
    margin-top: 80px !important;
    position: relative;
    z-index: 4;

    @include mobile {
        margin-top: 60px !important;
    }

    .container.mt-6 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        >a {
            display: none;
        }
    }

    .mb-6.mt-5 {
        margin-top: 24px !important;
        margin-bottom: 0 !important;
    }

    .title.is-5 {
        font-family: $title-font;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.2;

        @include maxw1200 {
            font-size: 36px;
        }

        @include touch {
            font-size: 32px;
        }

        @include mobile {
            font-size: 24px;
            line-height: 1.3;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }
    }

    .swiper-container {
        @include mobile {
            padding: 0 10px;
        }
    }

    .swiper-slide {

        // max-width: 252px;
        @include mobile {
            width: 240px;
        }

        // &:not(:last-child) {
        //     margin-right: 16px;
        //     @include mobile {
        //         margin-right: -1px;
        //     }
        // }
    }

    .swiper-button {

        &-prev,
        &-next {
            width: 42px;
            height: 42px;
            transform: initial !important;
            // top: calc(50% - 21px);
            top: 50%;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: -1px 1px 5px rgba(34, 34, 34, 0.2);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 11px 17px;
            z-index: 2;

            @include mobile {
                display: none;
            }

            &::after {
                content: none;
            }
        }

        &-prev {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4199 2.16118L4.26905 8.31205L10.4199 14.4629L8.52171 16.3612L0.472553 8.31205L8.52171 0.262901L10.4199 2.16118Z' fill='%23222222'/%3E%3C/svg%3E%0A");
            left: 11px;
            right: initial;
        }

        &-next {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.580078 2.16118L6.73095 8.31205L0.580078 14.4629L2.47829 16.3612L10.5274 8.31205L2.47829 0.262901L0.580078 2.16118Z' fill='%23222222'/%3E%3C/svg%3E%0A");
            right: 11px;
        }

        &-disabled {
            display: none !important;
        }
    }
}

.shop-cross-sale {
    .mb-6.mt-5 {
        margin-top: 42px !important;
        margin-bottom: 0 !important;
    }

    z-index: 20 !important;

    // margin-top: 80px !important;

    // @include mobile {
    //     margin-top: 60px !important;
    // }

    // .container {
    //     >.title.is-5 {
    //         font-size: 40px;
    //         line-height: 1.2;
    //         font-family: $title-font;
    //         font-weight: 500;

    //         @include maxw1200 {
    //             font-size: 36px;
    //         }

    //         @include touch {
    //             font-size: 32px;
    //         }

    //         @include mobile {
    //             font-size: 24px;
    //             line-height: 1.3;
    //         }
    //     }

    // }
    // #cross-sale-tabs {
    //     display: none;
    // }
    .tabs {
        margin-top: 24px;
        overflow: initial;
        width: 100%;

        &::-webkit-scrollbar {
            @include mobile {
                display: none;
            }
        }

        .a {
            padding: 0;
            margin: 0;
            border: 0;
            font-size: 14px;
            line-height: 1;
            position: relative;
            color: $grey-text;
            cursor: pointer;

            &::after {
                display: block;
                position: absolute;
                border-bottom: 2px solid currentColor;
                left: 0;
                right: 0;
                top: calc(100% + 17px);
                pointer-events: none;
            }
        }

        @include mobile {
            // overflow: auto;
            overflow: scroll;
            margin-left: -10px;
            margin-right: -10px;
            width: calc(100% + 20px);
            scrollbar-width: none;
        }

        &:not(:last-child) {
            margin-bottom: 0;
        }

        ul {
            border: 0;

            @include mobile {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        li {
            @include mobile {
                padding-bottom: 19px;
            }

            &:not(:last-child) {
                margin-right: 24px;
            }

            &.is-active {
                .a {
                    color: $black;
                    pointer-events: none;

                    &:after {
                        content: '';
                    }
                }
            }
        }
    }
}

.viewed-container {
    // margin-top: 80px !important;
    // position: relative;
    // z-index: 3;

    // @include mobile {
    //     margin-top: 60px !important;
    // }

    .is-relative {
        margin-bottom: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        @include touch {
            flex-wrap: wrap;
            gap: 16px;
        }
    }

    // .title.is-5 {
    //     font-family: $title-font;
    //     font-size: 40px;
    //     font-weight: 500;
    //     line-height: 1.2;

    //     @include maxw1200 {
    //         font-size: 36px;
    //     }

    //     @include touch {
    //         font-size: 32px;
    //     }

    //     @include mobile {
    //         font-size: 24px;
    //         line-height: 1.3;
    //     }
    // }

    .columns {
        margin: 0;
        width: calc(100% + 4px);

        @include maxw1200 {
            width: calc(100% + 3px);
        }

        @include touch {
            width: calc(100% + 2px);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .column.is-3 {
        width: calc(25% + 2px);
        // margin-left: -1px;
        // margin-right: -1px;
        margin: -1px;

        @include maxw1200 {
            width: calc((100% / 3) + 2px);
        }

        @include touch {
            width: calc(50% + 2px);
        }
    }

    // .swiper-container {
    //     @include mobile {
    //         margin-left: -10px;
    //         margin-right: -10px;
    //         width: calc(100% + 20px);
    //     }
    // }
    // .swiper-wrapper {
    //     @include mobile {
    //         padding: 0 10px;
    //     }
    // }

    // .swiper-slide {
    //     @include mobile {
    //         width: 240px;
    //     }
    // }

    // .swiper-button {

    //     &-prev,
    //     &-next {
    //         width: 42px;
    //         height: 42px;
    //         transform: initial !important;
    //         // top: calc(50% - 21px);
    //         top: 50%;
    //         border-radius: 50%;
    //         background-color: #fff;
    //         box-shadow: -1px 1px 5px rgba(34, 34, 34, 0.2);
    //         background-position: center;
    //         background-repeat: no-repeat;
    //         background-size: 11px 17px;
    //         z-index: 2;

    //         @include mobile {
    //             display: none;
    //         }

    //         &::after {
    //             content: none;
    //         }
    //     }

    //     &-prev {
    //         background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4199 2.16118L4.26905 8.31205L10.4199 14.4629L8.52171 16.3612L0.472553 8.31205L8.52171 0.262901L10.4199 2.16118Z' fill='%23222222'/%3E%3C/svg%3E%0A");
    //         left: 11px;
    //         right: initial;
    //     }

    //     &-next {
    //         background-image: url("data:image/svg+xml,%3Csvg width='11' height='17' viewBox='0 0 11 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.580078 2.16118L6.73095 8.31205L0.580078 14.4629L2.47829 16.3612L10.5274 8.31205L2.47829 0.262901L0.580078 2.16118Z' fill='%23222222'/%3E%3C/svg%3E%0A");
    //         right: 11px;
    //     }

    //     &-disabled {
    //         display: none !important;
    //     }
    // }
}

.breadcrumb {
    font-size: 12px;
    line-height: 1.25;
    color: $grey-text;
    width: 100%;

    li.is-active a {
        color: inherit;
    }

    a {
        color: inherit;
        padding: 0 3px;
    }

    @include mobile {
        ul {
            flex-wrap: nowrap;
        }
    }
}

.block-title-list {
    .title-list-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background-color: $grey-bg;
    }

    .block-head {
        margin-bottom: 0;
    }

    .title-list-content {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mobile {
            display: none;
            margin-top: 30px;
        }

        &.is-opened {
            @include mobile {
                display: flex;
            }
        }
    }

    .block-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 23px;
        max-width: 1200px;
        width: 100%;
        list-style: none;

        @include mobile {
            grid-gap: 16px;
        }

        @include maxw639 {
            grid-template-columns: 1fr;
        }

        li {
            // width: 50%;
            padding-left: 30px;
            font-size: 16px;
            line-height: 1.37;
            color: #000;
            position: relative;

            @include mobile {
                font-size: 14px;
                line-height: 1.3;
            }

            &::before {
                width: 15px;
                height: 13px;
                display: block;
                content: '';
                background: transparent url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.97845L5.13666 12.1249L15 2.27132L13.584 0.874855L5.13666 9.31235L1.39643 5.57216L0 6.97845Z' fill='black'/%3E%3C/svg%3E%0A") center no-repeat;
                background-size: 15px 13px;
                position: absolute;
                top: 2px;
                left: 0;
            }
        }
    }

    .block-link {
        margin-top: 30px;
        color: $purple;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 28px;
    }

    .list-content-toggler-mobile {
        display: none;
        margin-top: 24px;
        font-size: 12px;
        line-height: 28px;
        background-color: transparent;
        color: $purple;
        border: 0;
        border-bottom: 1px solid currentColor;
        text-transform: uppercase;

        @include mobile {
            display: block;
        }

        &.is-opened {
            span {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    display: block;
                }
            }
        }

        span {
            pointer-events: none;

            &:nth-child(2) {
                display: none;
            }
        }
    }
}

.block-cta-banners {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px;

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 24px;
        }
    }

    .cta-banner {
        min-height: 280px;
        padding: 20px 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        @include mobile {
            padding: 16px;
            // min-height: 320px;
            min-height: 260px;
        }

        &.invite-banner {
            background: $purple url("data:image/svg+xml,%3Csvg width='351' height='247' viewBox='0 0 351 247' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M257 227C301.735 227 338 190.735 338 146H176C176 190.735 212.265 227 257 227Z' fill='%23B281FF'/%3E%3Cpath d='M262 80C303.974 80 338 72.8366 338 64H186C186 72.8366 220.026 80 262 80Z' fill='%23B281FF'/%3E%3Cpath d='M262 64C303.974 64 338 56.8366 338 48H186C186 56.8366 220.026 64 262 64Z' fill='%23B281FF'/%3E%3Cpath d='M262 48C303.974 48 338 40.8366 338 32H186C186 40.8366 220.026 48 262 48Z' fill='%23B281FF'/%3E%3Cpath d='M262 32C303.974 32 338 24.8366 338 16H186C186 24.8366 220.026 32 262 32Z' fill='%23B281FF'/%3E%3Cpath d='M262 16C303.974 16 338 8.83656 338 0H186C186 8.83656 220.026 16 262 16Z' fill='%23B281FF'/%3E%3Crect y='80' width='351' height='30' fill='%23B281FF'/%3E%3Crect y='227' width='351' height='29' fill='%23B281FF'/%3E%3Crect x='55' y='151' width='56.8724' height='76.0861' fill='%23B281FF'/%3E%3Ccircle cx='111.488' cy='186.737' r='14.9866' fill='%23B281FF'/%3E%3C/svg%3E%0A") right bottom no-repeat;
            background-size: 351px 247px;

            .banner-title {
                span {
                    color: $purple-light;
                }
            }
        }

        &.calculate-banner {
            background: $purple-light url("data:image/svg+xml,%3Csvg width='211' height='248' viewBox='0 0 211 248' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M105.5 203.7C163.766 203.7 211 156.466 211 98.2002H0C0 156.466 47.234 203.7 105.5 203.7Z' fill='%23F2EAFF'/%3E%3Cpath d='M105.5 309.2C163.766 309.2 211 261.966 211 203.7H0C0 261.966 47.234 309.2 105.5 309.2Z' fill='%23F2EAFF'/%3E%3Crect x='107.655' y='50.9163' width='8.16774' height='39.4774' fill='%23F2EAFF'/%3E%3Crect x='91.9999' y='74.7389' width='8.16774' height='39.4774' transform='rotate(-90 91.9999 74.7389)' fill='%23F2EAFF'/%3E%3Crect x='171.432' y='75.1677' width='8.16774' height='39.4774' transform='rotate(-90 171.432 75.1677)' fill='%23F2EAFF'/%3E%3Crect x='170.417' y='11.0695' width='8.16774' height='39.4774' transform='rotate(45 170.417 11.0695)' fill='%23F2EAFF'/%3E%3Crect x='142.503' y='16.845' width='8.16774' height='39.4774' transform='rotate(-45 142.503 16.845)' fill='%23F2EAFF'/%3E%3C/svg%3E%0A") calc(100% - 20px) bottom no-repeat;
            background-size: 211px 248px;

            @include mobile {
                background-position: right bottom;
            }

            .banner-title {
                span {
                    color: $purple;
                }
            }
        }
    }

    .banner-title {
        font-family: $title-font;
        font-weight: 500;
        font-size: 32px;
        line-height: 1.2;

        @include mobile {
            font-size: 24px;
            line-height: 1.3;
        }

        span {
            @include mobile {
                display: inline;
            }

            &:first-of-type {
                @include mobile {
                    margin-left: 4px;
                }
            }
        }

        br {
            @include mobile {
                display: none;
            }
        }
    }

    *:is(.banner-button, .showroom-button) {
        padding: 20px 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        border: 0;
        background: transparent;

        @include mobile {
            padding: 16px;
        }

        span {
            margin-top: 24px;
            height: 44px;
            border: 2px solid $black;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            background: none;
        }
    }
}

.block-our-clients {
    .clients-logos {
        margin-top: 16px;
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);

        // // grid-gap: 1px;
        // // background-color: $grey-border;
        // // border: 1px solid $grey-border;
        // @include mobile {
        //     grid-template-columns: repeat(3, 1fr);
        // }

        // @include maxw639 {
        //     grid-template-columns: 1fr 1fr;
        // }
    }

    .swiper {
        &-wrapper {
            display: grid;
            grid-template-columns: repeat(5, 1fr);

            @include not-mobile {
                transform: initial !important;
            }

            @include mobile {
                display: flex;
            }
        }

        &-slide {
            @include mobile {
                width: 178px;
            }
        }

        &-pagination {
            margin-top: 20px;
            position: relative;
            bottom: 0;
            justify-content: center;
            display: none;

            @include mobile {
                display: flex;
            }

            &-bullet {
                width: 6px;
                height: 6px;
                opacity: 0.2;
                background-color: $text;
                transition: $duration;
                margin: 0;
                border-radius: 0;

                &:not(:last-child) {
                    margin-right: 6px;
                }

                &-active {
                    opacity: 1;
                }
            }
        }
    }

    .logo-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 25px 15px;
        border: 2px solid $grey-border;
        margin: -1px;
        filter: grayscale(100%);
        transition: filter 0.5s;

        &:hover {
            filter: grayscale(0);
        }

        @include maxw639 {
            padding: 0;
            height: 100px;
        }

        img,
        svg {
            width: 123px;
            height: 50px;
        }
    }

    .more-logo {
        font-size: 12px;
        line-height: 28px;
        color: $purple;
        font-weight: 400;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.block-home-slider {
    padding-top: 20px;
    position: relative;
    z-index: 1;

    .container {
        display: flex;

        // @include touch {
        //     display: block;
        // }

        @include mobile {
            flex-wrap: wrap;
        }
    }

    .banner-subcategories {
        margin-top: 20px;
        order: 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        width: 100%;
    }

    .banner-subcategory {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-bg;
        height: 74px;

        span {
            font-size: 11px;
            margin-left: 5px;
            text-transform: uppercase;
        }
    }

    .banner-categories {
        width: 292px;
        flex-shrink: 0;
        border: 1px solid #e7e7e7;
        // padding: 20px;
        // padding: 20px 0;
        padding: 9px 0;
        position: relative;

        @include touch {
            width: calc((100vw - 20px) / 3);
        }

        @include mobile {
            margin-top: 20px;
            // display: grid;
            // grid-template-columns: repeat(3, minmax(0, 1fr));
            // grid-gap: 8px;
            padding: 0;
            border: 0;
            width: 100%;
            order: 3;
        }

        &.is-expanded {

            // .banner-category {
            .banner-category-wrapper {
                &:nth-child(n + 7) {
                    @include mobile {
                        display: flex;
                    }
                }
            }

            .banner-pdf-download {
                display: flex;
            }

            .categories-list-toggler {
                span {
                    &:nth-child(1) {
                        display: none;
                    }

                    &:nth-child(2) {
                        display: block;
                    }
                }

                svg {
                    transform: rotate(-180deg);
                }
            }
        }

        &-inner {
            width: 100%;

            @include mobile {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                grid-gap: 8px;
            }
        }
    }

    .categories-list-toggler {
        margin: 20px auto 0;
        color: $purple;
        font-size: 14px;
        line-height: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin-right: 10px;

            &:nth-child(2) {
                display: none;
            }
        }

        svg {
            transition: $duration;
        }
    }

    .banner-category-wrapper {
        &:nth-child(n + 7) {
            @include mobile {
                display: none;
            }
        }
    }

    .banner-category {
        display: flex;
        // align-items: flex-start;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: 1.4;
        // padding: 0 20px;
        padding: 4.5px 16px;
        transition: $duration;

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding: 12px;
            background-color: $grey-bg;
            text-align: center;
            font-size: 11px;
            line-height: 1.1;
            height: 100%;
            width: 100%;
        }

        &:hover {
            color: $purple;
            background-color: $purple-ultralight;

            +.banner-category-list {
                display: flex;

                @include mobile {
                    display: none;
                }
            }

            .banner-category-title {
                .catalog-link-arrow {
                    display: block;
                }

                &.is-arrowless {
                    .catalog-link-arrow {
                        display: none;
                    }
                }
            }
        }

        // &:not(:last-child) {
        //     margin-bottom: 8px;
        //     @include mobile {
        //         margin-bottom: 0;
        //     }
        //     &:has(+ .is-hidden-tablet) {
        //         margin-bottom: 0;
        //     }
        // }
        // &:nth-child(n+7) {
        //     @include mobile {
        //         display: none;
        //     }
        // }

        &.is-arrowless {
            .catalog-link-arrow {
                display: none;
            }
        }

        &-image-mobile {
            height: 50px;
            display: flex;
            align-items: flex-start;

            img {
                max-width: 100%;
                max-height: 50px;
                object-fit: contain;
            }
        }

        &-title {
            padding: 9px 16px;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: color $duration, background-color $duration;

            span {
                line-height: 20px;
            }

            &.has-text-link {
                color: $purple !important;
                background-color: $purple-ultralight;
            }

            .catalog-link-arrow {
                color: $purple;
            }
        }

        &-list {
            display: none;
            align-items: flex-start;
            position: absolute;
            left: 100%;
            top: -0.5px;
            bottom: -0.5px;
            width: calc(100vw - 100% - 18px);
            max-width: 730px;
            background-color: #fff;
            // border-left: 2px solid $grey-border;
            border: 1px solid #e7e7e7;
            border-left-width: 2px;
            overflow-y: auto;
            z-index: 2;

            &:hover {
                display: flex;
            }
        }

        &-links {
            padding: 12px 0;
            // padding: 3px 0;
            align-self: stretch;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: 285px;

            @include touch {
                max-width: calc((100vw - 20px) / 3);
            }

            &.is-columns {
                columns: 2;
                column-fill: auto;
                display: block;
            }
        }

        &-link {
            font-weight: 400;
            line-height: 1.57;
            display: block;
            white-space: break-spaces;
            break-inside: avoid;
            padding-left: 16px;

            &:not(:last-child) {
                margin-bottom: 9px;
            }

            small {
                font-size: inherit;
                color: $grey-text;
                margin-left: 3px;
            }
        }

        span {
            @include mobile {
                margin-top: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 11px;
            flex-shrink: 0;

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
        }

        svg {
            color: $purple;

            // flex-shrink: 0;
            // &.icon {
            //     width: 24px;
            //     height: 24px;
            //     margin-right: 11px;
            // }
            &.arrow {
                align-self: center;
                width: 5px;
                height: 8px;
                margin-left: auto;

                @include mobile {
                    display: none;
                }
            }
        }
    }

    .nested.dropdown {
        @include not-mobile {
            position: static;
            width: 100%;
            max-width: 285px;

            @include touch {
                max-width: calc((100vw - 20px) / 3);
            }
        }

        &:hover {
            .dropdown-trigger {
                .navbar-item {
                    @include not-mobile {
                        background-color: $purple-ultralight;
                        color: $purple;
                    }
                }
            }
        }

        // &:not(:last-child) {
        //     border-bottom: 2px solid $grey-border;
        // }

        .dropdown-trigger {
            @include not-mobile {
                position: relative;
            }

            &::before {
                @include not-mobile {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 8' fill='none' id='link-arrow'%3E%3Cpath d='M5.34359e-07 7.05666L3.09171 4L7.14338e-08 0.943344L0.95413 -7.18185e-08L5 4L0.95413 8L5.34359e-07 7.05666Z' fill='%239E61FF'%3E%3C/path%3E%3C/svg%3E");
                    position: absolute;
                    width: 5px;
                    height: 8px;
                    top: calc(50% - 4px);
                    right: 16px;
                    z-index: 2;
                }
            }

            .navbar-item {
                line-height: 1.4;
                padding: 16px 0;
                display: block;
                color: inherit;

                @include not-mobile {
                    padding: 4px 16px;
                    line-height: 28px;
                    font-weight: 400;
                    color: inherit;
                }

                &.head-link {
                    color: $purple;
                    text-transform: uppercase;
                }
            }
        }

        .dropdown-menu {
            @include not-mobile {
                top: 0;
                bottom: 0;
                border-left: 2px solid $grey-border;
                width: 100%;
                padding: 0;
            }
        }

        .dropdown-content {
            @include not-mobile {
                padding: 12px 0;
                box-shadow: initial;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .navbar-item {
                @include not-mobile {
                    padding: 0;
                    font-weight: 400;
                    line-height: 1.57;
                    display: block;
                    white-space: break-spaces;
                    padding-left: 16px;
                    color: inherit;
                }

                &:not(:last-child) {
                    @include not-mobile {
                        margin-bottom: 9px;
                    }
                }

                &:hover {
                    @include not-mobile {
                        color: $purple;
                        background-color: initial;
                    }
                }
            }
        }
    }

    .banner-pdf-download {
        @include mobile {
            // display: flex;
            display: none;
            flex-direction: column;
            align-items: center;
            padding: 12px;
            background-color: $grey-bg;
            text-align: center;
            font-size: 11px;
            line-height: 1.1;
            color: $purple;
        }

        span {
            @include mobile {
                margin-top: 15px;
                text-transform: uppercase;
            }
        }
    }

    // .is-52 {
    //     padding-top: 52%;

    //     @include maxw1200 {
    //         padding-bottom: 0;
    //         height: 100%;
    //     }

    //     @include touch {
    //         padding-top: 52%;
    //         height: initial;
    //     }

    //     picture {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         height: 100%;
    //         width: 100%;
    //     }
    // }

    // .home-banner-slider {
    //     width: 66%;
    //     overflow: hidden;

    //     picture {
    //         overflow: hidden;

    //         img {
    //             transform: scale(1.05);
    //             transition: transform 0.5s;
    //             transition-delay: 0.25s;

    //             @include mobile {
    //                 width: 100%;
    //                 height: auto;
    //             }
    //         }

    //         &:before {
    //             background-color: #f3ebff;
    //         }

    //         &:after {
    //             border: 2px solid $purple;
    //             border-right-color: transparent;
    //             border-top-color: transparent;
    //         }
    //     }

    //     .swiper-slide-active {
    //         img {
    //             transform: scale(1) !important;
    //         }
    //     }

    //     @include touch {
    //         width: 100%;
    //     }

    //     .swiper-slide {
    //         max-width: 100%;

    //         @include mobile {
    //             width: 100%;
    //         }
    //     }

    //     img {
    //         display: block;
    //         object-fit: cover;
    //     }

    //     picture {
    //         display: flex;
    //     }
    // }

    // .home-text-slider {
    //     width: 34%;
    //     background-color: $purple-light;

    //     @include touch {
    //         width: 100%;
    //     }

    //     .swiper-slide {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: flex-start;
    //         padding: 42px 30px 84px;
    //         max-width: 100%;

    //         @include touch {
    //             padding: 42px 30px;
    //         }

    //         @include mobile {
    //             padding: 16px;
    //         }
    //     }

    //     .swiper-button {
    //         &-prev,
    //         &-next {
    //             background-color: transparent;
    //             box-shadow: initial;
    //             top: initial;
    //             bottom: 20px;

    //             @include touch {
    //                 bottom: 42px;
    //             }

    //             @include mobile {
    //                 bottom: 20px;
    //             }
    //         }

    //         &-prev {
    //             left: 5px;

    //             @include touch {
    //                 left: initial;
    //                 right: 74px;
    //             }

    //             @include mobile {
    //                 right: 60px;
    //             }
    //         }

    //         &-next {
    //             right: initial;
    //             left: 54px;

    //             @include touch {
    //                 left: initial;
    //                 right: 16px;
    //             }
    //         }
    //     }
    // }

    // .slide-title {
    //     font-family: $title-font;
    //     font-size: 40px;
    //     line-height: 1.2;
    //     font-weight: 500;

    //     // идентично .block-title
    //     @include maxw1200 {
    //         font-size: 36px;
    //     }

    //     @include touch {
    //         font-size: 32px;
    //     }

    //     @include mobile {
    //         font-size: 24px;
    //         line-height: 1.3;
    //     }
    // }

    // .slide-descr {
    //     margin-top: 12px;
    //     font-size: 15px;
    //     line-height: 1.2;

    //     @include mobile {
    //         margin-top: 8px;
    //         font-size: 14px;
    //         line-height: 1.4;
    //     }

    //     br {
    //         @include touch {
    //             display: none;
    //         }
    //     }
    // }

    // .slide-link {
    //     margin-top: 27px;
    //     height: 44px;
    //     display: flex;
    //     align-items: center;
    //     border: 2px solid $black;
    //     padding: 2px 20px 0px 20px;
    //     transition: background-color 0.1s, border-color 0.1s, color 0.1s;

    //     @include mobile {
    //         margin-top: 30px;
    //     }

    //     &:hover {
    //         background-color: $purple;
    //         border-color: $purple;
    //         color: #fff;
    //     }
    // }

    .slide {
        display: block;
        position: relative;
        height: 100%;
        min-height: 360px;
        max-height: 530px;

        &-image {
            position: relative;
            z-index: 1;
            height: 100%;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                // right: 230px;
                // right: 0;
                right: 138px;
                bottom: 0;
                // background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 76.63%);
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 76.63%);
                z-index: 2;
            }

            figure {
                z-index: 1;
                height: 100%;
            }

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        &-text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 40px 46px;
            color: #fff;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            @include mobile {
                padding: 12px 20px 16px;
                // display: flex;
                // flex-direction: column;
            }
        }

        &-title {
            font-family: $title-font;
            font-size: 40px;
            font-weight: 500;
            // line-height: 1.2;
            line-height: 1.1;
            max-width: 420px;

            @include touch {
                font-size: 32px;
            }

            @include mobile {
                font-size: 24px;
            }

            +.slide-btns {
                margin-top: auto;
            }
        }

        &-descr {
            // margin-top: 12px;
            margin-top: 25px;
            font-size: 15px;
            font-weight: 500;
            // line-height: 1.2;
            line-height: 1.64;
            max-width: 640px;
            margin-bottom: auto;

            @include mobile {
                margin-top: 10px;
                font-size: 14px;
            }
        }

        &-btns {
            margin-top: 28px;
            display: flex;

            @include mobile {
                margin-top: auto;
            }
        }

        &-link {
            height: 44px;
            min-width: 140px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 32px;
            background-color: #fff;
            color: $black;
        }
    }

    .swiper {
        &-container {
            max-width: 100%;
            width: calc(100% - 300px);
            overflow: hidden;
            margin-right: 0;

            @include touch {
                width: calc((100vw - 20px) / 3 * 2 - 8px);
            }

            @include mobile {
                width: 100%;
                order: 1;
            }
        }

        &-controls {
            display: flex;
            position: absolute;
            // left: 30px;
            right: 30px;
            bottom: 40px;

            @include mobile {
                // left: initial;
                right: 16px;
                bottom: 16px;
            }
        }

        &-button {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            border: 2px solid rgba(#fff, 0.1);
            background-size: 11px 18px;
            background-color: transparent;

            &::before,
            &::after {
                content: none;
            }

            &:not(:last-child) {
                margin-right: 12px;
            }

            &-prev {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 18' %3E%3Cpath d='M10.4209 2.93604L3.97713 9.37981L10.4209 15.8236L8.43229 17.8123L-0.00015508 9.37981L8.43229 0.947365L10.4209 2.93604Z' fill='white'/%3E%3C/svg%3E%0A");
            }

            &-next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 18' %3E%3Cpath d='M0.158691 2.93579L6.60246 9.37957L0.158691 15.8233L2.1473 17.812L10.5797 9.37957L2.1473 0.947121L0.158691 2.93579Z' fill='white'/%3E%3C/svg%3E%0A");
            }

            &-disabled {
                display: block !important;
                opacity: 0.5;
                pointer-events: none;
                border-color: transparent;
            }
        }
    }
}

.news-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;

    &-discount {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 78px;
        height: 78px;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $purple;
        font-family: $title-font;
        font-size: 26px;
        line-height: 1.44;
        border-radius: 50%;

        @include mobile {
            top: 8px;
            left: 8px;
            width: 52px;
            height: 52px;
            font-size: 16px;
        }
    }

    &-photo {
        // flex-grow: 1;
        position: relative;
        padding-bottom: 60%;

        picture {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            object-fit: cover;
        }
    }

    &-info {
        padding: 15px;
        background-color: $grey-bg;
        display: flex;
        flex-wrap: wrap;
        min-height: 160px;
        flex-grow: 1;
        align-content: flex-start;

        @include mobile {
            // min-height: 140px;
            min-height: initial;
        }
    }

    &-date {
        // margin-right: 16px;
        // position: relative;
        line-height: 1;
        color: $grey-text;
        // &::after {
        //     display: block;
        //     content: '';
        //     width: 3px;
        //     height: 3px;
        //     background-color: currentColor;
        //     position: absolute;
        //     top: 5px;
        //     right: -10px;
        //     pointer-events: none;
        // }
    }

    &-author {
        line-height: 1;
        color: $grey-text;
        margin-left: 16px;
        position: relative;

        &::before {
            display: block;
            content: '';
            width: 3px;
            height: 3px;
            background-color: currentColor;
            position: absolute;
            top: 5px;
            left: -10px;
            pointer-events: none;
        }
    }

    &-title {
        margin-top: 16px;
        width: 100%;
        font-family: $title-font;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;
        color: #000;

        @include mobile {
            font-size: 20px;
            line-height: 1.3;
        }
    }

    &-descr {
        margin-top: 16px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.67;
        color: $grey-text;

        @include mobile {
            font-size: 14px;
        }

        @include maxw639 {
            display: none;
        }
    }

    &-time-rest {
        margin-top: 8px;
        padding: 0 6px;
        color: #fff;
        background-color: $purple-medium;
    }
}

.block-products-for-your-business {
    .swiper-slide {
        @include mobile {
            width: 240px;
        }
    }

    .banner-item {
        display: flex;
        position: relative;
        overflow: hidden;

        picture {
            display: flex;
        }

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        img {
            transition: transform 0.5s;
        }
    }

    // margin-right: 20px;
    .product-category-link {
        position: absolute;
        line-height: 1.4;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 20px;
        background-color: #fff;
        margin: auto;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        max-width: calc(100% - 30px);
        min-height: 44px;
        text-align: center;
        z-index: 100;

        @include mobile {
            min-height: 36px;
        }
    }
}

.block-news-list {
    // padding-top: 40px;
    padding-top: 50px;
    padding-bottom: 80px;

    @include mobile {
        padding-bottom: 60px;
    }

    .news-list {
        margin-top: 18px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px 20px;

        @include touch {
            grid-template-columns: 1fr 1fr;
        }

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 16px;
        }
    }

    .pagination-wrapper {
        margin-top: 40px;
        padding-left: 0;

        @include mobile {
            margin-top: 24px;
        }
    }
}

.block-news-articles {
    .container {
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .block-head {

        // width: 100%;
        &-link {
            // display: none;
            @include mobile {
                display: none;
            }
        }
    }

    .block-title {

        // margin-right: auto;
        span {
            color: $purple;
            border-bottom: 3px dotted currentColor;
            cursor: pointer;

            &.is-active {
                color: inherit;
                border-bottom: 0;
                pointer-events: none;
            }
        }
    }

    .swiper {
        &-container {
            display: none;
            width: 100%;

            @include mobile {
                display: block;
            }

            &.swiper-container-initialized {
                display: block;
            }
        }

        // &-wrapper {
        //     @include mobile {
        //         display: grid;
        //         grid-template-columns: 1fr 1fr;
        //         grid-gap: 16px;
        //     }

        //     @include maxw639 {
        //         grid-template-columns: 1fr;
        //     }
        // }
        &-slide {
            height: auto;

            @include mobile {
                width: 324px;
            }

            &:not(:last-child) {
                @include mobile {
                    margin-right: 20px;
                }
            }
        }

        // &-button {
        //     &-prev,
        //     &-next {
        //         @include mobile {
        //             display: none;
        //         }
        //     }
        // }
        &-pagination {
            margin-top: 20px;
            position: relative;
            bottom: 0;
            justify-content: center;
            display: none;

            @include mobile {
                display: flex;
            }

            &-bullet {
                width: 6px;
                height: 6px;
                opacity: 0.2;
                background-color: $text;
                transition: $duration;
                margin: 0;
                border-radius: 0;

                &:not(:last-child) {
                    margin-right: 6px;
                }

                &-active {
                    opacity: 1;
                }
            }
        }
    }

    .block-footer-links-mobile {
        display: none;
        margin-top: 24px;
        justify-content: center;
        @include mobile {
            display: flex;
        }
    }
    .block-footer-link-mobile {
        // display: none;
        // margin-top: 24px;
        font-size: 12px;
        line-height: 28px;
        text-transform: uppercase;
        color: #9e61ff;
        flex-shrink: 0;

        // @include mobile {
        //     display: block;
        // }
    }
}

.block-actions-list {
    // padding-top: 40px;
    padding-bottom: 80px;

    .actions-list {
        margin-top: 18px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px 20px;

        @include mobile {
            margin-top: 24px;
            grid-template-columns: 1fr;
            grid-gap: 16px;
        }
    }

    .news-card {
        &-date {
            &::after {
                content: initial;
                margin-right: initial;
            }
        }
    }

    // .pagination-wrapper {
    //     margin-top: 40px;
    //     padding-left: 0;
    // }
}

.block-article {
    // padding-top: 40px;
    padding-top: 30px;
    padding-bottom: 110px;

    .article {
        max-width: 820px;
        margin: 0 auto;

        a {
            color: $purple;
            border-bottom: 1px solid $purple;
        }

        .download-wrapper {
            border: 1px solid #e3e3e3;
            padding: 1rem;

            .description {
                line-height: 1.67;
                font-weight: 400;
                margin-top: 6px;
            }

            .download-item {
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
            }

            .size {
                font-weight: normal;
                margin-left: 10px;
                color: #888;
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        .image-wrapper {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &:not(:first-child) {
                margin-top: 30px;
            }

            .description {
                line-height: 1.67;
                font-weight: 400;
                margin-top: 6px;
                max-width: 500px;
            }
        }

        &-date {
            font-size: 14px;
            line-height: 1;
            color: rgba($black, 0.3);
            font-weight: 400;
        }

        &-title {
            margin-top: 16px;
            font-family: $title-font;
            font-size: 40px;
            font-weight: 500;
            line-height: 1.2;

            +.article-slider {
                margin-top: 13px;
            }
        }

        &-banner {
            margin-top: 26px;
            display: block;
            width: 100%;
        }

        &-slider {
            margin-top: 18px;
            overflow: hidden;

            &:not(:last-child) {
                margin-bottom: 18px;
            }

            .swiper-button {

                &-prev,
                &-next {
                    width: 30px;
                    height: 30px;
                    background-size: 8px 12px;
                }

                &-prev {
                    left: 20px;
                }

                &-next {
                    right: 20px;
                }
            }
        }

        .images-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            &:not(:last-child) {
                margin-bottom: 18px;
            }

            .image-wrapper {
                padding-bottom: 68.25%;
                position: relative;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        p {
            line-height: 1.67;
            font-weight: 400;

            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }
        }

        h2 {
            font-family: $title-font;
            font-size: 24px;
            line-height: 1.33;
            font-weight: 500;

            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }
        }

        h3 {
            font-size: 18px;
            line-height: 1.5;
            font-family: $title-font;
            font-weight: 500;

            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }
        }

        .color-list {
            width: 100%;
            padding: 20px 60px 20px 20px;
            background-color: $purple-ultralight;

            &:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }

            ol {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                padding-left: 20px;

                &:first-of-type:not(:first-child) {
                    margin-top: 13px;
                }
            }

            li {
                &:not(:last-child) {
                    margin-bottom: 10px;

                    &::after {
                        content: initial;
                    }
                }

                &:last-child {
                    &::after {
                        content: initial;
                    }
                }
            }
        }

        ul,
        ol {
            padding-left: 16px;
            font-weight: 400;
            line-height: 1.67;

            +* {
                margin-top: 18px;
            }
        }

        ul {
            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            li {
                &::before {
                    display: block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: currentColor;
                    position: absolute;
                    left: -12px;
                    top: 10px;
                }
            }
        }

        ol {
            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }
        }

        li {
            position: relative;

            // &:not(:last-child) {
            //     &::after {
            //         content: ';';
            //     }
            // }

            // &:last-child {
            //     &::after {
            //         content: '.';
            //     }
            // }
        }

        >img {
            width: 100%;
            display: block;

            +* {
                margin-top: 18px;
            }
        }
    }

    .siblings-articles-controls {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            display: flex;
            align-items: center;
            max-width: calc(50% - 12px);
            border: 0;

            @media (hover: hover) {
                &:hover {
                    span {
                        &::after {
                            transform: scaleX(0);
                        }
                    }

                    &:nth-child(1) {
                        svg {
                            transform: translateX(-8px);
                        }
                    }

                    &:nth-child(2) {
                        svg {
                            transform: translateX(8px);
                        }
                    }
                }
            }
        }

        svg {
            transition: $duration;
            flex-shrink: 0;
        }

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;

            &::after {
                content: '';
                border-bottom: 1px solid currentColor;
                transform: scaleX(1);
                position: absolute;
                bottom: 0;
                left: 0;
                transform-origin: right center;
                width: 100%;
                transition: $duration;
            }

            &:nth-child(1) {
                margin-right: 8px;
            }

            &:nth-child(2) {
                margin-left: 8px;
            }
        }
    }
}

.block-not-found {
    .not-found {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding: 110px 30px;
        background-color: $purple-light;
        min-height: 510px;

        @include mobile {
            margin-top: 16px;
            min-height: 404px;
            padding: 60px 10px 16px;
            flex-direction: column;
        }

        &-figure {
            width: 47%;
            max-width: 646px;

            @include mobile {
                width: 100%;
                max-width: 100%;
            }
        }

        &-text {
            width: 44%;
            max-width: 590px;
            align-self: center;
            font-family: $title-font;
            font-weight: 500;
            padding-right: 50px;

            @include mobile {
                margin-top: 70px;
                width: 100%;
                max-width: 100%;
                padding-right: 0;
            }
        }

        &-title {
            font-size: 40px;
            line-height: 1.2;

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }
        }

        &-descr {
            margin-top: 20px;
            font-size: 24px;
            line-height: 1.33;
            color: $grey-text;

            @include mobile {
                margin-top: 8px;
                font-size: 14px;
                line-height: 1.4;
            }

            a {
                color: $purple;
            }
        }
    }
}

.block-action-discount {
    .action-discount {
        display: grid;
        // grid-template-columns: 1fr 1fr;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 64px;
        position: relative;

        @include maxw1280 {
            grid-gap: 32px;
        }

        @include maxw1200 {
            grid-gap: 24px;
        }

        @include touch {
            grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
        }

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

        &-discount {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 78px;
            height: 78px;
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: $purple;
            font-family: $title-font;
            font-size: 26px;
            line-height: 1.44;
            border-radius: 50%;

            @include mobile {
                top: 16px;
                left: 16px;
                width: 60px;
                height: 60px;
                font-size: 20px;
            }
        }

        &-banner {
            display: flex;
            align-self: start;

            picture {
                display: block;
                width: 100%;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-period {
            font-size: 14px;
            line-height: 1;
            color: $grey-text;
            font-weight: 400;
        }

        &-title {
            margin-top: 16px;
            font-family: $title-font;
            font-size: 32px;
            font-weight: 500;
            line-height: 1.2;
            color: #000;

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }
        }

        &-countdown {
            margin-top: 16px;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            background: rgba($orange, 0.1);
            border: 1.5px solid #ededed;
            width: 100%;

            // max-width: 580px;
            @include touch {
                flex-direction: column;
                align-items: flex-start;
            }

            @include mobile {
                margin-top: 10px;
                padding: 10px;
            }

            &-title {
                line-height: 1.47;
                margin-right: 10%;

                @include maxw1280 {
                    margin-right: 32px;
                }

                @include mobile {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
            }

            &-time {
                font-family: $title-font;
                font-size: 32px;
                font-weight: 500;
                line-height: 1.2;
                white-space: nowrap;

                @include maxw1280 {
                    font-size: 28px;
                }

                @include touch {
                    font-size: 24px;
                }
            }
        }

        &-descr {
            margin-top: 16px;

            .descr-inner {
                max-height: 140px;
                overflow: hidden;
                position: relative;

                @include mobile {
                    max-height: 240px;
                }

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 80px;
                    background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0.49) 100%);
                    z-index: 2;
                }

                &.open {
                    max-height: 100%;

                    &::after {
                        content: initial;
                    }
                }
            }

            p {
                color: #000;
                font-weight: 400;
                line-height: 1.67;

                &:not(:last-child) {
                    margin-bottom: 25px;

                    @include mobile {
                        margin-bottom: 15px;
                    }
                }
            }

            &-toggler {
                margin-top: 10px;
                color: #000;
                font-size: 16px;
                line-height: 1.75;
                display: flex;
                align-items: center;
                border: 0;
                background-color: transparent;

                span {
                    margin-right: 4px;
                }

                .icon {
                    width: 8px;
                    height: 5px;
                }
            }
        }
    }

    +.shop-subcategory-content {
        .column.is-hidden-mobile {
            &:empty {
                +.catalog-products {
                    margin-left: auto;
                    width: 100%;
                }
            }
        }

        .notification {
            @include mobile {
                margin-top: 16px;
            }
        }
    }
}

// info pages start
.block-info {
    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include mobile {
            padding: 0;
        }
    }

    .block-title {
        margin-bottom: 10px;
        width: 100%;

        @include mobile {
            display: none;
        }

        &:first-child {
            margin-top: 50px;
        }
    }

    .info-sidebar {
        padding: 20px;
        width: 330px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 20px;

        background-color: #fff;

        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;
        @include touch {
            width: 100%;
            margin-right: 0;
        }

        @include mobile {
            padding: 0;
            position: relative;
            z-index: 2;
            background-color: transparent;
        }

        &-toggler-mobile {
            font-size: 14px;
            line-height: 1.4;
            color: $purple;
            padding: 12px 20px;
            border: 0;
            border-radius: 0;
            display: none;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            border-bottom: 2px solid #fff;

            @include mobile {
                display: flex;
            }

            svg {
                width: 13px;
                height: 8px;
            }
        }

        &-inner {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include mobile {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                display: none;
                min-height: 520px;
                background-color: $grey-bg;
                padding: 12px 20px;
                overflow: auto;
            }

            &.is-opened {
                @include mobile {
                    display: flex;
                }
            }
        }

        &-categories {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include touch {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 8px 16px;
            }

            @include mobile {
                grid-template-columns: 1fr;
                grid-gap: 12px;
                justify-content: start;
            }

            a {
                line-height: 28px;

                &.active {
                    color: $purple;
                }

                &:not(:last-child) {
                    margin-bottom: 12px;

                    @include touch {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-contacts {
            margin-top: 12px;
            border-top: 2px solid $grey-border;
            padding-top: 14px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 12px;
            line-height: 22px;

            @include touch {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 8px 16px;
            }

            @include mobile {
                padding-top: 12px;
                border-color: #fff;
                flex-direction: column;
                gap: initial;
                width: 100%;
            }

            &-title {
                @include touch {
                    width: 100%;
                }

                @include mobile {
                    max-width: 290px;
                }

                a {
                    color: $purple;
                }
            }
        }
    }

    .info-content {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 20px;
        background-color: #fff;
        max-width: calc(100% - 350px);

        @include touch {
            max-width: 100%;
            margin-top: 20px;
        }

        @include mobile {
            margin-top: 0;
            padding: 24px 10px;
        }

        &.narrow {
            max-width: 800px;
            max-width: min(calc(100% - 350px), 800px);
        }
    }

    // .about-group {
    .info-group {
        max-width: 780px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        // &-title {
        h2 {
            font-family: $title-font;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            max-width: 660px;
            width: 100%;

            +* {
                margin-top: 18px;
            }
        }

        &-slider {
            overflow: hidden;
            width: 100%;

            &:not(:first-child) {
                margin-top: 18px;
            }

            img {
                display: block;
            }

            .swiper-button {

                &-prev,
                &-next {
                    width: 30px;
                    height: 30px;
                    background-size: 8px 12px;
                }

                &-prev {
                    left: 20px;
                }

                &-next {
                    right: 20px;
                }
            }
        }

        .color-list {
            width: 100%;
            padding: 20px 60px 20px 20px;
            background-color: $purple-ultralight;

            @include mobile {
                padding: 12px 8px;
                font-weight: 500;
            }

            &:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }

            ul:not(.block-list),
            ol:not(.block-list) {
                font-weight: 500;
            }

            ol {
                font-size: 14px;
                line-height: 22px;
                // font-weight: 500;
                padding-left: 20px;

                &:first-of-type:not(:first-child) {
                    margin-top: 13px;
                }
            }

            li {
                &:not(:last-child) {
                    margin-bottom: 10px;

                    &::after {
                        content: initial;
                    }
                }

                &:last-child {
                    &::after {
                        content: initial;
                    }
                }
            }
        }

        p {
            font-size: 15px;
            line-height: 1.67;
            font-weight: 400;
            width: 100%;

            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }
        }

        h3 {
            font-size: 18px;
            line-height: 1.5;
            font-family: $title-font;
            font-weight: 500;

            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            +* {
                margin-top: 18px;
            }
        }

        ul:not(.block-list),
        ol:not(.block-list) {
            padding-left: 16px;
            font-weight: 400;
            line-height: 1.67;

            li {
                position: relative;

                // &:not(:last-child) {
                //     &::after {
                //         content: ';';
                //     }
                // }

                // &:last-child {
                //     &::after {
                //         content: '.';
                //     }
                // }
            }

            +* {
                margin-top: 18px;
            }
        }

        ul:not(.block-list) {
            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }

            li {
                &::before {
                    display: block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: currentColor;
                    position: absolute;
                    left: -12px;
                    top: 10px;
                }
            }
        }

        ol:not(.block-list) {
            &:first-of-type:not(:first-child) {
                margin-top: 18px;
            }
        }

        a {
            color: $purple;
            font-size: 15px;
            line-height: 1.67;

            +a {
                margin-top: 18px;
            }
        }

        strong {
            font-weight: 500;
        }

        .warning {
            color: $warning;
        }
    }

    .contacts-group {
        &:not(:last-child) {
            margin-bottom: 80px;
        }

        @include mobile {
            width: 100%;
        }

        &-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        // &-title {
        h2 {
            font-family: $title-font;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            max-width: initial;
            // + * {
            //     margin-top: 18px;
            // }
        }

        .appointment-form {
            margin-top: 0;

            .modal {
                &-close {
                    svg {
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }

        .showroom-button {
            display: flex;
            align-items: center;
            padding: 0 15px;
            background-color: $purple;
            color: #fff;
            height: 44px;
            white-space: nowrap;
            border: 0;
        }

        &-body {
            margin-top: 30px;
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            grid-gap: 24px;
            align-items: start;

            @include mobile {
                grid-template-columns: minmax(0, 1fr);
            }
        }

        &-communication {
            // margin-top: 30px;
            display: grid;
            // grid-template-columns: repeat(4, 1fr);
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
            // grid-gap: 20px 30px;

            font-size: 14px;
            line-height: 22px;

            // @include touch {
            //     grid-template-columns: 1fr 1fr;
            // }

            @include mobile {
                grid-template-columns: 1fr;
                grid-gap: 20px;
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            h3 {
                font-family: $title-font;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 10px;

                +* {
                    margin-top: 0;
                }
            }

            a {
                color: inherit;

                &:hover {
                    color: $purple;
                }
            }

            .map-link {
                margin-top: 10px;
                font-size: 14px;
                border: 0;
                background-color: transparent;
                color: $purple;
            }

            .workTime {
                color: $grey-text;
            }

            .modal {
                &-content {
                    background-color: #fff;
                    padding: 15px;
                    width: 100%;
                    max-width: 600px;

                    @include mobile {
                        width: calc(100% - 40px);
                    }

                    >div {
                        &:nth-child(2) {
                            font-size: 18px;
                            line-height: 1.5;
                            font-weight: 500;
                        }

                        &:nth-child(3) {
                            margin-top: 6px;
                            font-size: 14px;
                            line-height: 1.57;
                        }

                        &:nth-child(4) {
                            margin-top: 8px;
                            width: 100% !important;
                            height: 390px !important;

                            @include mobile {
                                height: 270px !important;
                            }
                        }
                    }
                }

                &-close {
                    @include mobile {
                        right: 12px;
                    }

                    svg {
                        width: 13px;
                        height: 13px;
                    }
                }
            }

            .gm-fullscreen-control,
            .gm-svpc,
            .gmnoscreen,
            .gmnoprint {
                display: none;
            }
        }

        &-map {
            &:not(:first-child) {
                margin-top: 40px;
                min-height: 340px;

                @include mobile {
                    margin-top: 20px;
                }
            }

            img {
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }

        &-places {
            overflow: hidden;
            width: 100%;
            //     margin-top: 40px;
            //     display: grid;
            //     grid-template-columns: 1fr 1fr;
            //     grid-gap: 20px;

            //     @include mobile {
            //         margin-top: 20px;
            //         grid-template-columns: 1fr;
            //     }

            .swiper-button {
                top: calc(50% - 21px);
                width: 30px;
                height: 30px;
                background-size: 8px 12px;

                &-prev {
                    left: 10px;
                }

                &-next {
                    right: 10px;
                }
            }
        }

        &-place {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                display: block;
                width: 100%;
            }

            span {
                margin-top: 16px;
                font-size: 18px;
                line-height: 1.5;

                @include mobile {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 1.4;
                    text-align: center;
                }
            }
        }

        // &-showroom {
        //     margin-top: 40px;
        //     padding: 30px;
        //     background-color: $purple;
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        //     grid-gap: 30px;

        //     @include mobile {
        //         margin-top: 36px;
        //         padding: 20px 10px;
        //         grid-template-columns: 1fr;
        //     }
        // }

        // .showroom-form {
        //     &-wrapper {
        //         padding: 20px;
        //         background-color: #fff;

        //         @include mobile {
        //             padding: 14px 10px 10px;
        //         }
        //     }

        //     &-title {
        //         font-family: $title-font;
        //         font-weight: 500;
        //         font-size: 24px;
        //         line-height: 1.33;
        //         color: #000;

        //         @include mobile {
        //             font-size: 18px;
        //             line-height: 1.5;
        //         }
        //     }

        //     margin-top: 16px;
        //     display: block;

        //     @include mobile {
        //         margin-top: 10px;
        //     }

        //     .field {
        //         width: 100%;

        //         &:not(:last-child) {
        //             margin-bottom: 8px;
        //         }
        //     }

        //     .input {
        //         height: 52px;
        //         width: 100%;
        //         display: flex;
        //         padding: 15px 15px 0;
        //         background-color: $grey-bg;
        //         font-size: 14px;
        //         line-height: 17px;
        //         font-weight: 400;
        //         border: 0;
        //         border-radius: 0;
        //         box-shadow: initial;
        //         align-items: flex-start;

        //         &:not(:placeholder-shown),
        //         &:focus {
        //             +.label {
        //                 font-size: 12px;
        //                 top: 8px;
        //             }
        //         }
        //     }

        //     .label {
        //         position: absolute;
        //         right: 15px;
        //         font-size: 14px;
        //         line-height: 1.25;
        //         font-weight: 400;
        //         color: $grey-text;
        //         top: 18px;
        //         left: 15px;
        //         transition: font-size $duration, top $duration;
        //     }

        //     .button[type="submit"] {
        //         width: 100%;
        //         background-color: $purple;
        //         height: 44px;
        //         border: 0;
        //         margin-top: 16px;
        //         border-radius: 0;
        //     }
        // }

        // .figure-wrapper {
        //     display: flex;
        //     align-items: center;
        //     justify-content: flex-end;

        //     @include mobile {
        //         display: none;
        //     }

        //     svg {
        //         max-width: 435px;
        //         width: 100%;
        //     }
        // }

        a {
            +a {
                margin-top: 0;
            }
        }
    }

    .faq-group {
        // max-width: 780px;

        .faq-category {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &.open {
                .faq-category-title {
                    &::after {
                        content: '-';
                    }
                }

                .faq-items {
                    display: block;
                }
            }

            &-title {
                font-family: $title-font;
                font-size: 24px;
                font-weight: 500;
                line-height: 1.33;
                color: #000;
                display: flex;
                cursor: pointer;

                &::after {
                    display: block;
                    content: '+';
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 1.5;
                    margin-left: 8px;
                }
            }
        }

        .faq-items {
            display: none;
            width: 100%;

            &-inner {
                padding: 8px 0 2px;
            }
        }

        .faq-item {
            padding: 0 20px;
            border: 1.5px solid $grey-border;
            cursor: pointer;
            position: relative;

            @include mobile {
                padding: 0 33px 0 15px;
            }

            &::before {
                display: block;
                content: '';
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943344 2.25903e-07L4 3.09171L7.05666 3.0199e-08L8 0.95413L4 5L3.01121e-08 0.95413L0.943344 2.25903e-07Z' fill='%23222222'/%3E%3C/svg%3E%0A");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 8px;
                height: 5px;
                position: absolute;
                top: 27px;
                right: 21px;
                pointer-events: none;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &.open {
                background-color: #fafafa;

                &::before {
                    transform: rotate(-180deg);
                }

                .faq-item-text {
                    display: block;
                }
            }

            &-title {
                font-family: $title-font;
                font-size: 18px;
                line-height: 1.5;
                padding: 15px 0px;
                padding-right: 50px;

                @include mobile {
                    padding-right: 0;
                }
            }

            &-text {
                display: none;
                font-weight: 400;
                line-height: 1.67;
                padding: 0 0 15px;
            }
        }
    }

    .feedback-group {
        width: 100%;

        // &-title {
        h2 {
            font-family: $title-font;
            font-weight: 500;
            font-size: 24px;
            line-height: 1.33;
            color: #000;

            +* {
                margin-top: 18px;
            }
        }

        &-form {
            margin-top: 16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .field {
            // width: calc(50% - 4px);
            width: 100%;

            @include mobile {
                // width: 100%;
                flex-direction: column;
            }

            &.textarea-field {
                width: 100%;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .schema-col {
            @include mobile {
                width: 100%;
            }

            &:not(:last-child) {
                @include mobile {
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
        }

        .input {
            height: 52px;
            width: 100%;
            display: flex;
            padding: 15px 15px 0;
            background-color: $grey-bg;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            border: 0;
            border-radius: 0;
            box-shadow: initial;
            align-items: flex-start;

            &:not(:placeholder-shown),
            &:focus {
                +.label {
                    font-size: 12px;
                    top: 8px;
                }
            }
        }

        .consent-warning {
            font-weight: normal;
            color: #888;
            width: 70%;
            font-size: 14px;

            a {
                font-size: 14px;
            }

            @include mobile {
                width: 100%;
            }
        }

        .textarea {
            resize: none;
            background-color: $grey-bg;
            height: 125px;
            border: 0;
            border-radius: 0;
            box-shadow: initial;
            padding: 15px;
            font-size: 14px;
            line-height: 17px;
            // color: rgba($black, .5);
            font-weight: 400;

            @include mobile {
                height: 215px;
            }

            &::placeholder {
                color: inherit;
                opacity: 1;
            }

            &:not(:placeholder-shown),
            &:focus {
                +.label {
                    font-size: 12px;
                    // top: 8px;
                    top: 3px;
                }
            }
        }

        .label {
            position: absolute;
            right: 15px;
            font-size: 14px;
            line-height: 1.25;
            font-weight: 400;
            color: $grey-text;
            top: 18px;
            left: 15px;
            transition: font-size $duration, top $duration;
        }

        .button[type='submit'] {
            // width: 100%;
            background-color: $purple;
            height: 44px;
            border: 0;
            // margin-top: 16px;
            border-radius: 0;
        }
    }

    .block-title-list {
        .container.is-fluid {
            padding: 0;
        }

        .block-title {
            margin: 0;

            &:first-child {
                margin-top: 0;
            }
        }

        ul {
            padding-left: 0;
        }
    }

    .longread-slider {
        width: 100%;
        margin-left: initial;
        margin-right: initial;
        overflow: hidden;
    }

    .swiper-slide {
        height: auto;

        img {
            object-fit: cover;
        }
    }

    .swiper-button {
        &-prev {
            left: 8px;
        }

        &-next {
            right: 8px;
        }
    }
}

.page-oplata-ta-dostavka {
    .block-info {
        .info-group {
            max-width: 800px;
        }
    }
}

.page-contacts {
    .block-info {
        .info-group {
            max-width: 100%;
        }
    }
}

//
//end info pages group

.block-regional-partners {
    .partners-banner {
        &-group {
            display: flex;

            @include mobile {
                flex-direction: column;
            }
        }

        width: 66%;

        @include mobile {
            width: 100%;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 375px;
            object-fit: cover;

            @include mobile {
                min-height: 190px;
            }
        }

        &-text {
            background-color: $sand;
            padding: 30px;
            width: 34%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include mobile {
                width: 100%;
                padding: 16px;
            }
        }

        &-title {
            font-family: $title-font;
            font-size: 40px;
            line-height: 1.2;
            font-weight: 500;

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }
        }

        &-descr {
            margin-top: 20px;
            line-height: 1.2;

            @include mobile {
                margin-top: 8px;
                font-size: 14px;
                line-height: 1.4;
            }
        }

        &-join {
            margin-top: 27px;
            height: 44px;
            display: flex;
            align-items: center;
            border: 2px solid $black;
            padding: 0 20px;

            @include mobile {
                margin-top: 20px;
            }
        }
    }

    .partners-text-group {
        margin: 60px auto 0;
        max-width: 820px;

        @include mobile {
            margin: 20px auto 0;
        }

        h2 {
            font-family: $title-font;
            font-size: 32px;
            font-weight: 500;
            line-height: 1.2;

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }
        }

        p {
            line-height: 1.67;
            font-weight: 400;

            @include mobile {
                font-size: 15px;
            }

            &:not(:first-child) {
                margin-top: 18px;
            }
        }

        h3 {
            font-size: 18px;
            line-height: 1.5;
            font-family: $title-font;
            font-weight: 500;

            &:not(:first-child) {
                margin-top: 18px;
            }
        }

        ul,
        ol {
            padding-left: 16px;
            font-weight: 400;
            line-height: 1.67;

            &:not(:first-child) {
                margin-top: 18px;
            }
        }

        ul {
            li {
                &::before {
                    display: block;
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: currentColor;
                    position: absolute;
                    left: -12px;
                    top: 10px;
                }
            }
        }

        li {
            position: relative;

            // &:not(:last-child) {
            //     &::after {
            //         content: ';';
            //     }
            // }

            // &:last-child {
            //     &::after {
            //         content: '.';
            //     }
            // }
        }
    }

    .partners-table-group {
        margin-top: 50px;

        @include mobile {
            margin-top: 20px;
        }
    }

    .region-item {
        padding: 15px 20px 15px;
        border: 2px solid $grey-border;

        @include mobile {
            padding: 16px;
            border-width: 1px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;

            @include mobile {
                margin-bottom: 16px;
            }
        }

        .region-item-title {
            cursor: pointer;
        }

        &.open {
            .region-item-title {
                &::after {
                    transform: rotate(-180deg);
                }
            }

            .region-item-table {
                display: table;
            }
        }

        &-title {
            font-family: $title-font;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
            // padding: 20px 0;
            position: relative;

            @include mobile {
                font-size: 20px;
                line-height: 1.3;
            }

            &::after {
                display: block;
                content: '';
                width: 12px;
                height: 7px;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.73402 0.269532L5.78996 4.37199L9.84591 0.269531L11.0977 1.53559L5.78996 6.90415L0.482271 1.53559L1.73402 0.269532Z' fill='%23222222'/%3E%3C/svg%3E%0A");
                position: absolute;
                right: 0;
                top: calc(50% - 3.5px);
            }
        }

        &-table {
            padding: 6px 0 14px;
            width: 100%;
            margin-top: 26px;
            display: none;

            @include mobile {
                padding: 0;
                margin-top: 28px;
            }

            th {
                font-size: 12px;
                line-height: 1.83;
                color: $grey-text;
                text-transform: uppercase;
                padding-bottom: 13px;
                width: 25%;
            }

            tbody {
                border-top: 2px solid $grey-border;

                @include mobile {
                    border: 0;
                }
            }

            tr {
                @include mobile {
                    display: block;
                    width: 100%;
                }

                &:nth-child(2n) {
                    td {
                        background-color: $grey-bg;

                        @include mobile {
                            background-color: initial;
                        }
                    }
                }

                &:not(:first-child) {
                    @include mobile {
                        padding-top: 16px;
                    }
                }

                &:not(:last-child) {
                    @include mobile {
                        padding-bottom: 16px;
                        border-bottom: 1px solid $grey-border;
                    }
                }
            }

            td {
                padding: 6px 0;
                font-size: 14px;
                line-height: 1.57;
                width: 25%;
                padding-right: 14px;

                @include mobile {
                    padding: 0;
                    padding-right: 0;
                    width: 100%;
                    display: block;
                    line-height: 1.4;
                }

                &:first-child {
                    padding-left: 14px;

                    @include mobile {
                        padding-left: 0;
                    }
                }

                &:not(:last-child) {
                    @include mobile {
                        margin-bottom: 16px;
                    }
                }
            }

            .cell-title-mobile {
                font-size: 12px;
                line-height: 1.83;
                color: $grey-text;
                margin-bottom: 4px;
            }
        }
    }

    .partners-join-group {
        margin-top: 60px;
        display: flex;
        background-color: $purple-light;
        padding: 30px;
        overflow: hidden;

        @include mobile {
            margin-left: -10px;
            margin-right: -10px;
            padding: 20px 10px;
        }
    }

    .join-form {
        &-wrapper {
            padding: 20px;
            background-color: #fff;
            flex-grow: 1;
            flex-shrink: 1;

            @include mobile {
                padding: 14px 10px 10px;
            }
        }

        &-title {
            font-family: $title-font;
            font-size: 32px;
            font-weight: 500;
            line-height: 1.2;

            @include mobile {
                font-size: 18px;
                line-height: 1.5;
            }
        }

        margin-top: 20px;

        @include mobile {
            margin-top: 10px;
        }

        .schema-row {
            @include mobile {
                display: block;
            }

            // width: calc(50% - 4px);

            // &.textarea-field {
            //     width: 100%;
            // }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .schema-col {
            @include mobile {
                width: 100%;
            }

            &:not(:last-child) {
                @include mobile {
                    margin-bottom: 8px;
                }
            }
        }

        .input {
            height: 52px;
            width: 100%;
            display: flex;
            padding: 15px 15px 0;
            background-color: $grey-bg;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            border: 0;
            border-radius: 0;
            box-shadow: initial;
            align-items: flex-start;

            &:not(:placeholder-shown),
            &:focus {
                +.label {
                    font-size: 12px;
                    top: 8px;
                }
            }
        }

        .textarea {
            resize: none;
            background-color: $grey-bg;
            height: 125px;
            border: 0;
            border-radius: 0;
            box-shadow: initial;
            padding: 15px;
            font-size: 14px;
            line-height: 17px;
            color: rgba($black, 0.5);
            font-weight: 400;

            @include mobile {
                height: 100px;
            }

            &::placeholder {
                color: inherit;
                opacity: 1;
            }

            &:not(:placeholder-shown),
            &:focus {
                +.label {
                    font-size: 12px;
                    top: 3px;
                }
            }
        }

        .label {
            position: absolute;
            right: 15px;
            font-size: 14px;
            line-height: 1.25;
            font-weight: 400;
            color: $grey-text;
            top: 18px;
            left: 15px;
            transition: font-size $duration, top $duration;
        }

        .button[type='submit'] {
            // width: 100%;
            background-color: $purple;
            height: 44px;
            border: 0;
            // margin-top: 16px;
            border-radius: 0;

            @include mobile {
                width: 100%;
            }
        }
    }

    .join-figure {
        width: 39%;
        max-width: 514px;
        flex-shrink: 0;
        margin-left: 30px;

        @include mobile {
            display: none;
        }
    }
}

.block-brands-logos {
    .brands-logos {
        margin-top: 16px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: calc(100% + 6px);

        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
        }
    }

    .logo-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        background-color: #fff;
        padding: 8px 15px;
        position: relative;
        border: 2px solid $grey-border;
        margin: -1px;

        @include mobile {
            padding: 8px 0px;
        }

        &:hover {
            .logo-item-image {
                filter: grayscale(0);
            }
        }

        &-label {
            position: absolute;
            top: 12px;
            left: 12px;
            color: $purple;
            font-size: 12px;
            line-height: 28px;
            padding: 0 8px;
            background-color: $purple-ultralight;
            z-index: 2;
        }

        &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 140px;
            width: 100%;
            position: relative;
            z-index: 1;
            filter: grayscale(100%);
            transition: filter 0.5s;

            .image {
                width: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            picture {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg,
            img {
                max-width: 80%;
                max-height: 140px;
                position: static;
            }
        }

        &-title {
            margin-top: 18px;
            font-size: 18px;
            line-height: 1.5;
            text-align: center;
            white-space: break-spaces;
        }

        .badge {
            margin-left: 3px;
            display: initial;
            transform: initial;
        }
    }
}

.block-inventory-selection {
    .product-number {
        background-color: $sand;
        line-height: 17px;
        padding: 3px 8px;
        font-weight: 400;
        margin-left: 0.5rem;

        @include mobile {
            order: 1;
            max-width: 50%;
        }
    }

    .modal {
        textarea {
            padding-top: 20px;
            font-size: 17px;
            color: unset;
        }
    }

    .selection-group {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 30px;
        background-color: $purple-light;
        overflow: hidden;

        @include mobile {
            flex-direction: column;
            padding: 16px 10px 10px;
        }

        &-text {
            margin-right: 44px;
            position: relative;

            @include mobile {
                padding: 0 6px;
                margin-right: 0;
            }
        }

        &-figure {
            position: absolute;
            bottom: -30px;
            right: 0;
            z-index: 1;

            @include mobile {
                display: none;
            }
        }

        &-title {
            font-family: $title-font;
            font-size: 40px;
            font-weight: 500;
            line-height: 1.2;
            max-width: 660px;
            position: relative;
            z-index: 2;

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }

            span {
                color: $purple;
            }
        }

        &-descr {
            margin-top: 20px;
            max-width: 660px;
            font-family: $title-font;
            font-weight: 500;
            font-size: 20px;
            line-height: 1.33;
            position: relative;
            z-index: 2;

            @include mobile {
                margin-top: 8px;
                font-size: 14px;
                line-height: 1.4;
            }
        }

        &-form {
            flex-shrink: 0;
            max-width: 540px;
            padding: 30px;
            background-color: #fff;
            width: 50%;

            @include mobile {
                margin-top: 20px;
                width: 100%;
                max-width: 100%;
                padding: 14px 10px 10px;
            }
        }

        .form-title {
            font-family: $title-font;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            margin-bottom: 14px;

            @include mobile {
                font-size: 18px;
                line-height: 1.5;
            }
        }

        .input {
            height: 52px;
            width: 100%;
            display: flex;
            // padding: 15px 15px 0;
            padding: 1px 15px 0;
            color: $grey-text;
            background-color: $grey-bg;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            border: 0;
            border-radius: 0;
            box-shadow: initial;
            // align-items: flex-start;
            align-items: center;
            cursor: pointer;

            &:not(:placeholder-shown),
            &:focus {
                +.label {
                    font-size: 12px;
                    top: 8px;
                }
            }
        }

        .annotation {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: $grey-text;
            font-weight: 400;
            line-height: 17px;

            span {
                color: $black;
                margin-left: 4px;
                margin-right: 4px;
            }

            .icon {
                color: #fff;
                background-color: $purple;
                background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.26731 5.29423L3.34038 4.53269C3.48654 4.425 3.61731 4.32885 3.73269 4.24423C3.85577 4.15192 3.95962 4.05577 4.04423 3.95577C4.12885 3.85577 4.19038 3.74423 4.22885 3.62115C4.275 3.49038 4.29808 3.33654 4.29808 3.15962C4.29808 3.02115 4.27115 2.88654 4.21731 2.75577C4.17115 2.61731 4.09808 2.49423 3.99808 2.38654C3.89808 2.27885 3.76731 2.19423 3.60577 2.13269C3.45192 2.06346 3.26731 2.02885 3.05192 2.02885C2.59038 2.02885 2.22885 2.16731 1.96731 2.44423C1.71346 2.71346 1.58654 3.08654 1.58654 3.56346H0.375C0.375 3.125 0.444231 2.74038 0.582692 2.40962C0.721154 2.07115 0.909615 1.79038 1.14808 1.56731C1.38654 1.33654 1.66731 1.16346 1.99038 1.04808C2.32115 0.932692 2.675 0.875 3.05192 0.875C3.475 0.875 3.84423 0.940385 4.15962 1.07115C4.475 1.19423 4.73654 1.35962 4.94423 1.56731C5.15192 1.775 5.30577 2.01731 5.40577 2.29423C5.51346 2.56346 5.56731 2.84423 5.56731 3.13654C5.56731 3.45192 5.51346 3.72885 5.40577 3.96731C5.30577 4.20577 5.175 4.41731 5.01346 4.60192C4.85192 4.78654 4.675 4.94808 4.48269 5.08654C4.29038 5.21731 4.10962 5.34038 3.94038 5.45577L3.53654 5.73269V6.58654H2.26731V5.29423ZM2.23269 7.70577H3.57115V9.125H2.23269V7.70577Z' fill='white'/%3E%3C/svg%3E%0A");
                background-size: 6px 10px;
                background-position: center;
                background-repeat: no-repeat;
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-left: 6px;
                margin-right: 0;
            }
        }

        .button[type='submit'] {
            // margin-top: 20px;
            margin-top: 10px;
            width: 100%;
            background-color: $purple;
            height: 44px;
            border: 0;
            border-radius: 0;
            color: #fff;

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    .schema-row {
        &:not(:last-child) {
            margin-bottom: 0;
        }

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        &:empty {
            display: none;
        }
    }

    .results-group {
        margin-top: 30px;
        padding: 30px 20px 20px;
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mobile {
            margin-top: 40px;
            padding: 16px 16px 36px;
            border: 2px solid $grey-border;
        }

        &-title {
            font-family: $title-font;
            font-size: 24px;
            line-height: 1.33;
            font-weight: 500;

            @include mobile {
                font-size: 20px;
                line-height: 1.3;
            }

            span {
                color: $grey-text;
            }
        }
    }

    .results-table {
        // margin-top: 18px;
        margin-top: 36px;
        width: 100%;

        @include mobile {
            margin-top: 20px;
        }

        thead {
            @include mobile {
                display: none;
            }
        }

        th,
        td {
            &:first-child {
                width: 630px;
                padding-left: 14px;

                @include maxw1200 {
                    padding-left: 0;
                    // width: 400px;
                    width: initial;
                }

                @include mobile {
                    width: 100%;
                    margin-bottom: 16px;
                }
            }

            &:not(:last-child) {
                padding-right: 8px;

                @include mobile {
                    padding-right: 0;
                }
            }

            &:nth-child(2) {
                width: 135px;

                @include maxw1200 {
                    width: 100px;
                }

                @include mobile {
                    width: 30%;
                }
            }

            &:nth-child(3) {
                width: 170px;

                @include maxw1200 {
                    width: 100px;
                }

                @include mobile {
                    width: 40%;
                    padding-right: 8px;
                }
            }

            &:nth-child(4) {
                width: 150px;

                @include maxw1200 {
                    width: 100px;
                }

                @include mobile {
                    width: 30%;
                }
            }

            &:nth-child(5) {
                @include mobile {
                    margin-top: 16px;
                    width: 70%;
                }
            }

            &:last-child {
                width: 78px;

                @include mobile {
                    width: initial;
                    margin-top: 16px;
                }
            }
        }

        th {
            font-size: 12px;
            line-height: 28px;
            font-weight: 500;
            color: $grey-text;
            letter-spacing: 0.01em;
            padding-bottom: 10px;
            text-transform: uppercase;
        }

        tr {
            @include mobile {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            &:first-child {
                @include mobile {
                    padding-top: 0;
                }

                td {
                    border-top: 2px solid $grey-border;

                    @include mobile {
                        border: 0;
                    }
                }
            }

            &:not(:last-child) {
                @include mobile {
                    border-bottom: 2px solid $grey-border;
                }
            }

            &:last-child {
                @include mobile {
                    padding-bottom: 0;
                }

                td {
                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }
        }

        td {
            padding-top: 12px;
            padding-bottom: 12px;
            border-bottom: 2px solid $grey-border;

            @include mobile {
                border: 0;
                padding: 0;
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                line-height: 35px;

                @include mobile {
                    line-height: inherit;
                }
            }
        }

        .remove {
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M1.01552 11.0489C1.38808 11.4214 1.01557 11.0488 1.68968 11.723L5.97633 7.43638L10.2519 11.7119C10.4196 11.5434 10.5485 11.4153 10.915 11.0489C11.2756 10.6883 10.9146 11.0484 11.578 10.3858L7.30246 6.11025L11.5892 1.82355C11.4099 1.64444 11.2875 1.522 10.915 1.14944C10.5483 0.782792 10.42 0.654418 10.2409 0.475235L5.95415 4.76194L1.67814 0.485927C1.51009 0.653971 1.37613 0.788825 1.01552 1.14944C0.649089 1.51587 1.01581 1.14964 0.352006 1.81206L4.62802 6.08807L0.341365 10.3747C1.01557 11.0488 0.648876 10.6822 1.01552 11.0489Z' fill='%239B9B9B'/%3E%3C/svg%3E%0A");
            font-size: 0;
            border: 0;
            background-color: transparent;
            width: 13px;
            height: 13px;
            color: $grey-text;
            margin-top: 12px;
            display: block;

            @include maxw1200 {
                margin-left: auto;
                margin-right: auto;
            }

            @include mobile {
                margin-top: 0;
                font-size: 12px;
                line-height: 2.33;
                letter-spacing: 0.01em;
                color: $grey-text;
                background: initial;
                text-transform: capitalize;
                width: auto;
                height: auto;
                display: initial;
            }
        }
    }

    .product {
        display: flex;
        align-items: flex-start;
        // max-width: 530px;

        @include mobile {
            // max-width: 100%;
            align-items: center;
        }

        &-image {
            width: 84px;
            margin-right: 34px;
            flex-shrink: 0;

            @include maxw1200 {
                margin-right: 12px;
            }

            @include mobile {
                width: 56px;
                margin-right: 8px;
            }
        }

        &-text {
            // padding-top: 4px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-category {
            color: $grey-text;
            font-size: 12px;
            line-height: 2.33;
            text-transform: uppercase;

            @include mobile {
                line-height: 1.67;
            }
        }

        &-title {
            font-size: 14px;
            line-height: 1.57;

            @include mobile {
                margin-top: 4px;
                color: $black;
            }
        }
    }

    .cart-change-quantity {
        width: 70px;
        height: 35px;

        @include mobile {
            width: 100%;
            max-width: 100px;
            height: 56px;
        }

        .button.is-small {
            @include mobile {
                font-size: 30px;
                width: 40px;
                color: $grey-text;
            }
        }
    }

    .status {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 3.11;
        white-space: nowrap;

        @include mobile {
            line-height: 22px;
        }

        // &::before {
        //     display: block;
        //     content: '';
        //     margin-right: 4px;
        //     position: relative;
        //     top: -2px;

        //     background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.53535 8.53535C10.4882 6.58249 10.4882 3.41751 8.53535 1.46465C6.58249 -0.488216 3.41751 -0.488216 1.46465 1.46465C-0.488216 3.41751 -0.488216 6.58249 1.46465 8.53535C3.41751 10.4882 6.58453 10.4882 8.53535 8.53535ZM3.20733 4.23579L4.3317 5.36017L6.79472 2.89919L7.66605 3.77053L5.20508 6.23151L4.3317 7.10285L3.46036 6.23151L2.33599 5.10713L3.20733 4.23579Z' fill='currentColor'/%3E%3C/svg%3E%0A");
        //     width: 10px;
        //     height: 10px;
        // }

        &.available {
            color: $green;
        }

        &.not-available {
            color: #f14668;
        }

        &.order {
            color: #9e61ff;
        }

        &.wait {
            color: #ffa825;
        }

        // &.preorder {
        //     color: $orange-2;

        //     &::before {
        //         background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2548:210018)'%3E%3Cpath d='M6.41667 4.31771H9.91667V5.48438H6.41667V4.31771ZM6.41667 6.65104H9.91667V7.81771H6.41667V6.65104ZM6.41667 8.98438H9.91667V10.151H6.41667V8.98438ZM4.08333 4.31771H5.25V5.48438H4.08333V4.31771ZM4.08333 6.65104H5.25V7.81771H4.08333V6.65104ZM4.08333 8.98438H5.25V10.151H4.08333V8.98438ZM11.725 1.98438H2.275C1.98333 1.98438 1.75 2.21771 1.75 2.50938V11.9594C1.75 12.1927 1.98333 12.4844 2.275 12.4844H11.725C11.9583 12.4844 12.25 12.1927 12.25 11.9594V2.50938C12.25 2.21771 11.9583 1.98438 11.725 1.98438ZM11.0833 11.3177H2.91667V3.15104H11.0833V11.3177Z' fill='%23FFA825'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2548:210018'%3E%3Crect width='14' height='14' fill='white' transform='translate(0 0.234375)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        //         width: 14px;
        //         height: 15px;
        //     }
        // }

        // &.available {
        //     color: $green-2;

        //     &::before {
        //         background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.53535 8.53535C10.4882 6.58249 10.4882 3.41751 8.53535 1.46465C6.58249 -0.488216 3.41751 -0.488216 1.46465 1.46465C-0.488216 3.41751 -0.488216 6.58249 1.46465 8.53535C3.41751 10.4882 6.58453 10.4882 8.53535 8.53535ZM3.20733 4.23579L4.3317 5.36017L6.79472 2.89919L7.66605 3.77053L5.20508 6.23151L4.3317 7.10285L3.46036 6.23151L2.33599 5.10713L3.20733 4.23579Z' fill='%235AE157'/%3E%3C/svg%3E%0A");
        //         width: 10px;
        //         height: 10px;
        //     }
        // }

        // &.shipped {
        //     color: $blue-2;

        //     &::before {
        //         background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3636 2.18182H8.72727V0H1.09091C0.490909 0 0 0.490909 0 1.09091V7.09091H1.09091C1.09091 7.99636 1.82182 8.72727 2.72727 8.72727C3.63273 8.72727 4.36364 7.99636 4.36364 7.09091H7.63636C7.63636 7.99636 8.36727 8.72727 9.27273 8.72727C10.1782 8.72727 10.9091 7.99636 10.9091 7.09091H12V4.36364L10.3636 2.18182ZM10.0909 3L11.16 4.36364H8.72727V3H10.0909ZM2.72727 7.63636C2.42727 7.63636 2.18182 7.39091 2.18182 7.09091C2.18182 6.79091 2.42727 6.54545 2.72727 6.54545C3.02727 6.54545 3.27273 6.79091 3.27273 7.09091C3.27273 7.39091 3.02727 7.63636 2.72727 7.63636ZM3.93818 6C3.63818 5.66727 3.21273 5.45455 2.72727 5.45455C2.24182 5.45455 1.81636 5.66727 1.51636 6H1.09091V1.09091H7.63636V6H3.93818ZM9.27273 7.63636C8.97273 7.63636 8.72727 7.39091 8.72727 7.09091C8.72727 6.79091 8.97273 6.54545 9.27273 6.54545C9.57273 6.54545 9.81818 6.79091 9.81818 7.09091C9.81818 7.39091 9.57273 7.63636 9.27273 7.63636Z' fill='%235F88FF'/%3E%3C/svg%3E%0A");
        //         width: 12px;
        //         height: 9px;
        //     }
        // }
    }

    .cell-title-mobile {
        display: none;
        font-size: 12px;
        line-height: 2.33;
        font-weight: 500;
        color: $grey-text;
        letter-spacing: 0.01em;
        text-transform: uppercase;

        @include mobile {
            display: block;
        }
    }

    .results-summary {
        // margin-top: 22px;
        margin-top: 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include mobile {
            margin-top: 8px;
        }

        &-title {
            max-width: 540px;
            font-family: $title-font;
            font-size: 18px;
            font-weight: 500;
            color: #000;

            @include mobile {
                max-width: 100%;
                font-size: 14px;
                line-height: 1.4;
            }
        }

        // &-values {
        //     display: flex;
        //     line-height: 28px;
        //     color: #000;
        //     padding-left: 20px;
        // }

        // &-products {
        //     margin-right: 42px;
        // }
    }

    .results-btn {
        margin-top: 20px;
        height: 44px;
        padding: 0 15px;
        color: #fff;
        background-color: $purple;
        border: 0;
        border-radius: 0;
    }
}

.Vue-Toastification__toast {
    border-radius: 0 !important;
    padding: 12px 14px !important;
    min-height: 44px !important;

    &--success-cart {
        background-color: #b98cff;
    }
}

.product-gallery-modal-open {
    overflow: hidden;

    .header,
    .header-touch {
        z-index: 1;
    }

    .shop-product {
        z-index: 10;

        .product-gallery-fullscreen-content {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 50;
            background-color: #fff;
            padding: 30px 30px 20px;
            align-items: flex-start;
            flex-wrap: wrap;
            justify-content: space-between;

            @include mobile {
                padding: 16px 10px 10px;
            }
        }

        .modal-close {
            z-index: 60;

            path {
                fill: #000;
            }
        }

        .delete {
            // width: 24px;
            // height: 24px;
            z-index: 100;
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.5654 11.8503L23.1043 2.06782L21.088 0L11.5496 9.78199L2.01588 0.000555016L0 2.06882L9.53323 11.8498L0.548433 21.064L2.56477 23.1318L11.5491 13.9181L20.535 23.1375L22.5509 21.0692L13.5654 11.8503Z' fill='%23909090'/%3E%3C/svg%3E%0A");
            // top: 44px;
            top: 40px;
            // right: 30px;
            right: 26px;
            cursor: pointer;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 0;
            background-size: 24px;
            display: block !important;

            @include mobile {
                top: 15px;
                right: 15px;
            }

            &::before,
            &::after {
                content: none;
            }
        }

        .product-gallery-product-title {
            order: 1;
            margin-top: 0;

            @include mobile {
                padding-right: 30px;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }

            &.is-hidden {
                display: block !important;
                // position: fixed;
                // top: 10%;
                // left: 0;
                // z-index: 200;
            }
        }

        .main-gallery-wrapper {
            margin-top: 30px;
            order: 2;
            width: 100%;
            max-height: calc(100vh - 205px);
            height: 100%;
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            margin-bottom: 20px;

            .product-gallery-main {
                max-height: 100%;
                border: 0;
                display: flex;
                flex-grow: 1;
                flex-shrink: 1;

                .swiper-wrapper {
                    display: flex;
                    flex-grow: 1;
                    flex-shrink: 1;
                    height: auto;
                    max-height: 100%;
                }

                .swiper-slide {
                    // flex-shrink: 1;
                    height: auto;
                    max-height: 100%;
                }

                picture {
                    height: 100%;
                }

                img {
                    height: auto;
                    max-height: 100%;
                    object-fit: contain;
                    cursor: default;
                }
            }
        }

        .thumbs-gallery-wrapper {
            // width: auto;
            max-width: calc(100% - 500px);
            margin-right: initial;
            order: 3;
            // max-width: 50%;
            // max-width: 100%;
            flex-grow: 1;
            align-self: flex-end;
            padding-right: 10px;
            flex-direction: row;
        }

        .thumb-btn {
            &.swiper-button {
                &-disabled {
                    display: block !important;
                    opacity: 0.1;
                    pointer-events: none;
                }
            }

            &.thumb-prev {
                margin-bottom: 0;
                margin-right: 10px;
                transform: rotate(-90deg);
            }

            &.thumb-next {
                margin-top: 0;
                margin-left: 10px;
                transform: rotate(90deg);
            }
        }

        .product-gallery-thumbs {
            // width: 326px;
            width: 100%;
            // height: auto;
            height: 74px;

            .swiper-slide {
                width: 74px;
            }
        }

        // .swiper-slide {
        //     text-align: center;
        // }

        .cart {
            order: 4;

            @include mobile {
                width: 100%;
            }

            &.is-hidden {
                display: block !important;
                // position: fixed;
                // top: 50%;
                // right: 0;
                // z-index: 200;
            }
        }

        .card-offer {
            margin-top: 0;
            display: flex;
            align-items: center;

            @include mobile {
                justify-content: space-between;
            }

            .columns {
                flex-direction: row;
                align-items: center;
            }
        }

        .cart-add {
            margin-top: 0;
            margin-left: 30px;

            >div:nth-child(2) {
                display: none;
            }

            .button.is-link.is-small {
                border-radius: 0;
                flex-direction: row-reverse;
            }
        }

        .cart-change-quantity {
            margin-right: 30px;
            position: relative;
            bottom: initial;
            right: initial;
        }
    }
}

.block-products-updates {
    position: relative;
    z-index: 4;
    padding-bottom: 80px;
    overflow: initial !important;
    @include mobile {
        padding-bottom: 60px;
    }

    + .block-text-accordeon {
        margin-top: 0;
    }

    &:first-child {
        z-index: 5;
    }

    .swiper-container {
        display: none;

        &.swiper-container-initialized {
            display: block;
        }
    }

    .tab {
        *:is(.swiper-button-prev, .swiper-button-next) {
            @include mobile {
                display: block;
            }
        }
    }
}

@import 'cart.scss';

.empty-message {
    background: #fff;
    padding: 24px;

    @include mobile {
        padding: 0;
    }

    h2 {
        font-family: 'TT Firs Neue';
        font-weight: 500;
        font-size: 34px;
        line-height: 1.24;
        color: #000;

        @include mobile {
            font-size: 24px;
            line-height: 1.3;
        }
    }

    .description {
        font-family: 'TT Firs Neue';
        margin-top: 20px;
        font-size: 24px;
        color: #909090;
        width: 50%;

        @include mobile {
            margin-top: 0;
            font-size: 20px;
            line-height: 1.3;
            width: 100%;
            max-width: 270px;
        }

        a {
            color: $purple;
        }
    }
}

@include mobile {
    .swiper-slide {
        height: auto;
    }
}

.block-compare-products1 {
    .add-to-compare-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border: 2px solid currentColor;
        border-radius: 0;
        background-color: transparent;
        height: 44px;
        align-self: flex-start;
        color: $purple;

        @include mobile {
            display: none;
        }
    }

    .title-descr {
        margin-top: 20px;
        max-width: 680px;
        font-size: 24px;
        line-height: 32px;
        color: $grey-text;

        a {
            color: $purple;
        }
    }

    .comparison {

        // not object
        &-wrapper {
            margin-top: 10px;
            // width: calc(100% + 32px);
            max-width: calc(100% + 32px);
            margin-right: -32px;
            border: 2px solid $grey-border;
            border-right: 0;

            @media screen and (min-width: 1441px) {
                width: calc(100% + ((100vw - 100%) / 2));
                margin-right: calc((-100vw + 100%) / 2);
            }

            @include maxw1280 {
                width: calc(100% + 10px);
                margin-right: -10px;
            }

            @include touch {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                border: 0;
                width: 100%;
                margin-right: 0;
                // overflow-x: auto;
            }

            .table-container {
                @include touch {
                    order: 3;
                }
            }
        }

        &.table {
            border-top: 0;

            @include touch {
                margin-top: 14px;
                order: 2;
            }

            thead {
                th {
                    border: 0;
                    padding: 0;
                    border-right: 2px solid $grey-border;

                    @include touch {
                        border-top: 2px solid $grey-border;
                        border-bottom: 2px solid $grey-border;
                    }

                    &.first {

                        // position: sticky;
                        // top: 0;
                        // left: 0;
                        @include touch {
                            display: none;
                        }
                    }

                    &:nth-child(2) {
                        @include touch {
                            border-left: 2px solid $grey-border;
                        }
                    }

                    // &:not(:last-child) {
                    //     border-right: 2px solid $grey-border;
                    //     // .cell-inner {
                    //     //     border-right: 2px solid $grey-border;
                    //     // }
                    // }

                    .cell-inner {
                        border-bottom: 2px solid $grey-border;
                        min-height: 195px;

                        @include touch {
                            border: 0;
                            min-height: initial;
                        }
                    }
                }
            }

            th,
            td {
                min-width: 276px;
                max-width: 276px;
                width: 276px;
                border: 0;
                padding: 0;

                @include mobile {
                    min-width: 170px;
                    max-width: 170px;
                    width: 170px;
                }
            }
        }

        &.original {
            tr {
                &:hover {
                    td {
                        @include touch {
                            background-color: inherit !important;
                        }
                    }
                }

                &:not(:last-child) {
                    td {
                        border-bottom: 2px solid $grey-border;

                        @include touch {
                            border: 0;
                        }
                    }
                }

                &:nth-child(4n + 1),
                &:nth-child(4n + 2) {
                    @include touch {
                        background-color: transparent;
                    }

                    &:not(.is-selected):hover {
                        @include touch {
                            background-color: transparent;
                        }
                    }

                    td {
                        @include touch {
                            background-color: transparent !important;
                        }
                    }
                }

                &:nth-child(4n + 3),
                &:nth-child(4n + 4) {
                    @include touch {
                        background-color: $grey-bg !important;
                    }

                    &:not(.is-selected):hover {
                        td {
                            @include touch {
                                background-color: $grey-bg !important;
                            }
                        }
                    }

                    td {
                        @include touch {
                            background-color: $grey-bg !important;
                        }
                    }

                    .row-title-text {
                        background-color: $grey-bg;
                    }
                }
            }

            td {
                font-size: 16px;
                line-height: 35px;
                padding: 2px 10px;

                @include touch {
                    text-align: center;
                }

                &:first-child {
                    color: $grey-text;
                }

                &.first {
                    @include touch {
                        display: none;
                    }
                }

                &:not(:last-child) {
                    border-right: 2px solid $grey-border;

                    @include touch {
                        border: 0;
                    }
                }
            }

            .row-title-touch {
                display: none;

                @include touch {
                    display: table-row;
                }

                td {
                    padding: 0;
                }

                .row-title-text {
                    // width: calc(100vw - 32px);
                    // width: calc(100vw - 10px);
                    width: calc(100vw - 20px);
                    padding: 5px 0;

                    // margin-bottom: -2px;
                    // margin-top: -2px;
                    // height: calc(100% + 4px);
                    @include mobile {
                        font-size: 14px;
                        line-height: 1.2;
                    }
                }

                +tr {
                    td {
                        @include touch {
                            font-size: 14px;
                            line-height: 1.2;
                            padding: 5px 0 10px;
                        }
                    }
                }
            }
        }

        &.corner {

            // &.shadow {
            //     th.first {
            //         .cell-inner {
            //             // border-right: 2px solid $grey-border;
            //             border-right: 0;
            //         }
            //     }
            // }
            @include touch {
                position: relative !important;
                top: 0 !important;
                left: 0 !important;
                order: 1;
            }

            thead {
                th {
                    @include touch {
                        border: 0;
                    }

                    &:not(:last-child) {
                        border-right: 0;
                    }

                    &.first {
                        @include touch {
                            display: flex;
                        }

                        &::before {
                            // left: -1001px;
                            z-index: 1;

                            @include touch {
                                content: none;
                            }
                        }

                        &::after {
                            @include touch {
                                content: none;
                            }
                        }

                        .cell-inner {
                            border-right: 0;
                            border-left: 2px solid $grey-border;
                            margin-left: -2px;
                            min-height: 195px;
                            display: flex;
                            align-items: flex-end;
                            background-color: #fff;
                            // position: relative;
                            z-index: 3;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            left: 0;

                            @include touch {
                                border: 0;
                                background-color: initial;
                                margin: 0;
                                min-height: 0;
                                position: relative;
                                display: flex;
                            }
                        }
                    }

                    &:nth-child(2) {
                        @include touch {
                            border: 0;
                        }
                    }
                }
            }

            .cell-inner {
                @include touch {
                    display: none;
                }
            }
        }

        &-header {
            position: sticky;
            top: 0;
        }

        .comparsion-navigation {
            @include touch {
                display: none;
            }

            >div {
                top: calc(50% - 21px);
                // top: 76px;
            }

            .swiper-button {

                &-prev,
                &-next {
                    box-shadow: initial;
                    margin-top: 0;

                    &.is-disabled {
                        display: none;
                    }
                }

                &-prev {
                    left: 2px;
                }

                &-next {
                    right: 2px;
                }

                // &-disabled {
                //     display: none;
                // }
            }
        }

        .is-hovered {
            @include touch {
                background-color: inherit !important;
            }
        }
    }

    .compare-head-item-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $black;
        padding: 22px 10px 20px;
        min-height: 195px;
        background-color: #fff;

        @include touch {
            padding: 8px 8px 20px;
            min-height: initial;
        }

        .columns {
            margin: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .column {
            padding: 0;
        }

        .card-image {
            height: 256px;

            @include touch {
                height: auto;
            }

            .figure {
                padding-top: 0;
            }

            picture {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
            }

            img {
                object-fit: contain;
            }

            a {
                display: block;
            }
        }

        .title {
            // padding-right: 58px;
            font-size: 14px;
            line-height: 1.5;
            font-weight: 500;
            color: $black;

            @include mobile {
                padding-right: 0;
            }

            &:not(:first-child) {
                margin-top: 16px;

                @include mobile {
                    margin-top: 12px;
                }
            }
        }

        .product-toolbar {
            position: absolute;
            top: 22px;
            right: 10px;
            height: initial;
            display: flex;
            // flex-direction: row-reverse;
            align-items: center;

            @include mobile {
                top: 8px;
                right: 8px;
            }
        }

        .button {
            &.compare {
                width: 18px;
                height: 18px;
                color: #bdbdbd;

                .icon {
                    width: 14px;
                    height: 13px;
                }
            }

            &.wishlist {
                width: 18px;
                height: 19px;
                // margin-right: 12px;
                margin-top: 12px;
                color: $purple;

                &.is-transparent {
                    filter: initial;
                    color: #bdbdbd;
                }

                .icon {
                    width: 13px;
                    height: 19px;
                }
            }

            .icon {
                &.is-transparent {
                    filter: initial;
                }

                &:first-child:last-child {
                    margin-left: 0;
                    margin-right: 0;
                    color: inherit;
                }
            }
        }

        .mt-2 {
            @include mobile {
                margin-top: 26px !important;
            }
        }

        .card-offer {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        .current-price {
            font-size: 21px;
            line-height: 16px;

            &.color-dealer {
                color: $orange;
            }

            span[currency-symbol] {
                font-size: 12px;
                margin-left: 4px;
            }
        }

        .regular-price {
            font-size: 12px;
            line-height: 16px;
            margin-top: 6px;
            // font-weight: 400;
        }

        .button.is-link.is-small {
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            background-color: $purple;
            padding: 0;

            .icon {
                width: 22px;
                height: 20px;
                margin: 0;

                &:first-child:not(:last-child) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            span {
                display: none;
            }
        }
    }

    .switcher {
        padding: 10px 20px;

        @include touch {
            padding: 0;
        }

        .switch[type='checkbox'] {
            +label {
                white-space: nowrap;

                @include mobile {
                    font-size: 14px;
                    line-height: 2;
                    padding-top: 5px;
                }

                &::before {
                    background-color: #e7e7e7;
                }

                &::after {
                    background-color: #9b9b9b;
                }
            }
        }
    }
}

.block-compare-products {
    .add-to-compare-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border: 2px solid currentColor;
        border-radius: 0;
        background-color: transparent;
        height: 44px;
        align-self: flex-start;
        color: $purple;

        @include mobile {
            display: none;
        }
    }

    .block-head {
        margin-bottom: 0;

        @include responsive {
            padding-right: $wrap-padding-x;
        }

        @include maxw1280 {
            padding-right: 10px;
        }
    }

    .badge {
        font-size: inherit;
        transform: initial;
    }

    .comparison-wrapper {
        margin-top: 10px;
        // width: calc(100% + 32px);
        max-width: calc(100% + 32px);
        margin-right: -32px;
        border: 2px solid $grey-border;
        border-right: 0;
        display: inline-block;

        @media screen and (min-width: 1441px) {
            // width: calc(100% + ((100vw - 100%) / 2));
            max-width: calc(100% + ((100vw - 100%) / 2));
            margin-right: calc((-100vw + 100%) / 2);
        }

        @include maxw1280 {
            width: calc(100% + 10px);
            margin-right: -10px;
        }

        @include touch {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            border: 0;
            // width: 100%;
            width: calc(100% + 20px);
            margin-right: -10px;
            margin-left: -10px;
            // padding-left: 10px;
            // padding-right: 10px;
        }
    }

    .comparison {
        &.table {
            border: 0;
            width: auto;

            @include mobile {
                margin: 0 10px;
            }

            th,
            td {
                min-width: 276px;
                max-width: 276px;
                width: 276px;

                @include mobile {
                    min-width: 172px;
                    max-width: 172px;
                    width: 172px;
                }

                &.first {
                    @include mobile {
                        display: none;
                    }

                    &::after {
                        background: $grey-border;
                        // right: -1px;
                        width: 2px;
                        opacity: 1;
                    }
                }
            }

            th {
                padding: 0;
                border-bottom: 2px solid $grey-border;

                @include mobile {
                    border-top: 2px solid $grey-border;
                }

                &.first {
                    &::after {
                        right: -1px;
                    }
                }

                &:nth-child(2) {
                    @include mobile {
                        border-left: 2px solid $grey-border;
                    }
                }

                .pr-6 {
                    border-right: 2px solid $grey-border;
                    padding: 10px 10px 20px !important;
                    // height: 490px;
                    height: 400px;
                    margin-right: -1px;
                    display: flex;
                    flex-direction: column;

                    @include mobile {
                        padding: 8px 8px 20px !important;
                        height: 330px;
                    }
                }

                .image.is-4by3 {
                    padding-top: 100%;

                    a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                    }

                    picture {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                .product-toolbar {
                    top: 14px;
                    right: 14px;
                    height: initial;

                    @include mobile {
                        top: 8px;
                        right: 8px;
                    }
                }

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    filter: initial;
                    color: $grey-text-2;

                    &:not(:last-child) {
                        margin-bottom: 12px;

                        @include mobile {
                            margin-bottom: 8px;
                        }
                    }

                    &.compare {
                        width: 18px;
                        height: 18px;

                        .icon {
                            width: 13px;
                            height: 13px;
                        }
                    }

                    &.wishlist {
                        width: 18px;
                        height: 18px;

                        .icon {
                            width: 13px;
                            height: 18px;
                        }
                    }

                    &.is-link {
                        width: 44px;
                        height: 44px;
                        background-color: $purple;
                        color: #fff;
                        padding: 0;
                        border-radius: 0;

                        .icon {
                            width: 22px;
                            height: 20px;
                        }

                        span {
                            display: none;
                        }
                    }

                    .icon {
                        color: inherit;
                        filter: initial;
                        margin: 0 !important;
                    }
                }

                .mt-2 {
                    margin-top: auto !important;
                }

                .card-offer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .card-prices {
                    display: flex;
                    flex-direction: column-reverse;
                }

                .title {
                    font-size: 14px;
                    line-height: 1.5;
                    font-weight: 500;
                    color: $black;

                    @include mobile {
                        font-size: 12px;
                        line-height: 1.4;
                    }
                }

                .current-price {
                    display: flex;
                    align-items: baseline;
                    font-size: 24px;
                    line-height: 0.69;
                    color: $orange;

                    @include mobile {
                        font-size: 20px;
                        line-height: 0.83;
                    }

                    [currency-symbol] {
                        font-size: 12px;
                        margin-left: 4px;

                        @include mobile {}
                    }
                }

                .old-price {
                    margin-top: 6px;
                    font-size: 12px;
                    line-height: 1.38;
                    color: $black;

                    @include mobile {
                        margin-top: 3px;
                        line-height: 1;
                    }
                }

                .columns {
                    margin: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .column {
                    padding: 0;
                }
            }

            td {
                border-color: $grey-border;
                border-right: 2px solid $grey-border;

                @include mobile {
                    border: 0;
                    text-align: center;
                    padding: 0.5rem 0;
                }

                &.first {
                    &::after {
                        right: -2px;
                    }
                }
            }
        }

        &.corner {
            th {
                border-bottom: 0;

                &.first {
                    &::before {
                        border-right: 2px solid $grey-border;
                    }
                }
            }
        }

        &.original {
            tr {

                &:nth-child(4n + 1),
                &:nth-child(4n + 2) {
                    @include mobile {
                        background-color: transparent;
                    }

                    &:not(.is-selected):hover {
                        @include mobile {
                            background-color: transparent;
                        }
                    }

                    td {
                        @include mobile {
                            background-color: transparent !important;
                        }
                    }
                }

                &:nth-child(4n + 3),
                &:nth-child(4n + 4) {
                    @include mobile {
                        background-color: $grey-bg !important;
                    }

                    &:not(.is-selected):hover {
                        td {
                            @include mobile {
                                background-color: $grey-bg !important;
                            }
                        }
                    }

                    td {
                        @include mobile {
                            background-color: $grey-bg !important;
                        }
                    }

                    .cell-title-mobile {
                        background-color: $grey-bg;
                    }
                }

                &:not(:last-child) {
                    td {
                        &.first {
                            &::before {
                                display: block;
                                content: '';
                                position: absolute;
                                bottom: -1px;
                                left: 0;
                                right: 0;
                                background: $grey-border;
                                height: 1px;
                                width: initial;
                            }
                        }
                    }
                }
            }

            .cell-title-mobile {
                display: none;

                @include mobile {
                    display: table-row;
                }

                div {
                    font-size: 14px;
                    line-height: 1.14;
                    color: rgba($black, 0.5);
                    text-align: center;
                    padding-top: 4px;
                    width: calc(100vw - 20px);
                    position: sticky;
                    left: 10px;
                }
            }
        }

        // &.shadow {
        //     .first {
        //         &::after {
        //             opacity: 1;
        //         }
        //     }
        // }
        .table-container {
            @include mobile {
                padding: 0 10px;
            }
        }

        .comparsion-navigation {
            >div {
                top: 50%;
            }
        }
    }

    .switcher {
        padding: 10px 20px;

        @include touch {
            padding: 0;
        }

        .switch[type='checkbox'] {
            +label {
                white-space: nowrap;

                @include mobile {
                    font-size: 14px;
                    line-height: 2;
                    padding-top: 5px;
                }

                &::before {
                    background-color: #e7e7e7;
                }

                &::after {
                    background-color: #9b9b9b;
                }
            }
        }
    }
}

.page-auth-thanks,
.page-auth-thanks-social {
    padding-top: 30px;

    .breadcrumb {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $grey-text;
        margin-bottom: 42px;
        display: inline-block;

        @include mobile {
            margin-bottom: 24px;
        }
    }

    p {
        display: block;

        &:first-of-type {
            font-family: $title-font;
            font-size: 40px;
            font-weight: 500;
            line-height: 1.2;

            @include mobile {
                font-size: 24px;
            }
        }

        &:not(:first-of-type) {
            margin-top: 20px;
            font-family: $title-font;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            color: $grey-text;

            @include mobile {
                margin-top: 16px;
                font-size: 20px;
            }
        }

        a {
            color: $purple;
            text-decoration: none;
        }
    }
}

[currency-symbol] {
    margin-left: 0.2em;
}

.linking-block {
    margin-top: 20px;
    background-color: $grey-bg;
    padding-top: 4px;

    &__title {
        font-size: 16px;
        line-height: 24px;
        width: 100%;

        margin: 5px 0 7px 0;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__items {
        list-style: inside;
        margin-top: 8px;
        padding-bottom: 10px;
    }

    &__item {
        padding: 3px 15px;
        transition: color 0.3s, background-color 0.3s;
        line-height: 1.57;
        font-weight: 400;

        &:hover {
            a {
                color: $purple;
            }
        }
    }
}

.fancybox {
    &__container {
        .f-button {
            color: #fff;
            width: 76px;
            height: 76px;
            background: transparent;
        }
        svg {
            width: 36px;
            height: 36px;
        }
    }
    &__backdrop {
        background: rgba(#000,.6);
    }
}