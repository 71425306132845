html {
    // padding-top: 4rem;
    scroll-behavior: smooth;

    &.is-modal-open,
    &.is-appointment-modal-open {
        .navbar {
            &.is-fixed-top {
                z-index: 20000;
            }
        }

        .block {
            z-index: unset;
        }
    }

    &.is-mobile-filter-opened {
        .header-group:has(.sales-header) {
            display: none;
        }
        .catalog-filter {
            transform: translateX(0%) !important;
            pointer-events: auto !important;
        }

        .scroll-top-btn {
            display: none;
        }
    }
}

[hidden] {
    display: none !important;
}

.Vue-Toastification__container {
    z-index: 99999;
}

.Vue-Toastification__close-button {
    opacity: 1 !important;
}

.navbar-dropdown {
    z-index: 200;
}

.slider-base {
    border-radius: 0;
    background-color: rgb(221, 221, 221);
}

.nested.dropdown {
    display: flex;

    &:hover > .dropdown-menu {
        display: block;
    }

    .dropdown-menu {
        top: -0.5rem;
        margin-left: 100%;
    }

    .dropdown-trigger {
        width: 100%;

        .icon {
            transform: translateX(2rem) rotate(-90deg);
        }

        .navbar-item {
            justify-content: space-between;
        }
    }
}

.simplebar-scrollbar::before {
    background-color: #aaa;
}

.badge {
    font-size: 60%;
    color: #aaa;
    display: inline-block;
    transform: translate(8px, -1px);
}

.shop-search {
    .dropdown {
        width: 100%;
    }

    .dropdown-trigger {
        width: 100%;
    }

    .dropdown-menu {
        width: 100%;
    }

    .dropdown-item {
        &.is-active {
            background-color: #eee;
        }
    }

    .simplebar-vertical {
        background-color: transparent;
    }
}

form {
    .schema-row {
        display: flex;

        &:not(:first-child) {
            .fields-section {
                margin-top: 2rem;
            }
        }
    }

    input {
        &.is-danger {
            border: 1px solid #f14668 !important;
        }
    }

    textarea {
        &.is-danger {
            border: 1px solid #f14668 !important;
        }
    }

    .schema-col {
        width: 1000%;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    .field-password {
        .show-hide {
            height: 100%;
            position: absolute;
            top: 0;
            right: 0.75rem;
            font-size: 80%;
            display: flex;
            align-items: center;
        }

        .password-strenth {
            height: 0.25rem;

            .item {
                background-color: #eee;
                transition: background-color 0.5s;
                height: 0.25rem;
            }

            &.strenth-1 {
                .column {
                    &:nth-child(1) {
                        .item {
                            background-color: #fc8181;
                        }
                    }
                }
            }

            &.strenth-2 {
                .column {
                    &:nth-child(1),
                    &:nth-child(2) {
                        .item {
                            background-color: #ffdd57;
                        }
                    }
                }
            }

            &.strenth-3 {
                .column {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        .item {
                            background-color: #ffa500;
                        }
                    }
                }
            }

            &.strenth-4 {
                .item {
                    background-color: #03ad3e;
                }
            }
        }
    }

    .is-checkradio[type='checkbox'] + label {
        p {
            display: initial;
        }
    }

    .switch + label {
        p {
            display: initial;
        }
    }
}

.product-toolbar {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    line-height: 0;
    padding: 0;
    z-index: 3;
    display: flex;
    flex-flow: row;

    .button {
        background-color: transparent;
        height: 2rem;
        width: 2rem;
        border: 0;
        box-shadow: none !important;
        padding: 0;

        &.is-loading {
            font-size: 0.75rem;

            &:after {
                border: 2px solid $purple;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        }
    }
}

.comparison-wrapper {
    position: relative;
}

.comparison {
    .comparsion-navigation {
        > div {
            transform: scale(0.8);
            top: 2rem;
        }
    }

    &.only-difference {
        .is-same {
            display: none;
        }
    }

    &.table {
        border-top: 2px solid #dbdbdb;
        margin-bottom: 0;

        .is-hovered {
            background-color: #fafafa !important;
        }

        tr {
            &:hover {
                td {
                    background-color: #fafafa !important;
                }
            }
        }

        td,
        th {
            min-width: 300px;
            max-width: 300px;
            width: 300px;
        }

        tbody td {
            &:first-child {
                position: sticky;
                left: 0;
                background-color: white;
                z-index: 1;
            }
        }

        .first {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -10px;
                background: linear-gradient(90deg, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
                width: 10px;
                height: 100%;
                transition: opacity 0.3s;
                opacity: 0;
            }
        }
    }

    &.shadow {
        .first {
            &:after {
                opacity: 1;
            }
        }
    }

    &.original {
        thead {
            display: none;
        }
    }

    &.duplicate {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 31;

        .switcher {
            display: none;
        }
    }

    &.corner {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 32;
        background-color: transparent;
        display: none;
        pointer-events: none;

        thead {
            th {
                &:first-child {
                    background-color: #fff;
                    position: relative;
                    pointer-events: all;
                    vertical-align: bottom;

                    &:before {
                        content: '';
                        position: absolute;
                        background: #fff;
                        height: calc(100% + 2px);
                        width: 1000px;
                        top: 0;
                        left: -1000px;
                    }
                }

                &:not(:first-child) {
                    visibility: hidden;
                }
            }
        }
    }

    .product-toolbar {
        top: 0;
        right: 0;
        height: calc(100% - 10px);
        flex-flow: column;
        justify-content: space-between;
    }
}

img:not([src]):not([srcset]) {
    visibility: hidden;
}

picture {
    position: relative;

    &:not(.no-lazy) {
        img {
            transition: opacity 0.5s;
            position: relative;
            z-index: 3;
            opacity: 0;
        }

        &:after {
            @include loader;
            position: absolute;
            width: 16px;
            height: 16px;
            border-width: 0.15em;
            top: calc(50% - 8px);
            left: calc(50% - 8px);
            transition: opacity 0.3s;
            z-index: 2;
            pointer-events: none;
            user-select: none;
        }

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #fff;
            transition: opacity 0.3s;
            z-index: 1;
        }

        &.is-lazy-loaded {
            &:after {
                opacity: 0;
                content-visibility: auto;
                animation: none;
            }
        }
    }
}

.lazy {
    &.loaded {
        opacity: 1 !important;
    }
}

.swiper-container {
    overflow: unset;

    // .swiper-button-prev {
    //     transform: translateX(-0.2rem) scale(0.5);
    //     top: -2rem;
    //     right: 60px;
    //     left: unset;
    // }

    // .swiper-button-next {
    //     transform: translateX(0.2rem) scale(0.5);
    //     top: -2rem;
    //     right: 32px;
    // }
}

.is-transparent {
    filter: brightness(300%);
}

.is-disabled {
    opacity: 0.5;
    pointer-events: none;
}

// .cross-sale {
//     position: relative;

//     .tabs {
//         position: absolute;
//         top: -8px;
//         width: calc(100% - 32px - 32px - 100px);

//         ul {
//             padding-left: 370px;
//         }
//     }
// }

.card {
    box-shadow: none;
    border: 1px solid #ddd;

    .card-image {
        background-color: #eee;
    }
}

.bundles-container {
    .swiper-pagination {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 0.5rem;

        .swiper-pagination-bullet {
            margin: 0.2rem;
        }
    }
}

// #product-gallery-thumbs {
//     .swiper-slide {
//         opacity: 0.4;
//     }

//     .swiper-slide-thumb-active {
//         opacity: 1;
//     }
// }

.pagination {
    a {
        &.is-loading {
            position: relative;
            pointer-events: none;
            color: transparent;

            &:after {
                @include loader;
                position: absolute;
                width: 50%;
                height: 50%;
                border-width: 0.15em;
            }
        }
    }
}

.shop-cart-item {
    .inactive .button,
    .inactive .input,
    .inactive .price,
    .inactive .name {
        opacity: 0.5;
        pointer-events: none;
    }

    .inactive .remove {
        pointer-events: all;
        opacity: 1;
    }
}

.scroll-top-btn {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: $purple;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 14' fill='none'%3E%3Cpath d='M1 12.5L11.5 2L22 12.5' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: 23px 14px;
    background-position: center;
    background-repeat: no-repeat;
    width: 65px;
    height: 65px;
    border: 0;
    border-radius: 0;
    z-index: 14;
    transition: $duration;
    @media (hover: hover) {
        &:hover {
            background-color: $purple-light;
        }
    }
    @include mobile {
        width: 48px;
        height: 48px;
        right: 10px;
        bottom: 10px;
    }
}

.block {
    &:has(.swiper-container) {
        overflow: hidden;
    }
}