.block-cart {
    @include mobile {
        background-color: #fff;
        padding-bottom: 50px;
    }

    .toggle {
        color: #8539fd;
        text-align: center;
        //margin-top: 16px;
        padding-top: 16px;
        margin-bottom: 0;
        cursor: pointer;

        @include mobile {
            //border-top: 2px solid $grey-border;
            margin-bottom: 0;
        }
    }

    .cart-body-controls {
        &:empty {
            display: none;
        }
    }

    .cart-mobile-title {
        display: none;
    }

    .block-title {
        width: 100%;
    }

    .cart-content {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        @include touch {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .cart-body {
        flex-grow: 1;
        padding: 20px;
        background-color: #fff;
        width: calc(100% - 350px);

        @include touch {
            width: 100%;
        }

        @include mobile {
            padding: 0;
        }

        &-title {
            font-size: 24px;
            font-weight: 500;
            font-family: $title-font;
            line-height: 1.33;

            @include mobile {
                margin-top: 16px;
                font-size: 20px;
                line-height: 1.3;
                border: 2px solid $grey-border;
                border-bottom: 0;
                padding: 16px;
                position: relative;
            }

            &::before {
                @include mobile {
                    display: block;
                    content: '';
                    border-bottom: 2px solid $grey-border;
                    position: absolute;
                    bottom: 0;
                    left: 16px;
                    right: 16px;
                }
            }

            span {
                color: $grey-text;
            }

            + .cart-body-controls {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        &-comment-form {
            margin-top: 16px;

            textarea {
                resize: none;
                background-color: #f5f5f5;
                width: 100%;
                max-width: 740px;
                height: 125px;
                border: 0;
                border-radius: 0;
                box-shadow: initial;
                padding: 15px;
                font-size: 14px;
                line-height: 17px;
                color: rgba(34, 34, 34, 0.5);
                font-weight: 400;
                display: block;
                outline: 0;
            }

            .commercial-btn-reset {
                margin-top: 8px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0;
                background-color: transparent;
                border: 0;
                font-size: 14px;
                padding: 0 15px;
                color: #fff;
                background-color: $purple;
            }
        }

        &-controls {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            @include mobile {
                margin-top: 0;
                padding: 20px 16px 24px;
                border: 2px solid $grey-border;
                border-top: 0;
                flex-direction: column-reverse;
                order: 4;
            }

            .btn {
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0;
                background-color: transparent;
                border: 2px solid currentColor;
                font-size: 14px;
                padding: 0 15px;
                @include mobile {
                    font-size: 12px;
                }

                &.reset-cart-btn {
                    color: $grey-text;
                }

                &.commercial-btn {
                    color: $orange-2;
                    &:not(:last-child) {
                        @include mobile {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
    }

    .mobile-cart-order {
        @include mobile {
            display: flex;
            flex-direction: column;
        }
    }

    .cart-products {
        margin-top: 18px;
        width: 100%;
        $cell-gap: 50px;
        font-size: 14px;
        line-height: 28px;

        @include mobile {
            margin-top: 0;
            display: block;
            padding: 16px 16px 0;
            border-left: 2px solid $grey-border;
            border-right: 2px solid $grey-border;
            // border-bottom: 2px solid $grey-border;
        }

        thead {
            @include mobile {
                display: none;
            }
        }

        tbody {
            @include mobile {
                display: block;
            }
        }

        tr {
            @include mobile {
                padding-top: 12px;
                padding-bottom: 12px;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }

            // &:first-child {
            //     @include mobile {
            //         border-top: 2px solid $grey-border;
            //     }
            // }

            &:not(:last-child) {
                @include mobile {
                    //padding-bottom: 12px;
                    border-bottom: 2px solid $grey-border;
                }
            }
            &:last-child {
                padding-bottom: 0;
            }
        }

        th,
        td {
            // &:not(:last-child) {
            &:nth-child(-n + 4) {
                padding-right: $cell-gap;

                @include mobile {
                    padding-right: 0;
                }
            }
        }

        th {
            font-size: 12px;
            // line-height: 28px;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: $grey-text;
            padding-bottom: 6px;
            border-bottom: 2px solid $grey-border;
            white-space: nowrap;

            &:first-child {
                padding-left: 15px;
            }

            &:last-child {
                width: 76px;
            }

            &.dealer-column {
                padding-left: 10px;
            }
        }

        td {
            padding-top: 8px;
            padding-bottom: 8px;
            border-bottom: 2px solid $grey-border;
            position: relative;

            @include mobile {
                padding: 0;
                border: 0;
            }

            &.dealer-column {
                padding-top: 0;
                padding-bottom: 0;

                .price-wrapper {
                    color: $orange;
                    padding: 8px 10px;
                    height: 100%;
                    background: rgba(238, 238, 238, 0.3);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: calc(#{$cell-gap} - 10px);
                    bottom: 0;
                }
            }

            &:nth-child(1) {
                @include mobile {
                    width: 100%;
                    // margin-bottom: 16px;
                    margin-bottom: 12px;
                }
            }
            &:nth-child(2) {
                @include mobile {
                    padding-left: 64px !important;
                    min-width: 126px;
                }
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                @include mobile {
                    width: calc(100% / 3);
                    // width: calc((100% / 3) - 1px);
                    width: auto;
                    // margin-bottom: 16px;
                }
            }
            &:nth-child(4) {
                @include mobile {
                    padding-right: 0 !important;
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    min-width: 62px;
                }
            }
            &:nth-child(5),
            &:nth-child(6) {
                @include mobile {
                    width: 50%;
                }
            }
        }

        .product-wrapper {
            display: flex;
        }

        .product-image {
            width: 50px;
            flex-shrink: 0;
            margin-right: 8px;
            overflow: hidden;

            @include mobile {
                width: 56px;
            }

            img {
                &.empty {
                    transform: scale(2);
                }
            }
        }

        .amount-wrapper {
            white-space: nowrap;
            // text-align: right;
        }

        .price-wrapper {
            white-space: nowrap;
            text-align: right;

            @include mobile {
                text-align: left;
            }

            .dealer-price {
                color: $orange;
            }
        }

        .product-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include mobile {
                padding-right: 32px;
            }
        }

        .product-number {
            // background-color: #f3f3f3;
            background-color: rgba(#ffbf17, 0.15);
            line-height: 17px;
            padding: 3px;
            font-weight: 400;
            margin-left: 5px;
            font-size: 80%;
        }

        .product-category {
            text-transform: uppercase;
            font-size: 12px;
            // line-height: 28px;
            color: $grey;

            @include mobile {
                line-height: 1.67;
            }
        }

        .product-name {
            line-height: 130%;
        }

        .product-title {
            max-width: 266px;
            // font-size: 14px;
            font-weight: 500;
            line-height: 22px;

            @include mobile {
                margin-top: 4px;
                max-width: 100%;
                line-height: 1.57;
            }
        }

        .product-remove {
            margin-top: 3px;
            font-size: 12px;
            // line-height: 28px;
            // color: rgba($black, 0.3);
            color: $grey;
            border: 0;
            background-color: transparent;

            &.with-icon {
                @include mobile {
                    color: $grey-text;
                    display: flex;

                    svg {
                        width: 16px;
                    }
                }
            }
        }

        .old-price {
            color: $grey-text;
            text-decoration: line-through;
            font-size: 12px;
            line-height: 20px;

            + .current-price {
                line-height: 20px;
            }
        }

        .cart-change-quantity {
            input {
                transition: opacity 0.3s ease-in-out;
            }

            &.is-loading {
                position: relative;
                pointer-events: none;
                color: transparent;

                input {
                    opacity: 0;
                }

                &:after {
                    @include loader;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    border-width: 0.15em;
                    top: calc(50% - 7px);
                    left: calc(50% - 7px);
                }
            }

            input {
                background-color: transparent;
            }
        }

        .cell-title-mobile {
            // font-size: 12px;
            font-size: 11px;
            // line-height: 2.33;
            line-height: 8px;
            letter-spacing: 0.01em;
            color: $grey-text;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    // .shop-cart-item {
    //     margin: 0;
    //     &:last-child {
    //         margin-bottom: 0;
    //     }
    //     .columns {
    //         margin: 0;
    //         &:last-child {
    //             margin-bottom: 0;
    //         }
    //     }
    //     .column {
    //         padding: 0;
    //     }
    // }

    .cart-head {
        width: 330px;
        margin-left: 20px;
        flex-shrink: 0;
        padding: 20px 20px 15px;
        background-color: #fff;
        position: sticky;
        top: 0;

        @include touch {
            margin-left: 0;
            margin-top: 20px;
        }

        @include mobile {
            margin-top: 20px;
            width: 100%;
            max-width: 480px;
            padding: 16px;
            border: 2px solid $grey-border;
        }

        &-title {
            font-size: 24px;
            font-weight: 500;
            font-family: $title-font;
            line-height: 1.33;
        }

        &-summary {
            margin-top: 8px;
            width: 100%;
            padding-bottom: 15px;
            border-bottom: 2px solid $grey-border;
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            color: $grey-text;

            .summary-group {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:not(:last-child) {
                    margin-bottom: 14px;
                }

                &-title {
                    &::after {
                        content: ':';
                    }
                }
            }

            .orange {
                color: $orange;
            }
        }

        &-total {
            margin-top: 10px;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            line-height: 28px;

            span {
                font-size: 18px;
            }
        }

        &-controls {
            margin-top: 16px;

            &.is-promo-code {
                .toggler {
                    color: $purple;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-bottom: 10px;

                    svg {
                        width: 8px;
                        height: 5px;
                        margin-left: 4px;
                        transform: rotate(180deg);
                    }
                }

                &.is-toggled {
                    svg {
                        transform: rotate(0deg);
                    }
                }

                .promo-code-form {
                    input {
                        border: 0;
                        outline: 0;
                        background-color: #f5f5f5;
                        color: #909090;
                        height: 44px;
                        padding: 0 10px;
                        font-size: 14px;

                        &::placeholder {
                            color: #909090;
                        }
                    }

                    button {
                        font-size: 14px;
                        color: #fff;
                        background-color: $purple;
                        border: 0;
                        outline: 0;
                        border-radius: 0;
                        height: 44px;
                    }
                }
            }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 44px;
                border: 2px solid $purple;
                font-size: 14px;
                font-weight: 500;
                border-radius: 0;
                width: 100%;

                &.checkout-btn {
                    color: #fff;
                    background-color: $purple;
                }

                &.is-disabled,
                &[disabled] {
                    opacity: 0.5;
                    pointer-events: all;
                }

                &.buy-one-click-btn {
                    color: $purple;
                    background-color: #fff;
                }

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }

        &-promo {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            &.open {
                .promo-toggler {
                    &::before {
                        transform: rotate(-180deg);
                    }
                }

                .promo-dropdown {
                    display: flex;
                }
            }

            .promo-toggler {
                font-size: 14px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: $purple;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943344 2.25903e-07L4 3.09171L7.05666 3.0199e-08L8 0.95413L4 5L3.01121e-08 0.95413L0.943344 2.25903e-07Z' fill='%239E61FF'/%3E%3C/svg%3E%0A");
                    width: 8px;
                    height: 5px;
                    margin-left: 4px;
                }
            }

            .promo-dropdown {
                display: none;
                padding-bottom: 5px;
                display: flex;
                align-items: center;
            }

            .promo-input {
                width: 100%;
                height: 44px;
                color: $grey;
                background-color: $grey-bg;
                border: 0;
                border-radius: 0;
                display: block;
                padding: 0 10px;
                font-weight: 300;

                &:focus {
                    box-shadow: 0;
                    outline: 0;
                }

                &::placeholder {
                    opacity: 1;
                }
            }

            .promo-submit {
                color: #fff;
                border: 0;
                border-radius: 0;
                height: 44px;
                width: 106px;
                background-color: rgba($purple, 0.5);
                flex-shrink: 0;
            }
        }
    }

    .empty-descr {
        margin-top: 20px;
        max-width: 680px;
        font-size: 24px;
        line-height: 1.33;
        font-family: $title-font;
        color: $grey-text;

        a {
            color: $purple;
        }
    }

    .mobile-hidden {
        @include mobile {
            display: none;
        }
    }

    .desktop-hidden {
        display: none;

        @include mobile {
            display: block;
        }
    }

    .mobile-visible {
        display: none;

        @include mobile {
            display: block;
        }
    }

    .checkout-order-success {
        .checkout-title {
            width: 100%;
            font-size: 32px;
            line-height: 1.2;
            font-family: $title-font;
            font-weight: 500;

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }
        }

        .checkout-descr {
            margin-top: 10px;
            font-size: 24px;
            line-height: 1.33;
            font-family: $title-font;
            font-weight: 500;
            margin-bottom: 20px;
            max-width: 700px;

            @include mobile {
                font-size: 18px;
            }
        }

        .checkout-content {
            margin-top: 15px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;

            @include touch {
                flex-direction: column;
            }

            @include mobile {
                background-color: transparent;
                padding-bottom: 0;
            }

            .checkout-body {
                width: calc(100% - 350px);
                position: relative;

                @include touch {
                    width: 100%;
                }
            }

            .block-title {
                display: none;

                &.block-title-empty {
                    display: block;
                }
            }
        }

        .order-products {
            &-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &-title {
                font-size: 24px;
                font-weight: 500;
                font-family: $title-font;
                line-height: 1.33;

                @include mobile {
                    font-size: 20px;
                    line-height: 1.3;
                }

                span {
                    color: $grey-text;
                }
            }

            &-table {
                margin-top: 18px;
                width: 100%;
                $cell-gap: 50px;
                font-size: 14px;
                line-height: 28px;

                @include mobile {
                    margin-top: 12px;
                    display: block;
                }

                thead {
                    @include mobile {
                        display: none;
                    }
                }

                tbody {
                    @include mobile {
                        display: block;
                    }
                }

                tr {
                    @include mobile {
                        padding-top: 12px;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                    }

                    &:first-child {
                        @include mobile {
                            border-top: 2px solid $grey-border;
                        }
                    }

                    &:not(:last-child) {
                        @include mobile {
                            padding-bottom: 12px;
                            border-bottom: 2px solid $grey-border;
                        }
                    }

                    &:last-child {
                        @include mobile {
                            padding-bottom: 12px;
                            border-bottom: 2px solid $grey-border;
                        }
                    }
                }

                th,
                td {
                    // &:not(:last-child) {
                    &:nth-child(-n + 4) {
                        padding-right: $cell-gap;

                        @include maxw1200 {
                            padding-right: 32px;
                        }

                        @include mobile {
                            padding-right: 0;
                        }
                    }
                }

                th {
                    font-size: 12px;
                    // line-height: 28px;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: $grey-text;
                    padding-bottom: 6px;
                    border-bottom: 2px solid $grey-border;
                    white-space: nowrap;

                    &:first-child {
                        padding-left: 15px;
                    }

                    &:last-child {
                        width: 76px;
                    }
                }

                td {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    border-bottom: 2px solid $grey-border;
                    position: relative;

                    @include mobile {
                        padding: 0;
                        border: 0;
                    }

                    &:nth-child(1) {
                        @include mobile {
                            width: 100%;
                            margin-bottom: 16px;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5) {
                        @include mobile {
                            width: calc(100% / 4);
                            margin-bottom: 0;
                        }
                    }
                }

                .product-wrapper {
                    display: flex;
                }

                .product {
                    @include mobile {
                        max-width: 100%;
                    }
                }

                .product-image {
                    width: 50px;
                    flex-shrink: 0;
                    margin-right: 8px;

                    @include mobile {
                        width: 56px;
                    }
                }

                .amount-wrapper {
                    white-space: nowrap;
                }

                .price-wrapper {
                    white-space: nowrap;
                    text-align: right;

                    @include mobile {
                        text-align: left;
                    }

                    .dealer-price {
                        color: $orange;
                    }
                }

                .product-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .product-category {
                    text-transform: uppercase;
                    font-size: 12px;
                    color: $grey;

                    @include mobile {
                        line-height: 1.67;
                    }
                }

                .product-title {
                    max-width: 266px;
                    font-weight: 500;
                    line-height: 22px;

                    @include mobile {
                        margin-top: 4px;
                        max-width: 100%;
                        line-height: 1.57;
                    }
                }

                .cell-title-mobile {
                    font-size: 12px;
                    line-height: 2.33;
                    letter-spacing: 0.01em;
                    color: $grey-text;
                    font-weight: 500;
                    text-transform: uppercase;
                    display: none;

                    @include mobile {
                        display: block;
                    }
                }

                .old-price {
                    font-size: 12px;
                    line-height: 15px;
                    color: $grey-text;
                    text-decoration: line-through;
                }
            }

            &-catalog-btn {
                margin-top: 18px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0;
                background-color: transparent;
                border: 2px solid currentColor;
                font-size: 14px;
                padding: 0 15px;
                color: $purple;

                @include mobile {
                    margin-top: 24px;
                    max-width: 480px;
                    width: 100%;
                    color: #fff;
                    background-color: $purple;
                }
            }
        }

        .product {
            display: flex;
            align-items: flex-start;
            max-width: 324px;

            &-photo {
                width: 50px;
                margin-right: 8px;
                flex-shrink: 0;
            }

            &-info {
                // padding-top: 4px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &-category {
                color: $grey-text;
                font-size: 12px;
                line-height: 18px;

                @include mobile {
                    line-height: 1.67;
                }
            }

            &-title {
                font-size: 14px;
                line-height: 1.57;
            }
        }
    }
}

.wrapper {
    &:has(.cart-head-controls) {
        overflow: unset;
    }
}

.cart-page {
    .cart-products {
        .mobile-visible {
            &.price,
            &.quantity {
                @include mobile {
                    display: none;
                }
            }
        }

        td {
            @include mobile {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    padding: 0 11px;
                }

                &:nth-child(2) {
                    justify-content: flex-end;
                }

                &:nth-child(3) {
                    width: max-content;
                }

                &:nth-child(4) {
                    justify-content: flex-start;
                }
            }
        }

        .cart-sum {
            @include mobile {
                display: flex;
                flex-direction: row;
                align-items: center;

                .amount-wrapper {
                    // padding-left: 10px;
                    margin-top: 6px;
                    font-size: 14px;
                    line-height: 10px;
                }
            }
        }
    }

    .cart-body {
        .cart-body-controls {
            @include mobile {
                // padding-top: 0;
                padding-top: 16px;
                // padding-bottom: 0;
                padding-bottom: 16px;

                .btn.reset-cart-btn {
                    border: none;
                }
            }
        }
    }

    .product-remove.with-icon {
        padding-left: 10px;
    }
}
