.modal {
    &-background {
        background-color: rgba($black, .3);
    }

    &-content {
        max-width: 446px;
        width: 100%;
        position: relative;
    }

    &-content,
    &-card {
        @include mobile {
            max-height: 100vh;
            max-height: -webkit-fill-available;
        }
    }

    &-close {
        position: absolute;
        right: 10px;
        top: 10px;
        color: $grey-text;
        border-radius: 0;

        @include mobile {
            width: 24px;
            height: 24px;
            right: 20px;
        }

        &.is-large {
            min-width: initial;
            min-height: initial;
            width: 22px;
            height: 22px;

            @include mobile {
                width: 24px;
                height: 24px;
            }
        }

        .icon {
            @include mobile {
                width: 12px;
                height: 12px;
            }
        }

        &:hover {
            background-color: transparent;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    .box {
        padding: 20px;
        border-radius: 0;
        box-shadow: initial;
        color: inherit;

        @include mobile {
            padding: 16px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .tabs {
        display: none;
    }

    .title {
        margin-top: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.33;

        @include mobile {
            margin-top: 0;
            font-size: 24px;
            line-height: 1.33;
            color: #000;
        }

        &:first-child {
            margin-top: 0;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .field {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &:last-child {
            margin-top: 25px;

            @include mobile {
                margin-top: 16px;
            }
        }
    }

    .input {
        height: 52px;
        width: 100%;
        display: flex;
        padding: 15px 15px 0;
        background-color: $grey-bg;
        line-height: 17px;
        font-weight: 400;
        border: 0;
        border-radius: 0;
        box-shadow: initial;
        align-items: flex-start;

        &:not(:placeholder-shown),
        &:focus {
            +.label {
                font-size: 12px;
                top: 8px;
            }
        }
    }

    .textarea {
        resize: none;
        background-color: $grey-bg;
        height: 125px;
        border: 0;
        border-radius: 0;
        box-shadow: initial;
        padding: 15px;
        font-size: 14px;
        line-height: 17px;
        color: rgba($black, .5);
        font-weight: 400;

        &::placeholder {
            color: inherit;
            opacity: 1;
        }
        &:not(:placeholder-shown),
        &:focus {
            +.label {
                font-size: 12px;
                top: 3px;
            }
        }
    }

    .label {
        position: absolute;
        right: 15px;
        font-size: 14px;
        line-height: 1.25;
        font-weight: 400;
        color: $grey-text;
        top: 18px;
        left: 15px;
        transition: font-size $duration, top $duration;
    }

    .show-hide {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon {

            color: inherit !important;
            position: initial !important;
        }

        .password-visible {
            color: $purple;
        }

        .password-hidden {
            color: $grey-text;
        }

        span {
            display: none;
        }
    }

    .rating {
        .has-text-warning {
            color: $purple !important;
        }
    }

    .password-strenth-wrapper {
        display: none;
    }

    .button[type="submit"] {
        width: 100%;
        background-color: $purple;
        height: 44px;
        border: 0;
        // margin-top: 16px;
        border-radius: 0;
    }

    .auth-registration,
    .auth-login {
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .columns {
            margin: 0;
            flex-direction: column;
            align-items: stretch;
        }

        .column {
            padding: 0;
        }

        .href-forget-password {
            align-self: flex-start;
            color: $purple;
        }

        .socials {
            margin-top: 12px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            &::before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                border-top: 2px solid $grey-border;
                top: 11px;
            }
        }

        .description {
            font-size: 14px;
            line-height: 1.57;
            padding: 0 7px;
            background-color: #fff;
            position: relative;
            z-index: 2;
            color: $grey-text-2;
        }

        .button[type="submit"] {
            margin-top: 16px;
        }

        .buttons {
            width: 100%;
            margin-top: 16px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px;

            .button {
                background-color: $grey-bg;
                height: 44px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                border: 0;
                margin-bottom: 0;
                border-radius: 0;
                width: 100%;

                // @include mobile {
                //     width: 100%;
                // }
                &:not(:last-child):not(.is-fullwidth) {
                    margin-right: 0;
                }

                &[type="submit"] {
                    background-color: $purple;
                }

                .icon {
                    margin: 0 !important;
                }

                span {
                    margin-left: 8px;
                }
            }
        }

        .href-already-register,
        .href-register {
            margin: 20px auto 0;
            display: inline-flex;
            color: $purple;
            line-height: 2;
        }
    }

    .auth-registration {
        .button {
            margin-top: 10px;
            background-color: $purple;
            height: 44px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            border: 0;
            margin-bottom: 0;
            border-radius: 0;
            width: 100%;
            color: #fff;
            font-size: 14px;
        }
    }

    .auth-forget {
        .button[type="submit"] {
            margin-top: 0;
        }

        .mt-2 {
            margin: 20px auto 0 !important;
            color: #9E61FF;
            line-height: 2;
            text-align: center;
        }
    }
}