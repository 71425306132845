.block-account {
    margin-top: 0 !important;
    position: relative;
    z-index: 4;

    // background-color: $grey-bg;
    @include mobile {
        background-color: #fff;
    }

    .container {
        display: flex;

        @include mobile {
            display: block;
            padding: 0;
        }
    }

    .account-sidebar-toggler-mobile {
        font-size: 14px;
        line-height: 1.4;
        color: $purple;
        padding: 12px 20px;
        border: 0;
        border-radius: 0;
        display: none;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 2px solid #fff;

        @include mobile {
            display: flex;
        }

        .icon {
            width: 15px;
            margin-right: 8px;
        }

        .arrow {
            margin-left: auto;
            width: 13px;
            height: 8px;
        }
    }

    .account {
        margin: 0;
        width: 100%;

        @include mobile {
            position: relative;
        }

        &:last-child {
            margin-bottom: 0;
        }

        // .columns {
        //     flex-direction: column;
        //     margin: 0;
        //     &:last-child {
        //         margin-bottom: 0;
        //     }
        // }
        .column {
            padding: 0;
            // width: 100%;
        }

        >.column.is-3 {
            width: 300px;
            flex-shrink: 0;
            background-color: #fff;
            flex-direction: column-reverse;

            @include mobile {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                display: none;
                background-color: $grey-bg;
                width: 100%;
                // z-index: 3;
                z-index: 4;
            }

            &.is-opened {
                @include mobile {
                    display: block;
                }
            }
        }

        >.column:nth-child(2) {
            padding-top: 30px;
            width: 100%;
            padding-left: 32px;

            @include maxw1200 {
                padding-top: 20px;
                padding-left: 20px;
            }

            @include mobile {
                padding: 0;
            }
        }
    }

    .column.is-3 {
        // width: 300px;
        // flex-shrink: 0;
        // background-color: #fff;
        // flex-direction: column-reverse;

        .user-wrapper {
            padding: 15px 10px 15px 7px;
            border-bottom: 2px solid $grey-border;
            display: flex;
            align-items: center;

            @include mobile {
                display: none;
            }

            .user-photo {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                color: #fff;
                margin-right: 9px;
                background-color: $purple-medium;
                font-weight: 400;
                font-size: 17px;
                flex-shrink: 0;
                border-radius: 50%;
            }

            .user-info {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: calc(100% - 45px);
            }

            .user-name {
                font-size: 15px;
                line-height: 28px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 25px);
            }

            .user-label {
                position: relative;
                margin-left: 4px;
                cursor: pointer;
                transform: translateY(-1px);

                &:hover {
                    .label-annotation {
                        display: block;
                    }
                }
            }

            .label-icon {
                width: 17px;
                height: 17px;
                border-radius: 30px;
                background-size: 13px 12px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.50002 0.429199L7.9275 4.53587L12.2743 4.62445L8.80973 7.2511L10.0687 11.4125L6.50002 8.9292L2.93132 11.4125L4.19031 7.2511L0.725746 4.62445L5.07254 4.53587L6.50002 0.429199Z' fill='white'/%3E%3C/svg%3E%0A");

                &.dealer-icon {
                    background-color: $orange;
                }

                &.opp-icon {
                    background-color: green;
                }
            }

            .label-annotation {
                padding: 5px 15px;
                position: absolute;
                top: calc(100% + 8px);
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                font-size: 14px;
                line-height: 1.4;
                font-weight: 400;
                display: none;
                box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);

                &:before {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 6px 6px 6px;
                    border-color: transparent transparent #fff transparent;
                    bottom: 100%;
                    left: calc(50% - 6px);
                    position: absolute;
                }
            }

            .user-mail {
                font-size: 13px;
                line-height: normal;
                font-weight: 400;
                color: $grey;
                margin-top: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
        }

        .menu-list {
            padding-top: 10px;
            padding-bottom: 50px;

            @include mobile {
                padding: 6px 0;
            }

            a {
                display: flex;
                align-items: center;
                padding: 6px 17px;
                font-size: 14px;
                line-height: 28px;
                transition: background-color .5s, color .5s;

                @include mobile {
                    // line-height: 1.4;
                    padding: 6px 20px;
                    color: $black;
                }

                &:hover {
                    background-color: #F5EFFF;
                    color: $purple;

                    @include mobile {
                        background-color: initial;
                    }

                    svg {
                        color: $purple;
                    }
                }

                svg {
                    color: $grey-text;
                    margin-right: 8px;
                    width: 16px;
                    height: 16px;
                    transition: color .5s;
                }

                &.is-active {
                    color: $purple;
                    background-color: $purple-ultralight;

                    @include mobile {
                        background-color: initial;
                    }

                    svg {
                        color: $purple;
                    }
                }

                .badge {
                    border-radius: 100%;
                    padding: 5px 2px;
                    font-size: 9px;
                    line-height: 1.1;
                    background-color: $purple;
                    font-weight: bold;
                    color: #fff;
                    min-width: 19px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            hr {
                height: 2px;
                background-color: $grey-border;

                @include mobile {
                    background-color: #fff;
                    margin: 6px 0;
                }
            }
        }
    }

    .account-content {

        // padding-top: 30px;
        // width: 100%;
        // padding-left: 32px;
        @include mobile {
            padding: 24px 10px;
        }

        .block-head {
            gap: 12px;
            flex-wrap: nowrap;

            @include mobile {
                flex-direction: column;
            }
        }
    }

    .block-head {
        @include mobile {
            margin-bottom: 0;
        }
    }

    .field {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        // &:last-child {
        //     margin-top: 25px;
        // }
    }

    .input {
        height: 52px;
        width: 100%;
        display: flex;
        padding: 15px 15px 0;
        background-color: $grey-bg;
        line-height: 17px;
        font-weight: 400;
        border: 0;
        border-radius: 0;
        box-shadow: initial;
        align-items: flex-start;

        &:not(:placeholder-shown),
        &:focus {
            +.label {
                font-size: 12px;
                top: 8px;
            }
        }

        &[type="number"] {
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                display: none;
            }
        }
    }

    .label {
        position: absolute;
        right: 15px;
        font-size: 14px;
        line-height: 1.25;
        font-weight: 400;
        color: $grey-text;
        top: 18px;
        left: 15px;
        transition: font-size $duration, top $duration;
    }

    .show-hide {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon {

            color: inherit !important;
            position: initial !important;
        }

        .password-visible {
            color: $purple;
        }

        .password-hidden {
            color: $grey-text;
        }

        span {
            display: none;
        }
    }

    .rating {
        .has-text-warning {
            color: $purple !important;
        }
    }

    .password-strenth-wrapper {
        display: none;
    }

    .button[type="submit"] {
        width: 100%;
        background-color: $purple;
        height: 44px;
        border: 0;
        // margin-top: 16px;
        border-radius: 0;
    }

    textarea {
        height: 150px;
        resize: none;
        border-radius: 0;
        border: 1px solid $grey-border;
        box-shadow: initial;

        @include mobile {
            height: 214px;
            background-color: $grey-bg;
            color: rgba($black, .5);
        }

        &:hover {
            box-shadow: none;
            border-color: $grey-border;
        }

        &::placeholder {
            color: $black;
        }

        &:focus {
            box-shadow: 0;
            border-color: $grey-border;
        }
    }

    .load-more-btn {
        height: 44px;
        margin-top: 20px;
        padding: 0 15px;
        color: #fff;
        background-color: $purple;
        border: 0;
        border-radius: 0;
    }

    .selector-item {
        // margin-top: 10px;
        display: flex;
        align-items: center;

        @include mobile {
            display: none;
        }

        &-label {
            font-size: 14px;
            margin-right: 8px;
        }

        &-inner {
            position: relative;

            @include touch {
                width: 100%;
            }

            &::before,
            &::after {
                display: block;
                content: '';
                position: absolute;
                background-color: transparent;
                pointer-events: none;
            }

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3014 1.80001H14.4014V0H12.6014V1.80001H5.40138V0H3.60136V1.80001H2.70138C1.70689 1.80001 0.910395 2.6055 0.910395 3.60003L0.901367 16.2C0.901367 17.1945 1.70685 18 2.70138 18H15.3014C16.2959 18 17.1014 17.1945 17.1014 16.2V3.59998C17.1014 2.6055 16.2959 1.80001 15.3014 1.80001ZM15.3014 16.2H2.70138V6.29998H15.3014V16.2Z' fill='%23B3B3B3'/%3E%3Cpath d='M13.501 8.1001H4.50098V9.90011H13.501V8.1001Z' fill='%23B3B3B3'/%3E%3Cpath d='M10.8009 11.7002H4.50098V13.5002H10.8009V11.7002Z' fill='%23B3B3B3'/%3E%3C/svg%3E%0A");
                width: 18px;
                height: 18px;
                left: 9px;
                top: calc(50% - 9px);
                // top: 13px;
            }

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943344 2.25903e-07L4 3.09171L7.05666 3.0199e-08L8 0.95413L4 5L3.01121e-08 0.95413L0.943344 2.25903e-07Z' fill='%239B9B9B'/%3E%3C/svg%3E%0A");
                width: 8px;
                height: 5px;
                right: 8px;
                top: calc(50% - 2.5px);
                // top: 20px;
            }

        }

        select {
            height: 32px;
            border: 1px solid $grey-border;
            border-radius: 0;
            background-color: transparent;
            padding-left: 34px;
            padding-right: 24px;
            appearance: none;
            color: $grey-text;
            font-weight: 400;
            cursor: pointer;
        }

        .select-placeholder {
            height: 32px;
            border: 1px solid $grey-border;
            padding-left: 34px;
            padding-right: 24px;
            display: flex;
            align-items: center;
            cursor: pointer;

            @include mobile {
                height: 44px;
            }
        }
    }

    .calendar-dropdown {

        position: absolute;
        right: -6px;
        top: calc(100% + 10px);
        // width: 555px;
        width: 745px;
        // padding: 15px 20px 20px;
        // background-color: #fff;
        box-shadow: 0px 1px 5px rgba(34, 34, 34, 0.15);
        display: grid;
        grid-template-columns: 190px auto;

        @include touch {
            width: 700px;
        }

        @include mobile {
            // width: calc(100vw - 20px);
            width: 100%;
            grid-template-columns: 1fr;
            position: relative;
            margin-top: 16px;
            right: 0;
        }

        // &.is-hidden {
        //     display: grid !important;
        // }
        &::before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #fff transparent;
            position: absolute;
            bottom: 100%;
            right: 13px;
        }

        &-head {
            background-color: $grey-border;
            padding: 15px 20px;
        }

        .presets {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include mobile {
                flex-direction: row;
                gap: 10px;
                flex-wrap: wrap;
            }

            // gap: 8px 15px;
            // width: 190px;
            a {
                word-break: break-word;
                color: $grey-text;

                &:not(:last-child) {
                    margin-bottom: 10px;

                    @include mobile {
                        margin-bottom: initial;
                    }
                }

                &.is-active {
                    color: $black;
                }
            }
        }

        &-body {
            background-color: #fff;
            padding: 15px 20px 20px;
        }

        .vc-container {
            --blue-200: #{$black};
            --blue-600: #{$purple};
            --blue-700: #{$purple};
            --blue-900: #000;

            margin: 0;
            width: 100%;

            &:not(:first-child) {
                margin-top: 10px;
            }

            border: 0;
        }

        .vc-pane-layout {
            grid-column-gap: 30px;

            @include mobile {
                grid-template-columns: 1fr !important;
                grid-row-gap: 24px;
            }
        }

        .vc-pane {
            width: 100%;
            min-width: initial;
        }

        .vc-header {
            padding: 0;

        }

        .vc-title {
            font-family: $title-font;
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
            color: #000;
            text-transform: capitalize;
        }

        .vc-weeks {
            padding: 0;
            margin-top: 12px;
            min-width: initial;
            width: 100%;
        }

        .vc-weekday {
            font-size: 14px;
            line-height: 1.57;
            text-transform: lowercase;
            color: $grey-text;
            font-weight: 500;
            padding: 0;
        }

        .vc-day {
            font-size: 14px;
            line-height: 30px;
            height: 30px;
            font-weight: 500;
            color: #000;

            &.is-not-in-month {
                color: $grey-text;

                * {
                    opacity: 1;
                    color: inherit;
                }
            }
        }

        .vc-day-content {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit !important;

            // color: inherit !important;
            &.is-disabled {
                color: $grey-text;
                opacity: 1;
            }
        }

        .vc-highlight {
            background-color: $purple;
        }

        .vc-highlight-base-start,
        .vc-highlight-base-middle,
        .vc-highlight-base-end {
            background-color: #F2EAFF !important;
        }

        .vc-arrows-container {
            padding: 0;
            top: -3px;
        }

        .vc-arrow {
            color: $black;
            border-radius: 0;

            &:hover {
                background-color: $purple-light;
            }

            &.is-disabled {
                color: $grey-text;
                pointer-events: none;
            }
        }

        .calendar-submit {
            margin-top: 20px;
            height: 44px;
            padding: 0 15px;
            border: 0;
            background-color: $purple;
            border-radius: 0;
            color: #fff;
        }
    }

    .block-title {
        font-size: 34px;
        line-height: 1.24;
        display: flex;

        @include touch {
            font-size: 32px;
        }

        @include mobile {
            font-size: 24px;
            line-height: 1.3;
        }

        .badge {
            border-radius: 20px;
            padding: 0 2.5px;
            font-size: 17px;
            line-height: 24px;
            background-color: $purple-medium;
            font-weight: 400;
            color: #fff;
            min-width: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            position: relative;
            top: 2px;
            // margin-left: 8px;
            display: inline-flex;
        }
    }

    .swither-group {
        display: flex;
        align-items: center;

        @include mobile {
            width: 100%;
            justify-content: space-between;
        }

        .switcher-title {
            font-size: 14px;
            line-height: 28px;
            letter-spacing: 0.01em;
            margin-right: 6px;
            white-space: nowrap;

            @include mobile {
                line-height: 1.4;
            }
        }

        .switcher {
            display: block;
            content: '';
            width: 34px;
            height: 19px;
            background-color: rgba($purple, .15);
            border-radius: 30px;
            position: relative;
            cursor: pointer;

            span {
                position: absolute;
                top: 2px;
                right: 17px;
                bottom: 2px;
                width: 15px;
                border-radius: 50%;
                background-color: $grey-bg;
                transition: right $duration;
                display: block;
                transition: background-color $duration;
            }

            input:checked+span {
                right: 2px;
                background-color: $purple;
            }
        }
    }

    .download-btn {
        display: inline-flex;
        min-width: 184px;
        padding: 0 20px;
        height: 44px;
        border: 2px solid currentColor;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: $purple;

        &::before {
            display: block;
            content: '';
            margin-right: 5px;
            flex-shrink: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.25 9.625V12.25H1.75V9.625H0V12.25C0 13.2125 0.7875 14 1.75 14H12.25C13.2125 14 14 13.2125 14 12.25V9.625H12.25ZM11.375 6.125L10.1413 4.89125L7.875 7.14875V0H6.125V7.14875L3.85875 4.89125L2.625 6.125L7 10.5L11.375 6.125Z' fill='%239E61FF'/%3E%3C/svg%3E%0A");
            width: 14px;
            height: 14px;
        }
    }

    .content-blocks {
        margin-top: 18px;

        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        @include mobile {
            margin-top: 0;
        }

        &.dashboard-blocks {
            // columns: 2;
            // column-gap: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;

            @include touch {
                // grid-template-columns: 1fr;
                display: block;
            }

            @include mobile {
                .content-block {
                    display: none;
                }

                margin-top: 0;
            }
        }
    }

    .tab-selectors-mobile {
        display: none;
        padding: 24px 10px 0;
        overflow: auto;
        align-items: center;
        border-bottom: 1px solid $grey-border;
        width: calc(100% + 20px);
        // margin-left: -10px;
        // margin-right: -10px;
        // margin: -24px -10px 0;
        margin: 0 -10px;
        scrollbar-width: none;

        @include mobile {
            display: flex;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .tab-selector {
            white-space: nowrap;
            padding-bottom: 10px;
            line-height: 1.4;
            color: $grey-text;
            position: relative;

            &:not(:last-child) {
                margin-right: 35px;
            }

            &.is-active {
                color: $black;
                border-bottom: 2px solid currentColor;
            }

            .badge {
                font-size: 12px;
                line-height: 1;
                min-width: 16px;
                height: 16px;
                border-radius: 50%;
                color: #fff;
                background-color: $purple-medium;
                position: absolute;
                // left: 100%;
                // bottom: 100%;
                left: calc(100% - 8px);
                bottom: calc(100% - 4px);
                padding: 0 4px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .content-block {
        // width: calc(50% - 10px);
        // margin-bottom: 20px;
        padding: 20px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        break-inside: avoid;

        @include mobile {
            padding: 10px 0 0;
        }

        &.is-visible-mobile {
            @include mobile {
                display: flex !important;
            }
        }

        &:not(:last-child) {
            margin-bottom: 20px;

            @include mobile {
                margin: 0;
            }
        }

        &-head {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;

            @include mobile {
                display: none;
            }
        }

        .content-block-mobile-title {
            margin-bottom: 10px;
        }

        @include mobile {
            // .dashboard-chart {
            //     transform: scale(.8);
            //     transform-origin: top left;
            // }

            .sales-stats-table {
                th {
                    white-space: nowrap;
                }

                td {
                    &:first-child {
                        padding-right: 10px;
                    }
                }
            }
        }

        &-title {
            font-size: 24px;
            line-height: 1.33;
            font-family: $title-font;
            font-weight: 500;
            color: $black;
            display: flex;

            @include mobile {
                font-size: 20px;
                line-height: 1.3;
            }

            .badge {
                // margin-left: 8px;
                border-radius: 20px;
                padding: 3px 2px;
                font-size: 12px;
                line-height: 1.1;
                background-color: $purple-medium;
                font-weight: 400;
                color: #fff;
                min-width: 19px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                align-self: center;
                position: relative;
                top: 2px;
            }
        }

        &-list {
            width: 100%;
            break-inside: avoid;

            &:not(:first-child) {
                margin-top: 15px;

                @include mobile {
                    margin-top: 0;
                }
            }
        }

        table {
            width: 100%;

            th {
                font-size: 12px;
                line-height: 1.83;
                letter-spacing: -0.01em;
                color: $grey-text;
                border-bottom: 2px solid $grey-border;
                text-transform: uppercase;
            }

            tr {
                &:not(:last-child) {
                    td {
                        padding-bottom: 14px;
                        border-bottom: 1px solid $grey-border;
                    }
                }
            }

            td {
                font-size: 14px;
                line-height: 2;
                padding-top: 14px;
                vertical-align: middle;

                @include mobile {
                    line-height: 1.4;
                }

                &:first-child {
                    vertical-align: top;
                }
            }

            .product {
                display: flex;
                align-items: center;
                color: $black;

                &:empty {
                    display: none;
                }

                &-logo {
                    width: 37px;
                    height: 37px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    margin-right: 9px;
                    border: 1px solid $grey-border;
                    overflow: hidden;

                    @include mobile {
                        width: 52px;
                        height: 52px;
                        margin-right: 12px;
                    }
                }

                &-title {
                    font-family: $title-font;
                    font-size: 18px;
                    line-height: 1.5;

                    @include mobile {
                        font-size: 20px;
                        line-height: 1.3;
                        color: $purple;
                    }
                }
            }

            .cell-title {
                font-size: 12px;
                line-height: 1.83;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: $grey-text;
                margin-bottom: 2px;
                display: none;

                @include mobile {
                    display: flex;
                }
            }
        }

        .more {
            margin-top: 20px;
            font-size: 12px;
            line-height: 28px;
            text-transform: uppercase;
            font-weight: 500;
            color: $purple;
            border: 0;
            background-color: transparent;
        }

        .chart-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-shrink: 0;
        }

        .chart-item {
            // max-width: 160px;
            position: relative;
            width: 100%;
        }

        .chart-circle {
            position: relative;
            width: 100%;
            padding-bottom: 100%;

            canvas {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
            }
        }

        .chart-total {
            font-family: $title-font;
            font-size: 35px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $orange;

            @include mobile {
                font-size: 30px;
                font-weight: 400;
            }
        }

        .chart-annotation {
            margin-top: 8px;
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 17px;
            color: rgba(34, 34, 34, .3);

            &::before {
                display: block;
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #FAD87F;
                margin-right: 5px;
                flex-shrink: 0;
            }
        }
    }

    .group-sales-plan {
        .select-wrapper {
            width: 97px;
            position: relative;

            &::after {
                display: block;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943466 2.25903e-07L4.00012 3.09171L7.05678 3.0199e-08L8.00012 0.95413L4.00012 5L0.0001221 0.95413L0.943466 2.25903e-07Z' fill='%23B3B3B3'/%3E%3C/svg%3E%0A");
                width: 8px;
                height: 5px;
                position: absolute;
                right: 8px;
                top: calc(50% - 2.5px);
            }

            select {
                height: 27px;
                border: 1.5px solid $grey-border;
                width: 100%;
                padding-left: 8px;
                padding-right: 30px;
                appearance: none;
                font-size: 12px;
                color: $grey-text;
                background: transparent;
                border-radius: 0;
            }
        }

        .content-block-list {
            display: grid;
            grid-template-columns: 3fr 2fr;
            grid-gap: 20px;

            @include mobile {
                grid-template-columns: auto 160px;
                grid-gap: 16px;
            }
        }

        .sales-stats-table {
            max-width: 276px;
            align-self: start;

            // width: 100%;
            // font-weight: 500;
            th,
            td {
                &:nth-child(2) {
                    width: 96px;
                    padding-right: 6px;

                    @include mobile {
                        width: 50%;
                        padding-right: 0;
                    }
                }
            }

            td {
                &:nth-child(2) {
                    color: $orange;
                }
            }

            .cell-item {
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }

        .chart-item {
            max-width: 160px;
        }
    }

    .group-latest-news {
        .news-item {
            border: 2px solid $grey-border;
            display: flex;

            @include mobile {
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &.new {
                background-color: $purple-ultralight;
            }

            &-photo {
                min-height: 105px;
                flex-shrink: 0;
                width: 130px;

                @include mobile {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-info {
                padding: 10px 15px;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 14px;
                line-height: 1.57;
                align-content: flex-start;

                @include mobile {
                    padding: 16px;
                }
            }

            &-date {
                color: $grey-text;
            }

            &-label {
                margin-left: 4px;
                color: $purple-medium;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 3px;
                    background-color: currentColor;
                    flex-shrink: 0;
                }
            }

            &-title {
                width: 100%;
            }
        }
    }

    .group-supply-schedule {
        .download-btn {
            margin-top: 12px;
        }
    }

    .group-account-info {
        .account-info-table {
            thead {
                @include mobile {
                    display: none;
                }
            }

            tr {
                @include mobile {
                    display: block;
                    width: 100%;
                }
            }

            th,
            td {
                width: 50%;

                @include mobile {
                    width: 100%;
                }
            }

            td {
                @include mobile {
                    display: block;
                    padding: 0;
                }

                &:not(:last-child) {
                    @include mobile {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    .group-account-addresses {
        .addresses-list {
            width: 100%;
            display: grid;
            // grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }

        .address-item {
            width: 100%;
            // min-height: 160px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 1.5px solid $grey-border;
            padding: 15px 16px;

            &.is-main {
                background-color: rgba($orange-2, .1);
            }

            &-head {
                color: $grey-text;
                line-height: 1.57;
            }

            .main-label {
                color: $orange;
            }

            &-value {
                margin-top: 4px;
                font-size: 15px;
                line-height: 1.47;
                margin-bottom: auto;
                width: 100%;

                @include mobile {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 1.57;
                }
            }

            &-controls {
                margin-top: 26px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 8px 15px;
                font-size: 12px;
                line-height: 15px;
                color: $grey-text;

                @include mobile {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 1.4;
                }

                a {
                    &::first-letter {
                        @include mobile {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }

        .more {
            margin-top: 20px;
        }
    }

    .group-currencies {
        .currencies-table {
            width: 100%;

            thead {
                @include mobile {
                    display: none;
                }
            }

            tr {
                @include mobile {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }

                &:not(:first-child) {
                    @include mobile {
                        padding-top: 16px;
                    }
                }

                &:not(:last-child) {
                    @include mobile {
                        padding-bottom: 16px;
                        border-bottom: 2px solid $grey-border;
                    }

                    td {
                        @include mobile {
                            border: 0;
                            padding: 0;
                        }
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 8px;

                        @include mobile {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            th,
            td {
                &:nth-child(1) {
                    @include mobile {
                        width: 100%;
                        margin-bottom: 8px;
                    }
                }

                &:nth-child(2) {
                    width: 30%;
                    max-width: 135px;

                    @include mobile {
                        width: calc(50% - 18px);
                        max-width: 100%;
                        margin-left: 36px;
                    }
                }

                &:nth-child(3) {
                    width: 20%;
                    max-width: 72px;

                    @include mobile {
                        width: calc(50% - 18px);
                        max-width: 100%;
                    }
                }
            }

            td {
                @include mobile {
                    padding: 0;
                }

                &:nth-child(2) {
                    color: $grey-text;
                }
            }

            .icon {
                display: inline-flex;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: $purple-ultralight;
                color: $purple-medium;
                margin-right: 8px;
                font-size: 14px;
            }
        }
    }

    .group-actions {
        .action-item {
            border: 2px solid $grey-border;
            display: flex;
            position: relative;

            @include mobile {
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &.new {
                background-color: $purple-ultralight;
            }

            &-discount {
                position: absolute;
                top: 10px;
                left: 10px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: $purple-medium;
                color: #fff;
                font-size: 16px;
                line-height: 1.44;
                font-weight: 400;
                font-family: $title-font;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-photo {
                min-height: 115px;
                flex-shrink: 0;
                width: 160px;

                @include mobile {
                    width: 100%;
                    min-height: 50px;
                }

                // &:empty {
                //     min-height: initial;
                // }
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-info {
                padding: 10px 15px;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 14px;
                line-height: 1.57;

                @include mobile {
                    padding: 16px 16px 24px;
                }
            }

            &-date {
                color: $grey-text;
            }

            &-label {
                margin-left: 4px;
                color: $purple-medium;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 3px;
                    background-color: currentColor;
                    flex-shrink: 0;
                }
            }

            &-title {
                width: 100%;
            }

            &-time-left {
                margin-top: 6px;
                padding: 0 6px;
                font-size: 12px;
                line-height: 22px;
                color: #fff;
                background-color: $purple-medium;
            }
        }
    }

    .group-products-updates {
        .new-item {
            border: 2px solid $grey-border;
            display: flex;
            position: relative;

            @include mobile {
                flex-direction: column;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &.new {
                background-color: $purple-ultralight;
            }

            &-label {
                margin-left: 4px;
                color: $purple-medium;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 3px;
                    background-color: currentColor;
                    flex-shrink: 0;
                }
            }

            &-photo {
                min-height: 115px;
                flex-shrink: 0;
                width: 160px;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-info {
                padding: 10px 15px;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 14px;
                line-height: 1.57;
            }

            &-date {
                color: $grey-text;
            }

            &-title {
                width: 100%;
            }

            &-tags {
                margin-top: 4px;
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }

            &-tag {
                color: $purple;

                &::before {
                    content: '# ';
                }
            }
        }
    }

    .group-sales-month-history {
        .content-block-list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @include mobile {
                flex-direction: column-reverse;
                align-items: center;
            }
        }

        .dropdown {
            @include mobile {
                display: none;
            }

            button {
                border: 0;
                padding: 0;
                background-color: transparent;
                color: $purple;
                position: relative;
                border-bottom: 2px dotted currentColor;

                &::after {
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 3.5px 0 3.5px;
                    border-color: currentColor transparent transparent transparent;
                    position: absolute;
                    left: calc(100% + 4px);
                    top: calc(50% - 3px);
                }
            }
        }

        .dropdown-item {
            &.is-active {
                background-color: $purple-light;
                color: $purple;
            }
        }

        .sales-history-table {
            width: 100%;
            max-width: 760px;

            @include mobile {
                margin-top: 8px;
            }

            thead {
                @include mobile {
                    display: none;
                }
            }

            tr {
                @include mobile {
                    padding-top: 24px;
                    padding-bottom: 24px;
                    display: flex;
                    flex-wrap: wrap;
                }

                &:not(:last-child) {
                    padding-bottom: 8px;

                    @include mobile {
                        padding-bottom: 24px;
                        border-bottom: 1.5px solid $grey-border;
                    }

                    td {
                        @include mobile {
                            padding-bottom: 0;
                            border: 0;
                        }
                    }
                }
            }

            th,
            td {
                &:first-child {
                    padding-left: 10px;

                    @include mobile {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    width: 100px;

                    @include mobile {
                        width: calc(100% / 3);
                    }
                }
            }

            td {
                padding-top: 8px;

                @include mobile {
                    padding: 0;
                    width: calc(100% / 3);
                }

                &:first-child {
                    @include mobile {
                        width: 100%;
                        margin-bottom: 12px;
                    }
                }

                &:last-child {
                    color: $orange;
                }
            }
        }

        // .sales-history-chart {
        .chart-group {
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            width: 200px;
            // flex-shrink: 0;
        }

        // .history-chart {
        .chart-item {
            // position: relative;
            max-width: 190px;
        }

        // .chart-total {
        //     font-family: $title-font;
        //     font-size: 35px;
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        //     color: $orange;
        // }
        // .chart-annotation {
        //     margin-top: 8px;
        //     display: flex;
        //     align-items: center;
        //     font-size: 12px;
        //     line-height: 17px;
        //     color: rgba(34,34,34,.3);
        //     &::before {
        //         display: block;
        //         content: '';
        //         width: 6px;
        //         height: 6px;
        //         border-radius: 50%;
        //         background-color: #FAD87F;
        //         margin-right: 5px;
        //         flex-shrink: 0;
        //     }
        // }
    }

    .group-partnership-terms {
        .partnership-table {
            color: $grey-text;

            thead {
                @include mobile {
                    display: none;
                }
            }

            tr {
                @include mobile {
                    display: flex;
                    flex-wrap: wrap;
                }

                &:not(:first-child) {
                    @include mobile {
                        padding-top: 24px;
                    }
                }

                &:not(:last-child) {
                    padding-bottom: 8px;

                    @include mobile {
                        padding-bottom: 24px;
                        border-bottom: 1.5px solid $grey-border;
                    }

                    td {
                        @include mobile {
                            padding-bottom: 0;
                            border-bottom: 0;
                        }
                    }
                }
            }

            th,
            td {
                &:last-child {
                    width: 150px;

                    @include mobile {
                        width: initial;
                    }
                }
            }

            td {
                padding-top: 8px;
                vertical-align: top;

                @include mobile {
                    padding: 0;
                    border: 0;
                }

                &:nth-child(1) {
                    @include mobile {
                        width: 100%;
                        margin-bottom: 16px;
                        order: 1;
                    }

                    &:empty {
                        @include mobile {
                            display: none;
                        }
                    }
                }

                &:nth-child(2) {
                    @include mobile {
                        width: 44%;
                        order: 2;
                        padding-right: 8px;
                    }
                }

                &:nth-child(3) {
                    @include mobile {
                        // width: 20%;
                        width: 36%;
                        order: 4;
                    }
                }

                &:nth-child(4) {
                    @include mobile {
                        // width: 36%;
                        width: 20%;
                        order: 3;
                    }
                }
            }

            .cell-title {
                @include mobile {
                    margin-bottom: 12px;
                }
            }

            .cell-item {
                &:not(:last-child) {
                    @include mobile {
                        margin-bottom: 6px;
                    }
                }

                @include mobile {
                    color: $black;
                }

                span {

                    @include mobile {
                        color: $grey-text;
                    }
                }
            }
        }

        .grey {
            text-decoration: line-through;
        }

        .orange {
            color: $orange !important;
        }

        .red {
            color: $warning !important;
        }
    }

    .group-news-cards {
        .content-block-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px 10px;

            @include touch {
                grid-template-columns: 1fr 1fr;
            }

            @include maxw639 {
                grid-template-columns: 1fr;
                grid-gap: 16px;
            }
        }

        .news-card {
            // position: relative;
            border: 1px solid $grey-border;
            // overflow: hidden;
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 340px;

            // &:hover {
            //     .news-card-info {
            //         top: 0;
            //         max-height: 400px;
            //     }
            //     .news-card-title {
            //         -webkit-line-clamp: initial;
            //     }
            //     .news-card-descr {
            //         opacity: 1;
            //     }
            // }
            &.new {
                .news-card-info {
                    background-color: $purple-ultralight;
                }
            }

            &-photo {
                // position: relative;
                // z-index: 1;
                // padding-bottom: 130px;
                padding-bottom: 0;

                img {
                    width: 100%;
                    position: relative;
                }
            }

            &-info {
                // position: absolute;
                // top: calc(100% - 130px);
                // background-color: $purple-ultralight;
                // left: 0;
                // right: 0;
                // bottom: 0;
                // overflow: hidden;
                // transition: top $duration, max-height $duration;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: flex-start;
                // max-height: 130px;
                min-height: initial;
                background-color: #fff;
                z-index: 2;
                flex-grow: 1;

                &:only-child {

                    // position: relative;
                    // top: 0;
                    // max-height: initial;
                    .news-card-title {
                        -webkit-line-clamp: initial;
                        font-size: 18px;
                        line-height: 1.5;
                    }

                    // .news-card-descr {
                    //     opacity: 1;
                    // }
                }

                // + .news-card-info {
                //     padding-bottom: 130px;
                // }
            }

            &-date {
                color: $grey-text;
            }

            &-label {
                margin-left: 4px;
                color: $purple-medium;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 3px;
                    background-color: currentColor;
                    flex-shrink: 0;
                }
            }

            &-title {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                width: 100%;
                // font-size: 14px;
                // line-height: 1.57;
                font-size: 18px;
                line-height: 1.5;
            }

            &-descr {
                margin-top: 8px;
                width: 100%;
                // opacity: 0;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.57;
                font-weight: 400;
                color: $grey-text;
            }
        }
    }

    .group-promo-actions {
        .content-block-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px 10px;

            @include touch {
                grid-template-columns: 1fr 1fr;
            }

            @include maxw639 {
                grid-template-columns: 1fr;
                grid-gap: 16px;
            }
        }

        .action-item {
            border: 2px solid $grey-border;
            position: relative;

            &.new {
                background-color: $purple-ultralight;
            }

            &-discount {
                position: absolute;
                top: 22px;
                left: 22px;
                width: 76px;
                height: 76px;
                border-radius: 50%;
                background-color: $purple-medium;
                color: #fff;
                font-size: 25px;
                line-height: 1.44;
                font-weight: 400;
                font-family: $title-font;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobile {
                    top: 8px;
                    left: 8px;
                    width: 52px;
                    height: 52px;
                    font-size: 16px;
                }

                +.action-item-info {
                    padding-top: 115px;

                    @include mobile {
                        padding-top: 68px;
                    }
                }
            }

            &-photo {
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }

            &-info {
                padding: 10px 15px;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 14px;
                line-height: 1.57;
            }

            &-date {
                color: $grey-text;
            }

            &-label {
                margin-left: 4px;
                color: $purple-medium;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 3px;
                    background-color: currentColor;
                    flex-shrink: 0;
                }
            }

            &-title {
                width: 100%;
            }

            &-time-left {
                margin-top: 8px;
                padding: 0 6px;
                font-size: 12px;
                line-height: 22px;
                color: #fff;
                background-color: $purple-medium;
            }
        }
    }

    .group-promo-news {
        .content-block-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px 10px;

            @include touch {
                grid-template-columns: 1fr 1fr;
            }

            @include maxw639 {
                grid-template-columns: 1fr;
                grid-gap: 16px;
            }
        }

        .new-item {
            border: 2px solid $grey-border;

            // position: relative;
            &-photo {
                // min-height: 115px;
                // flex-shrink: 0;
                // width: 160px;
                width: 100%;
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &.new {
                background-color: $purple-ultralight;
            }

            &-label {
                margin-left: 4px;
                color: $purple-medium;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 3px;
                    background-color: currentColor;
                    flex-shrink: 0;
                }
            }

            &-info {
                padding: 10px 15px;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-size: 14px;
                line-height: 1.57;
            }

            &-date {
                color: $grey-text;
            }

            &-title {
                width: 100%;
            }

            &-tags {
                margin-top: 4px;
                display: flex;
                flex-wrap: wrap;
                gap: 4px 10px;
            }

            &-tag {
                color: $purple;

                &::before {
                    content: '# ';
                }
            }
        }
    }

    .group-finance {
        max-width: 610px;

        @include mobile {
            max-width: 100%;
        }

        .content-block-head {
            @include mobile {
                display: block;
            }
        }

        .debt-current-ballance {
            font-family: $title-font;
            font-size: 18px;
            line-height: 1.78;

            span {
                color: $warning;
            }
        }

        .calendar-dropdown {
            right: 0;
            left: -6px;

            @include maxw1200 {
                left: -218px;
            }

            @include touch {
                left: initial;
            }

            &::before {
                right: initial;
                left: 200px;
            }
        }
    }

    .group-finance-debt {
        min-height: 150px;

        @include mobile {
            min-height: initial;
        }

        .debt-current-ballance {
            font-family: $title-font;
            font-size: 18px;
            line-height: 1.78;

            span {
                color: $warning;
            }
        }
    }

    .group-finance-period {
        @include mobile {
            margin-top: 16px;
            border-top: 2px solid $grey-border;
            padding-top: 16px;
        }

        .period-title {
            font-family: $title-font;
            font-size: 18px;
            line-height: 1.78;

            span {
                color: $warning;
            }
        }

        .selector-item {
            margin-top: 10px;

            @include mobile {
                margin-top: 24px;
                display: block;
            }

            // display: flex;
            // align-items: center;
            &-label {
                @include mobile {
                    display: none;
                }

                // font-size: 14px;
                // margin-right: 8px;
            }

            // &-inner {
            //     position: relative;
            // }
        }

        .request-btn {
            margin-top: 20px;
            display: inline-flex;
            min-width: 160px;
            padding: 0 20px;
            height: 44px;
            border: 2px solid currentColor;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            color: $purple;

            @include mobile {
                width: 100%;
            }
        }
    }

    .group-catalog-price {
        background-color: transparent;
        padding: 0;

        .content-block-head {
            padding: 20px;
            background-color: #fff;
        }

        .content-block-list {
            padding: 20px;
            background-color: #fff;

            @include mobile {
                padding: 0;
            }

            &:not(:first-child) {
                margin-top: 9px;

                @include mobile {
                    margin-top: 24px;
                }
            }
        }

        .content-tab-selectors {
            display: flex;
        }

        .content-tab-selector {
            color: $grey-text;
            position: relative;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 24px;
            }

            &.is-active {
                color: $dark;
                cursor: default;

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    right: 0;
                    height: 2px;
                    background-color: currentColor;
                }
            }
        }

        .content-tab {
            display: none;

            &.is-open {
                display: block;
            }
        }

        .brand-item {
            border: 1px solid $grey-border;
            padding: 15px;
            background-color: #fff;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &.is-open {
                .brand-item-head {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }

                .brand-item-body {
                    display: block;
                }
            }

            &-head {
                cursor: pointer;
                position: relative;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943344 2.25903e-07L4 3.09171L7.05666 3.0199e-08L8 0.95413L4 5L3.01121e-08 0.95413L0.943344 2.25903e-07Z' fill='%23222222'/%3E%3C/svg%3E%0A");
                    width: 8px;
                    height: 5px;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 2.5px);
                }
            }

            &-body {
                margin-top: 16px;
                display: none;
            }

            &-table {
                width: 100%;

                thead {
                    @include mobile {
                        display: none;
                    }
                }

                tr {
                    @include mobile {
                        display: block;
                    }

                    &:not(:last-child) {}
                }

                th,
                td {
                    width: 25%;
                    vertical-align: top;

                    @include mobile {
                        width: 100%;

                    }

                    &:not(:last-child) {
                        padding-right: 16px;

                        @include mobile {
                            padding-right: 0;
                        }
                    }
                }

                td {
                    @include mobile {
                        padding-top: 0;
                        display: block;
                    }

                    &:not(:last-child) {
                        @include mobile {
                            margin-bottom: 16px;
                        }
                    }
                }

            }

            .product {
                display: flex;
                align-items: center;

                &-logo {
                    width: 37px;
                    height: 37px;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 1px solid $grey-border;
                    margin-right: 10px;
                    flex-shrink: 0;

                    // @include mobile {
                    //     width: 52px;
                    //     height: 52px;
                    //     margin-right: 12px;
                    // }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                // &-title {
                //     font-family: $title-font;
                //     font-size: 18px;
                //     line-height: 1.5;

                //     @include mobile {
                //         font-size: 20px;
                //         line-height: 1.3;
                //         color: $purple;
                //     }
                // }
            }


            .brand-docs {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                line-height: 1.57;

                a {
                    color: $purple;

                    &:hover {
                        text-decoration: underline;
                    }

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .docs-list {
            list-style: none;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px 30px;

            a {
                line-height: 1.57;
                color: $purple;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .group-orders {
        .history-order {
            border: 1px solid $grey-border;
            padding: 15px;
            background-color: #fff;

            @include mobile {
                padding: 16px;
                border-width: 1.5px;
            }

            &:not(:last-child) {
                margin-bottom: 8px;

                @include mobile {
                    margin-bottom: 16px;
                }
            }

            &.is-open {
                @include mobile {
                    padding: 16px 16px 24px;
                }

                .history-order-head {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }

                .history-order-body {
                    display: block;
                }
            }

            &.processed {
                .history-order-status {
                    color: $orange-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0001 10.8184C3.89056 10.8184 2.18192 9.10978 2.18192 7.00024C2.18192 6.35434 2.34419 5.74978 2.6242 5.21523L1.6951 4.28613C1.2019 5.07521 0.90918 6.00112 0.90918 7.00024C0.90918 9.81295 3.18735 12.0912 6.0001 12.0912V14.0002L8.54555 11.4548L6.0001 8.90933V10.8184Z' fill='%23FFA825'/%3E%3Cpath d='M6.00004 1.90909V0L3.45459 2.54545L6.00004 5.09089V3.1818C8.10958 3.1818 9.81822 4.89044 9.81822 6.99999C9.81822 7.64588 9.65595 8.25044 9.37594 8.78499L10.305 9.71409C10.7982 8.92501 11.091 7.9991 11.091 6.99999C11.0909 4.18727 8.81276 1.90909 6.00004 1.90909Z' fill='%23FFA825'/%3E%3C/svg%3E%0A");
                        width: 12px;
                        height: 14px;
                    }
                }
            }

            &.shipped {
                .history-order-status {
                    color: $blue-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3636 2.18182H8.72727V0H1.09091C0.490909 0 0 0.490909 0 1.09091V7.09091H1.09091C1.09091 7.99636 1.82182 8.72727 2.72727 8.72727C3.63273 8.72727 4.36364 7.99636 4.36364 7.09091H7.63636C7.63636 7.99636 8.36727 8.72727 9.27273 8.72727C10.1782 8.72727 10.9091 7.99636 10.9091 7.09091H12V4.36364L10.3636 2.18182ZM10.0909 3L11.16 4.36364H8.72727V3H10.0909ZM2.72727 7.63636C2.42727 7.63636 2.18182 7.39091 2.18182 7.09091C2.18182 6.79091 2.42727 6.54545 2.72727 6.54545C3.02727 6.54545 3.27273 6.79091 3.27273 7.09091C3.27273 7.39091 3.02727 7.63636 2.72727 7.63636ZM3.93818 6C3.63818 5.66727 3.21273 5.45455 2.72727 5.45455C2.24182 5.45455 1.81636 5.66727 1.51636 6H1.09091V1.09091H7.63636V6H3.93818ZM9.27273 7.63636C8.97273 7.63636 8.72727 7.39091 8.72727 7.09091C8.72727 6.79091 8.97273 6.54545 9.27273 6.54545C9.57273 6.54545 9.81818 6.79091 9.81818 7.09091C9.81818 7.39091 9.57273 7.63636 9.27273 7.63636Z' fill='%235F88FF'/%3E%3C/svg%3E%0A");
                        width: 12px;
                        height: 9px;
                    }
                }
            }

            &.received {
                .history-order-status {
                    color: $green-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.53535 8.53535C10.4882 6.58249 10.4882 3.41751 8.53535 1.46465C6.58249 -0.488216 3.41751 -0.488216 1.46465 1.46465C-0.488216 3.41751 -0.488216 6.58249 1.46465 8.53535C3.41751 10.4882 6.58453 10.4882 8.53535 8.53535ZM3.20733 4.23579L4.3317 5.36017L6.79472 2.89919L7.66605 3.77053L5.20508 6.23151L4.3317 7.10285L3.46036 6.23151L2.33599 5.10713L3.20733 4.23579Z' fill='%235AE157'/%3E%3C/svg%3E%0A");
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            &.cancelled {
                .history-order-status {
                    color: $red-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.46353 1.46353C-0.487842 3.4149 -0.487842 6.5851 1.46353 8.53647C3.4149 10.4878 6.5851 10.4878 8.53647 8.53647C10.4878 6.5851 10.4878 3.4149 8.53647 1.46353C6.5851 -0.487842 3.42105 -0.487842 1.46353 1.46353ZM6.56048 7.58849L5.00308 6.03109L3.44568 7.58849L2.41767 6.56048L3.97507 5.00308L2.41767 3.45183L3.44568 2.42382L5.00308 3.98122L6.56048 2.42382L7.58849 3.45183L6.03109 5.00923L7.58849 6.56664L6.56048 7.58849Z' fill='%23FF8C8C'/%3E%3C/svg%3E%0A");
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            &-head {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 28px;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943344 2.25903e-07L4 3.09171L7.05666 3.0199e-08L8 0.95413L4 5L3.01121e-08 0.95413L0.943344 2.25903e-07Z' fill='%23222222'/%3E%3C/svg%3E%0A");
                    width: 8px;
                    height: 5px;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 2.5px);
                }
            }

            &-title {
                font-family: $title-font;
                font-size: 18px;
                line-height: 1.5;

                @include mobile {
                    font-size: 14px;
                    line-height: 1.57;
                }

                span {
                    color: $grey;
                    margin-left: 4px;

                    @include mobile {
                        display: inline-block;
                        margin: 0;
                    }
                }
            }

            &-status {
                font-size: 12px;
                line-height: 22px;
                display: flex;
                align-items: center;

                @include mobile {
                    margin-left: 16px;
                    line-height: 3.11;
                }

                &::before {
                    display: block;
                    content: '';
                    margin-right: 4px;
                    position: relative;
                    top: -2px;
                }

                // &.processed {
                //     color: $orange-2;
                //     &::before {
                //         background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0001 10.8184C3.89056 10.8184 2.18192 9.10978 2.18192 7.00024C2.18192 6.35434 2.34419 5.74978 2.6242 5.21523L1.6951 4.28613C1.2019 5.07521 0.90918 6.00112 0.90918 7.00024C0.90918 9.81295 3.18735 12.0912 6.0001 12.0912V14.0002L8.54555 11.4548L6.0001 8.90933V10.8184Z' fill='%23FFA825'/%3E%3Cpath d='M6.00004 1.90909V0L3.45459 2.54545L6.00004 5.09089V3.1818C8.10958 3.1818 9.81822 4.89044 9.81822 6.99999C9.81822 7.64588 9.65595 8.25044 9.37594 8.78499L10.305 9.71409C10.7982 8.92501 11.091 7.9991 11.091 6.99999C11.0909 4.18727 8.81276 1.90909 6.00004 1.90909Z' fill='%23FFA825'/%3E%3C/svg%3E%0A");
                //         width: 12px;
                //         height: 14px;
                //     }
                // }
                // &.shipped {
                //     color: $blue-2;
                //     &::before {
                //         background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3636 2.18182H8.72727V0H1.09091C0.490909 0 0 0.490909 0 1.09091V7.09091H1.09091C1.09091 7.99636 1.82182 8.72727 2.72727 8.72727C3.63273 8.72727 4.36364 7.99636 4.36364 7.09091H7.63636C7.63636 7.99636 8.36727 8.72727 9.27273 8.72727C10.1782 8.72727 10.9091 7.99636 10.9091 7.09091H12V4.36364L10.3636 2.18182ZM10.0909 3L11.16 4.36364H8.72727V3H10.0909ZM2.72727 7.63636C2.42727 7.63636 2.18182 7.39091 2.18182 7.09091C2.18182 6.79091 2.42727 6.54545 2.72727 6.54545C3.02727 6.54545 3.27273 6.79091 3.27273 7.09091C3.27273 7.39091 3.02727 7.63636 2.72727 7.63636ZM3.93818 6C3.63818 5.66727 3.21273 5.45455 2.72727 5.45455C2.24182 5.45455 1.81636 5.66727 1.51636 6H1.09091V1.09091H7.63636V6H3.93818ZM9.27273 7.63636C8.97273 7.63636 8.72727 7.39091 8.72727 7.09091C8.72727 6.79091 8.97273 6.54545 9.27273 6.54545C9.57273 6.54545 9.81818 6.79091 9.81818 7.09091C9.81818 7.39091 9.57273 7.63636 9.27273 7.63636Z' fill='%235F88FF'/%3E%3C/svg%3E%0A");
                //         width: 12px;
                //         height: 9px;
                //     }
                // }
                // &.received {
                //     color: $green-2;
                //     &::before {
                //         background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.53535 8.53535C10.4882 6.58249 10.4882 3.41751 8.53535 1.46465C6.58249 -0.488216 3.41751 -0.488216 1.46465 1.46465C-0.488216 3.41751 -0.488216 6.58249 1.46465 8.53535C3.41751 10.4882 6.58453 10.4882 8.53535 8.53535ZM3.20733 4.23579L4.3317 5.36017L6.79472 2.89919L7.66605 3.77053L5.20508 6.23151L4.3317 7.10285L3.46036 6.23151L2.33599 5.10713L3.20733 4.23579Z' fill='%235AE157'/%3E%3C/svg%3E%0A");
                //         width: 10px;
                //         height: 10px;
                //     }
                // }
                // &.cancelled {
                //     color: $blue-2;
                //     &::before {
                //         background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.46353 1.46353C-0.487842 3.4149 -0.487842 6.5851 1.46353 8.53647C3.4149 10.4878 6.5851 10.4878 8.53647 8.53647C10.4878 6.5851 10.4878 3.4149 8.53647 1.46353C6.5851 -0.487842 3.42105 -0.487842 1.46353 1.46353ZM6.56048 7.58849L5.00308 6.03109L3.44568 7.58849L2.41767 6.56048L3.97507 5.00308L2.41767 3.45183L3.44568 2.42382L5.00308 3.98122L6.56048 2.42382L7.58849 3.45183L6.03109 5.00923L7.58849 6.56664L6.56048 7.58849Z' fill='%23FF8C8C'/%3E%3C/svg%3E%0A");
                //         width: 10px;
                //         height: 10px;
                //     }
                // }
            }

            &-body {
                margin-top: 16px;
                display: none;

                @include mobile {
                    margin-top: 8px;
                    border-top: 2px solid $grey-border;
                }
            }

            &-table {
                width: 100%;

                thead {
                    @include mobile {
                        display: none;
                    }
                }

                tr {
                    @include mobile {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 12px 0;
                        border-bottom: 2px solid $grey-border;
                    }

                    &:not(:last-child) {
                        td {
                            border-bottom: 1px solid $grey-border;

                            @include mobile {
                                border: 0;
                                padding: 0;
                            }
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: 2px solid $grey-border;

                            @include mobile {
                                border: 0;
                            }
                        }
                    }
                }

                th,
                td {
                    // width: 25%;
                    vertical-align: top;

                    &:first-child {
                        padding-right: 14px;

                        @include mobile {
                            padding-right: 0;
                        }
                    }

                    &:not(:last-child) {
                        padding-right: 16px;

                        @include mobile {
                            padding-right: 0;
                        }
                    }

                    &:last-child {
                        width: 74px;

                        @include mobile {
                            width: initial;
                        }

                        &:nth-child(5) {
                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }

                // th {
                //     // &:not(:last-child) {
                //     //     padding-right: 16px;
                //     // }
                // }

                td {
                    padding-top: 8px;
                    padding-bottom: 8px;

                    @include mobile {
                        padding: 0;
                        width: calc(100% / 3);
                    }

                    &:nth-child(1) {
                        @include mobile {
                            margin-bottom: 16px;
                            width: 100%;
                        }
                    }

                    // &:not(:last-child) {
                    //     padding-right: 16px;
                    // }
                }
            }

            .product {
                display: flex;
                align-items: flex-start;
                max-width: 370px;

                @include mobile {
                    max-width: 100%;
                    align-items: center;
                }

                &-photo {
                    width: 50px;
                    margin-right: 8px;
                    flex-shrink: 0;

                    @include mobile {
                        width: 56px;
                    }
                }

                &-info {
                    // padding-top: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                &-category {
                    color: $grey-text;
                    font-size: 12px;
                    line-height: 18px;

                    @include mobile {
                        line-height: 1.67;
                    }
                }

                &-title {
                    font-size: 14px;
                    line-height: 1.57;

                    @include mobile {
                        margin-top: 4px;
                        color: $black;
                    }
                }
            }

            .color-dealer {
                color: $orange;
            }

            &-summary {
                margin-top: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                @include mobile {
                    margin-top: 8px;
                }
            }

            &-delivery-payment {
                width: calc(40% - 15px);
                max-width: 380px;
                padding: 10px;
                background-color: $grey-bg;
                font-size: 14px;
                line-height: 1.5;

                @include mobile {
                    display: none;
                }

                >div {
                    div:nth-child(2) {
                        color: $grey;
                    }
                }
            }

            .payment-group {
                margin-top: 10px;
            }

            &-total {
                width: calc(60% - 15px);
                max-width: 590px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include maxw1366 {
                    flex-direction: column;
                    align-items: flex-end;
                }

                @include mobile {
                    width: 100%;
                    max-width: 100%;
                    // flex-direction: column;
                    align-items: flex-start;
                }
            }

            .total-item {
                font-size: 14px;
                display: flex;
                align-items: baseline;

                @include mobile {
                    line-height: 28px;
                }

                &:not(:last-child) {
                    margin-right: 15px;

                    @include maxw1366 {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }

                    // @include mobile {
                    //     margin-right: 0;
                    //     margin-bottom: 8px;
                    // }
                }

                &.summ-regular {
                    color: $grey-text;
                }

                &.summ-dealer {
                    span {
                        color: $orange;

                        @include mobile {
                            font-size: 16px;
                        }
                    }
                }

                span {
                    font-size: 16px;
                    margin-left: 4px;

                    @include mobile {
                        font-size: 14px;
                    }
                }
            }

            .repeat-checkout-btn {
                height: 44px;
                border: 0;
                background-color: $purple;
                color: #fff;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                &.is-loading {
                    pointer-events: none;
                    position: relative;
                    pointer-events: none;
                    color: transparent;

                    &:after {
                        @include loader;
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        border-width: 0.15em;
                    }
                }

                @include mobile {
                    margin-top: 8px;
                    width: 100%;
                }
            }
        }
    }

    .group-proposal {
        .proposal-order {
            border: 1px solid $grey-border;
            padding: 15px;
            background-color: #fff;

            @include mobile {
                padding: 16px;
                border-width: 1.5px;
            }

            &:not(:last-child) {
                margin-bottom: 8px;

                @include mobile {
                    margin-bottom: 16px;
                }
            }

            &.is-open {
                @include mobile {
                    padding: 16px 16px 24px;
                }

                .proposal-order-head {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }

                .proposal-order-body {
                    display: block;
                }
            }

            &-head {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 28px;

                &::after {
                    display: block;
                    content: '';
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.943344 2.25903e-07L4 3.09171L7.05666 3.0199e-08L8 0.95413L4 5L3.01121e-08 0.95413L0.943344 2.25903e-07Z' fill='%23222222'/%3E%3C/svg%3E%0A");
                    width: 8px;
                    height: 5px;
                    position: absolute;
                    right: 0;
                    top: calc(50% - 2.5px);
                }
            }

            &-title {
                font-family: $title-font;
                font-size: 18px;
                line-height: 1.5;

                @include mobile {
                    font-size: 14px;
                    line-height: 1.57;
                }

                span {
                    color: $grey;
                    margin-left: 4px;

                    @include mobile {
                        display: block;
                        margin: 0;
                    }
                }

                [currency-symbol] {
                    display: inline;
                }
            }

            &-body {
                margin-top: 16px;
                display: none;

                @include mobile {
                    margin-top: 8px;
                    border-top: 2px solid $grey-border;
                }
            }

            &-table {
                width: 100%;

                thead {
                    @include mobile {
                        display: none;
                    }
                }

                tr {
                    @include mobile {
                        display: flex;
                        flex-wrap: wrap;
                        padding: 12px 0;
                        border-bottom: 2px solid $grey-border;
                    }

                    &:not(:last-child) {
                        td {
                            border-bottom: 1px solid $grey-border;

                            @include mobile {
                                border: 0;
                                padding: 0;
                            }
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: 2px solid $grey-border;

                            @include mobile {
                                border: 0;
                            }
                        }
                    }
                }

                th,
                td {
                    // width: 25%;
                    vertical-align: top;

                    &:first-child {
                        padding-right: 14px;

                        @include mobile {
                            padding-right: 0;
                        }
                    }

                    &:not(:last-child) {
                        padding-right: 16px;

                        @include mobile {
                            padding-right: 0;
                        }
                    }

                    &:last-child {
                        width: 74px;

                        @include mobile {
                            width: initial;
                        }

                        &:nth-child(5) {
                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }

                // th {
                //     // &:not(:last-child) {
                //     //     padding-right: 16px;
                //     // }
                // }

                td {
                    padding-top: 8px;
                    padding-bottom: 8px;

                    @include mobile {
                        padding: 0;
                        width: calc(100% / 3);
                    }

                    &:nth-child(1) {
                        @include mobile {
                            margin-bottom: 16px;
                            width: 100%;
                        }
                    }

                    // &:not(:last-child) {
                    //     padding-right: 16px;
                    // }
                }
            }

            .product {
                display: flex;
                align-items: flex-start;
                max-width: 370px;

                @include mobile {
                    max-width: 100%;
                    align-items: center;
                }

                &-photo {
                    width: 50px;
                    margin-right: 8px;
                    flex-shrink: 0;

                    @include mobile {
                        width: 56px;
                    }
                }

                &-info {
                    // padding-top: 4px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                &-category {
                    color: $grey-text;
                    font-size: 12px;
                    line-height: 18px;

                    @include mobile {
                        line-height: 1.67;
                    }
                }

                &-title {
                    font-size: 14px;
                    line-height: 1.57;

                    @include mobile {
                        margin-top: 4px;
                        color: $black;
                    }
                }

                &-remove {
                    margin-top: 3px;
                    font-size: 12px;
                    line-height: 28px;
                    color: rgba($black, .3);
                }
            }

            // .color-dealer {
            //     color: $orange;
            // }

            &-summary {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                // align-items: flex-start;
                // justify-content: space-between;

                @include mobile {
                    margin-top: 8px;
                }
            }

            // .payment-group {
            //     margin-top: 10px;
            // }

            &-total {
                // width: calc(60% - 15px);
                // max-width: 590px;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                // @include maxw1366 {
                //     flex-direction: column;
                //     align-items: flex-end;
                // }
                // @include mobile {
                //     width: 100%;
                //     max-width: 100%;
                //     // flex-direction: column;
                //     align-items: flex-start;
                // }
            }

            .total-item {
                font-size: 14px;
                line-height: 28px;
                display: flex;
                align-items: baseline;

                // @include mobile {
                //     line-height: 28px;
                // }

                &:not(:last-child) {
                    // margin-right: 15px;
                    margin-right: 30px;

                    // @include maxw1366 {
                    //     margin-right: 0;
                    //     margin-bottom: 8px;
                    // }
                    // @include mobile {
                    //     margin-right: 0;
                    //     margin-bottom: 8px;
                    // }
                }

                // &.summ-regular {
                //     color: $grey-text;
                // }

                // &.summ-dealer {
                //     span {
                //         color: $orange;

                //         @include mobile {
                //             font-size: 16px;
                //         }
                //     }
                // }

                span {
                    // font-size: 16px;
                    margin-left: 4px;

                    // @include mobile {
                    //     font-size: 14px;
                    // }
                }
            }

            &-controls {
                margin-top: 20px;
                display: flex;
                width: 100%;
                justify-content: space-between;

                @include mobile {
                    flex-direction: column;
                }
            }

            &-remove,
            &-edit,
            &-mailsend,
            &-checkout {
                height: 44px;
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
            }

            &-remove {
                background-color: transparent;
                color: $grey-text;
                border: 2px solid $grey-border;
                margin-right: 10px;

                @include mobile {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            &-edit {
                color: $grey-text;
                border: 2px solid $grey-border;
                background-color: transparent;
                margin-right: auto;

                @include mobile {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            &-mailsend {
                color: $purple;
                border: 2px solid $purple;
                margin-right: 10px;
                background-color: transparent;

                @include mobile {
                    margin-right: 0;
                    margin-bottom: 10px;
                }
            }

            &-checkout {
                background-color: $purple;
                color: #fff;
                border: 0;
            }
        }
    }

    .group-user-info {
        max-width: 780px;

        form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px 10px;

            @include mobile {
                grid-template-columns: 1fr;
                grid-gap: 8px;
            }

            .password-eye {
                width: 1.5em !important;
            }
        }

        .schema-row {

            &:nth-child(1),
            // &:nth-child(6) {
            &:nth-child(5) {
                grid-column: span 2;
                margin-bottom: 0;

                @include mobile {
                    grid-column: initial;
                }
            }

            &:not(:first-child) {
                .fields-section {
                    margin-top: 0;
                }
            }
        }

        hr {
            display: none;
        }

        .columns {
            margin: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .button[type="submit"] {
            margin-top: 4px;
            width: initial;
            background-color: $purple;

            @include mobile {
                width: 100%;
                margin-top: 8px;
            }
        }
    }

    .group-user-addresses {
        max-width: 780px;

        .addresses-list {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;

            @include mobile {
                grid-template-columns: 1fr;
                grid-gap: 12px;
            }
        }

        .address-item {
            min-height: 160px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 1.5px solid $grey-border;
            padding: 15px 16px;
            transition: background-color .5s;

            @include mobile {
                padding: 10px 16px;
                min-height: 144px;
            }

            &.is-main {
                background-color: rgba($orange-2, .1);
            }

            &-head {
                color: $grey-text;
                line-height: 1.57;
            }

            .main-label {
                color: $orange;
            }

            &-value {
                margin-top: 4px;
                font-size: 15px;
                line-height: 1.47;
                margin-bottom: auto;
                width: 100%;

                @include mobile {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 1.57;
                }
            }

            &-controls {
                margin-top: 26px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 8px 15px;
                font-size: 12px;
                line-height: 15px;
                color: $grey-text;

                @include mobile {
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 1.4;
                }

                a {
                    &::first-letter {
                        @include mobile {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }

    .group-user-companies {
        max-width: 780px;

        .companies-list {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;

            @include mobile {
                grid-template-columns: 1fr;
                grid-gap: 0;
            }
        }

        .company-item {
            min-height: 160px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 1.5px solid $grey-border;
            padding: 15px 16px;
            position: relative;

            @include mobile {
                border: 0;
                min-height: initial;
                padding: 0;
                flex-direction: row;
            }

            &:not(:first-child) {
                @include mobile {
                    padding-top: 8px;
                }
            }

            &:not(:last-child) {
                @include mobile {
                    padding-bottom: 8px;
                    border-bottom: 1.5px solid $grey-border;
                }
            }

            &-parameter {
                font-size: 15px;
                line-height: 1.47;

                @include mobile {
                    font-size: 14px;
                    line-height: 1.4;
                    width: 50%;
                }

                &:not(:first-child) {
                    margin-top: 10px;

                    @include mobile {
                        margin-top: 0;
                    }
                }

                span {
                    font-size: 14px;
                    display: block;
                    line-height: 1.57;
                    color: $grey-text;

                    @include mobile {
                        font-size: 12px;
                        line-height: 1.83;
                        font-weight: 400;
                        text-transform: uppercase;
                        letter-spacing: 0.01em;
                    }
                }
            }

            &-controls {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 8px 15px;
                font-size: 12px;
                line-height: 15px;
                color: $grey-text;

                @include mobile {
                    margin: 0;
                    gap: initial;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    // flex-direction: row-reverse;
                    align-items: center;
                    justify-content: space-between;
                    z-index: 2;
                    transition: $duration;
                }

                &.is-visible-mobile {
                    @include mobile {
                        left: 0;
                        background-color: #fff;

                    }

                    a {
                        @include mobile {
                            display: initial;
                        }
                    }
                }

                a {
                    @include mobile {
                        display: none;
                    }
                }
            }

            .controls-toggler-mobile {
                display: none;
                border: 0;
                background-color: transparent;
                height: 15px;
                align-items: center;

                @include mobile {
                    display: flex;
                }

                span {
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    display: block;
                    background-color: rgba($black, .3);
                    pointer-events: none;

                    &:not(:last-child) {
                        margin-right: 3px;
                    }
                }
            }
        }

        .schema-row:not(:first-child) {
            .fields-radio.schema-col {
                padding-top: 6px;
            }
        }

        .fields-radio.schema-col {
            display: flex;
            align-items: center;

            .label {
                position: static;
                // margin-right: 20px;
                color: $grey-text;
                font-size: 14px;
                line-height: 16px;
                margin: 0;
            }

            .field {
                margin-left: 20px;
                width: initial;
                margin-bottom: 0;
                max-width: 100%;

                &:last-child {
                    margin-top: 0;
                }

                input[type="radio"]:hover:not([disabled])+label::before {
                    border-color: #e7e7e7 !important;
                }

                input:checked+label::before {
                    background: $purple url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.00506 6.32836L1.6187 3.8209L0.823242 4.65672L4.00506 8L10.8232 0.835821L10.0278 0L4.00506 6.32836Z' fill='white'/%3E%3C/svg%3E%0A") center no-repeat;
                    background-size: 11px 8px;
                    border: 0;
                }

                label {
                    margin: 0;
                    padding-left: 23px;
                    font-size: 14px;
                    line-height: 1.71;
                    padding-right: 0;

                    &::before {
                        width: 16px;
                        height: 16px;
                        border: 1px solid #e7e7e7;
                        left: 0;
                        top: calc(50% - 8px);
                    }

                    &::after {
                        content: none;
                    }
                }
            }
        }
    }

    .group-account-orders {
        .history-order {
            border: 1px solid $grey-border;
            padding: 10px;
            background-color: #fff;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &.processed {
                .history-order-status {
                    color: $orange-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0001 10.8184C3.89056 10.8184 2.18192 9.10978 2.18192 7.00024C2.18192 6.35434 2.34419 5.74978 2.6242 5.21523L1.6951 4.28613C1.2019 5.07521 0.90918 6.00112 0.90918 7.00024C0.90918 9.81295 3.18735 12.0912 6.0001 12.0912V14.0002L8.54555 11.4548L6.0001 8.90933V10.8184Z' fill='%23FFA825'/%3E%3Cpath d='M6.00004 1.90909V0L3.45459 2.54545L6.00004 5.09089V3.1818C8.10958 3.1818 9.81822 4.89044 9.81822 6.99999C9.81822 7.64588 9.65595 8.25044 9.37594 8.78499L10.305 9.71409C10.7982 8.92501 11.091 7.9991 11.091 6.99999C11.0909 4.18727 8.81276 1.90909 6.00004 1.90909Z' fill='%23FFA825'/%3E%3C/svg%3E%0A");
                        width: 12px;
                        height: 14px;
                    }
                }
            }

            &.shipped {
                .history-order-status {
                    color: $blue-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3636 2.18182H8.72727V0H1.09091C0.490909 0 0 0.490909 0 1.09091V7.09091H1.09091C1.09091 7.99636 1.82182 8.72727 2.72727 8.72727C3.63273 8.72727 4.36364 7.99636 4.36364 7.09091H7.63636C7.63636 7.99636 8.36727 8.72727 9.27273 8.72727C10.1782 8.72727 10.9091 7.99636 10.9091 7.09091H12V4.36364L10.3636 2.18182ZM10.0909 3L11.16 4.36364H8.72727V3H10.0909ZM2.72727 7.63636C2.42727 7.63636 2.18182 7.39091 2.18182 7.09091C2.18182 6.79091 2.42727 6.54545 2.72727 6.54545C3.02727 6.54545 3.27273 6.79091 3.27273 7.09091C3.27273 7.39091 3.02727 7.63636 2.72727 7.63636ZM3.93818 6C3.63818 5.66727 3.21273 5.45455 2.72727 5.45455C2.24182 5.45455 1.81636 5.66727 1.51636 6H1.09091V1.09091H7.63636V6H3.93818ZM9.27273 7.63636C8.97273 7.63636 8.72727 7.39091 8.72727 7.09091C8.72727 6.79091 8.97273 6.54545 9.27273 6.54545C9.57273 6.54545 9.81818 6.79091 9.81818 7.09091C9.81818 7.39091 9.57273 7.63636 9.27273 7.63636Z' fill='%235F88FF'/%3E%3C/svg%3E%0A");
                        width: 12px;
                        height: 9px;
                    }
                }
            }

            &.received {
                .history-order-status {
                    color: $green-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.53535 8.53535C10.4882 6.58249 10.4882 3.41751 8.53535 1.46465C6.58249 -0.488216 3.41751 -0.488216 1.46465 1.46465C-0.488216 3.41751 -0.488216 6.58249 1.46465 8.53535C3.41751 10.4882 6.58453 10.4882 8.53535 8.53535ZM3.20733 4.23579L4.3317 5.36017L6.79472 2.89919L7.66605 3.77053L5.20508 6.23151L4.3317 7.10285L3.46036 6.23151L2.33599 5.10713L3.20733 4.23579Z' fill='%235AE157'/%3E%3C/svg%3E%0A");
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            &.cancelled {
                .history-order-status {
                    color: $red-2;

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.46353 1.46353C-0.487842 3.4149 -0.487842 6.5851 1.46353 8.53647C3.4149 10.4878 6.5851 10.4878 8.53647 8.53647C10.4878 6.5851 10.4878 3.4149 8.53647 1.46353C6.5851 -0.487842 3.42105 -0.487842 1.46353 1.46353ZM6.56048 7.58849L5.00308 6.03109L3.44568 7.58849L2.41767 6.56048L3.97507 5.00308L2.41767 3.45183L3.44568 2.42382L5.00308 3.98122L6.56048 2.42382L7.58849 3.45183L6.03109 5.00923L7.58849 6.56664L6.56048 7.58849Z' fill='%23FF8C8C'/%3E%3C/svg%3E%0A");
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            &-title {
                font-family: $title-font;
                font-size: 14px;
                line-height: 1.57;
                max-width: 330px;

                span {
                    color: $grey;
                    margin-left: 4px;
                }
            }

            &-status {
                font-size: 12px;
                line-height: 22px;
                display: flex;
                align-items: center;

                &::before {
                    display: block;
                    content: '';
                    margin-right: 4px;
                    position: relative;
                    top: -2px;
                }
            }

        }
    }

    .group-feedback {
        width: 100%;
        max-width: 780px;

        .button[type="submit"] {
            width: initial;
            margin-top: 12px;
        }
    }

    .viewed-container {
        margin-top: 0 !important;
        z-index: unset;

        // margin-top: 20px;
        @include mobile {
            padding: 24px 10px 50px;
        }

        .is-relative {
            &:first-child {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                width: 100%;
                justify-content: space-between;

                @include touch {
                    flex-wrap: wrap;
                    gap: 16px;
                }
            }
        }

        .title.is-5 {
            font-family: $title-font;
            font-size: 40px;
            font-weight: 500;
            line-height: 1.2;
            // margin-bottom: 20px;
            margin-bottom: 0;

            @include maxw1200 {
                font-size: 36px;
            }

            @include touch {
                font-size: 32px;
            }

            @include mobile {
                font-size: 24px;
                line-height: 1.3;
            }

            +a {
                display: inline-flex;
                min-width: 184px;
                padding: 0 20px;
                height: 44px;
                border: 2px solid currentColor;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                color: $purple;

                @include touch {
                    position: relative !important;
                }
            }
        }

        .mb-6.mt-5 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        .columns {
            margin: 0;
            // width: calc(100% + 4px);
            width: 100%;

            // @include maxw1200 {
            //     width: calc(100% + 3px);
            // }
            // @include touch {
            //     width: calc(100% + 2px);
            // }
            @include mobile {
                display: flex;
                flex-wrap: wrap;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .column.is-3 {
            width: calc(25% + 2px);
            // margin-left: -1px;
            // margin-right: -1px;
            margin: -1px;

            @include maxw1200 {
                width: calc((100% / 3) + 2px);
            }

            @include touch {
                width: calc(50% + 2px);
            }

            @include mobile {
                flex: initial;
            }
        }
    }
}