.shop-footer {
    margin-top: 80px;
    background-color: $grey-bg;
    padding-top: 45px;
    padding-bottom: 40px;
    font-weight: 500;
    flex-grow: 0;
    @include mobile {
        margin-top: 60px;
        padding-top: 24px;
        padding-bottom: 24px;
    }
    a {
        color: inherit;
    }

    .columns.is-gapless {
        > .catalog-menu {
            padding-right: 16px !important;
        }
        > .site-menu {
            padding-right: 16px !important;
        }
    }

    .container-bottom {
        margin-top: 74px;
        @include touch {
            flex-direction: column;
        }
        @include mobile {
            margin-top: 24px;
            display: flex;
            align-items: flex-start;
        }
    }

    .left-column {
        @include mobile {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .footer-subscribe {
        padding-right: 16px;
        @include mobile {
            width: 100%;
        }
        &-title {
            font-family: $title-font;
            font-size: 24px;
            line-height: 1.42;
        }

        &-descr {
            margin-top: 10px;
            font-size: 15px;
            line-height: 1.2;
            @include mobile {
                margin-top: 8px;
            }
        }

        &-form {
            margin-top: 16px;
            display: block;
            max-width: 300px;
            position: relative;
            @include mobile {
                width: 100%;
                max-width: 394px;
            }
        }

        &-input {
            width: 100%;
            height: 53px;
            border: 1.5px solid $black;
            line-height: 51px;
            padding-left: 15px;
            padding-right: 54px;
            background-color: transparent;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 0;
            box-shadow: none;
            outline: 0;
            &:focus {
                box-shadow: initial;
            }
            @include mobile {
                font-size: 14px;
            }
            ::placeholder {
                opacity: .5;
            }
        }

        &-submit {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 54px;
            cursor: pointer;
            border: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile {
                width: 44px;
            }
        }
    }

    .footer-socials {
        margin-top: 36px;
        @include mobile {
            margin-top: 24px;
            width: 50%;
        }
        &-title {
            font-size: 15px;
            line-height: 1.2;
        }

        &-links {
            display: flex;
            align-items: center;
            margin-top: 13px;
        }

        &-link {
            &:not(:last-child) {
                margin-right: 24px;
            }
        }

    }

    .footer-contacts {
        &-mobile {
            display: none;
            @include mobile {
                display: block;
                width: 50%;
                margin-top: 24px;
            }
        }
        &-desktop {
            @include mobile {
                display: none;
            }
        }
    }

    .catalog-menu {
        columns: 2;
        list-style: none;
        // padding-right: 16px;
        @include touch {
            columns: initial;
        }
        @include mobile {
            display: none;
        }
        .uppercase {
            text-transform: uppercase;
            font-size: 12px;
            color: $black;
        }

        .highlight {
            color: $purple;
        }

        li {
            &:not(:last-child) {
                margin-bottom: 13px;
            }
        }

        a {
            line-height: 20px;

            &.has-text-link {
                color: $purple !important;
            }
        }
    }

    .site-menu {
        list-style: none;
        padding-right: 16px;
        @include mobile {
            display: none;
        }
        li {
            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        a {
            line-height: 28px;

            &.is-highlighted,
            &.active {
                color: $purple;
            }
        }
    }

    .phones {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        line-height: 28px;
        @include mobile {
            font-size: 15px;
            line-height: 1.5;
        }
    }

    .callback-button,
    .chat-button {
        font-size: 12px;
        line-height: 28px;
        text-transform: uppercase;
        color: $purple;
        border: 0;
        background-color: transparent;
        font-weight: 500;
    }

    .callback-button {
        margin-top: 4px;
    }

    .chat-button {
        margin-top: 10px;
        display: flex;
        align-items: center;
        @include mobile {
            margin-top: 0;
        }
        .icon {
            margin-right: 4px;
        }
    }

    .worktime {
        margin-top: 13px;
        line-height: 28px;
    }

    .develop {
        display: flex;
        align-items: center;
        line-height: 2.36;
        @include touch {
            order: 3;
            margin-top: 16px !important;
        }
        @include mobile {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        span {
            &:not(:last-child) {
                margin-right: 16px;
                @include mobile {
                    margin-right: initial;
                }
            }
        }
    }

    .payment-systems {
        display: flex;
        align-items: center;
        @include touch {
            margin-top: 16px !important;
        }
    }

    .icon {
        color: $black;

        &.subscribe {
            width: 13px;
            height: 12px;
        }

        &.facebook {
            width: 22px;
            height: 22px;
        }

        &.instagram {
            width: 22px;
            height: 22px;
        }

        &.youtube {
            width: 22px;
            height: 16px;
        }

        &.chat {
            width: 13px;
            height: 13px;
            color: $purple;
        }

        &.visa {
            width: 48px;
            height: 15px;
            margin-right: 24px;
        }

        &.mastercard {
            width: 35px;
            height: 22px;
        }
    }

    .grey-text {
        color: rgba($black, .5);
    }
}